import React, { useState } from 'react';
import styled from 'styled-components';
import { baseUrl } from '../../../../api/base-url';
import { scrapeDineOutPage } from '../../../../api/dine-out/scrape-menu';
import { useUpdatePub } from '../../../../api/hooks/use-update-pub';
import { Loader } from '../../../../components/loader';
import { spacing } from '../../../../components/styled';
import { ActionButton } from '../../../../features/shared/components/styled';
import { StyledTextArea } from '../../../../features/shared/components/styled-form';
import { useUpdatePubsState } from '../../../../features/shared/hooks/use-update-pubs-state';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectAdminPubs } from '../../../../store/admin-selectors';
import { setUnpublishedChanges } from '../../../../store/app-slice';
import { HappyHoursByDay, Pub } from '../../../../types';
import { stripHtmlTags } from './helpers/strip-html';
import { useDineOutPlacesSearch } from './hooks/use-google-dine-out';

const DEFAULT_LATITUDE = 49.266250342711686;
const DEFAULT_LONGITUDE = -123.14568758010866;
const FALLBACK_IMAGE =
  'https://assets.simpleviewinc.com/simpleview/image/upload/c_fill,h_450,q_50,w_600/v1/clients/vancouverbc/default_listing_image_23e90736-6428-4c80-9da7-90777139fb78.jpg';

interface JsonData {
  detailURL: string;
  latitude?: number;
  longitude?: number;
  description: string;
  primary_image_url?: string;
  title: string;
}

export const DineOutDataFetch = () => {
  const adminPubs = useAppSelector(selectAdminPubs);
  const dispatch = useAppDispatch();
  const { textSearch, getPlaceDetails } = useDineOutPlacesSearch();
  const [pub, setPub] = useState<Pub>({} as Pub);
  const baseDineOutUrl = 'https://www.dineoutvancouver.com';
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [pubsToScrape, setPubsToScrape] = useState<JsonData[]>([]);
  const [processed, setProcessed] = useState<JsonData[]>([]);
  const [scraping, setScraping] = useState(false);
  const { updatePub } = useUpdatePub();
  const { updatePubsState } = useUpdatePubsState();

  const lookup = async () => {
    setScraping(true);

    setLoading(true);

    const {
      detailURL,
      description: rawDescription,
      primary_image_url,
      title,
      latitude: pubLatitude,
      longitude: pubLongitude,
    } = pubsToScrape[0];
    const imageUrl = primary_image_url ?? FALLBACK_IMAGE;
    const latitude = pubLatitude ?? DEFAULT_LATITUDE;
    const longitude = pubLongitude ?? DEFAULT_LONGITUDE;
    const description = stripHtmlTags(rawDescription);

    const dineOutUrl = `${baseDineOutUrl}${detailURL}`;
    const scrapedData = await scrapeDineOutPage(dineOutUrl);

    if (scrapedData) {
      const { phone, email, cuisine, bookingUrl, menus } = scrapedData;
      const textSearchResult = (
        await textSearch(title, latitude, longitude)
      )[0];

      const { place_id, name, rating, user_ratings_total } = textSearchResult;

      const existingPub = adminPubs.find((p) => p.googlePlaceId === place_id);

      if (existingPub) {
        const newData = {
          ...existingPub,
          dineOut: {
            imageUrl,
            dineOutUrl,
            description,
            phone,
            email,
            cuisine,
            bookingUrl,
            menus,
          },
        };
        console.log('Old bug: newData', newData);

        setPub({
          ...existingPub,
          happyHours: { ...existingPub.happyHours },
          drinks: [...existingPub.drinks],
          food: [...existingPub.food],
          openingHours: [...existingPub.openingHours],
          dineOut: {
            imageUrl,
            dineOutUrl,
            description,
            phone,
            email,
            cuisine,
            bookingUrl,
            menus,
          },
        });
      } else if (place_id && name && rating && user_ratings_total) {
        const placeDetails = await getPlaceDetails(place_id);

        console.log('placeDetails', placeDetails);

        const { geometry, website, opening_hours, vicinity } = placeDetails;

        if (
          geometry &&
          geometry.location &&
          website &&
          opening_hours &&
          opening_hours.periods &&
          vicinity
        ) {
          setPub({
            ...pub,
            googlePlaceId: place_id,
            name,
            website,
            vicinity,
            openingHours: opening_hours.periods,
            rating,
            userRatingsTotal: user_ratings_total,
            latitude: geometry.location.lat(),
            longitude: geometry.location.lng(),
            drinks: [],
            food: [],
            events: [],
            happyHours: {} as HappyHoursByDay,
            dineOut: {
              imageUrl,
              dineOutUrl,
              description,
              phone,
              email,
              cuisine,
              bookingUrl,
              menus,
            },
          });
        } else {
          alert('Failed to get data from getPlaceDetails');
          console.error('placeDetails', placeDetails);
        }
      } else {
        alert('Failed to get data from textSearch');
        console.error('textSearchResult', textSearchResult);
      }
    } else {
      alert('Failed to get data scrape needed data');
      console.error('scrapedData', scrapedData);
    }

    setLoading(false);
  };

  const submit = async () => {
    if (!pub.rating) {
      const response = await updatePub(pub);

      const newArray = [...pubsToScrape];
      const processedValue = newArray.shift();
      setPubsToScrape(newArray);
      if (processedValue) {
        setProcessed([...processed, processedValue]);
      }

      updatePubsState(pub);
      dispatch(setUnpublishedChanges(false));
      setComplete(true);
    }

    try {
      const token = localStorage.getItem('token');

      // Using directly rather than useUpdatePub since rating info is fresh.
      const response = await fetch(`${baseUrl}/api/pub`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(pub),
      });

      if (response?.status === 200 || response?.status === 201) {
        const newArray = [...pubsToScrape];
        const processedValue = newArray.shift();
        setPubsToScrape(newArray);
        if (processedValue) {
          setProcessed([...processed, processedValue]);
        }

        updatePubsState(pub);
        dispatch(setUnpublishedChanges(false));
        setComplete(true);
      } else {
        console.error('Non-200 response');
      }
    } catch (err) {
      console.error('Failed to create or update pub.', err);
    }
  };

  if (loading) {
    return (
      <>
        {pubsToScrape.length > 0 && (
          <span>{`Processing: ${pubsToScrape[0].title}`}</span>
        )}
        <Loader />
      </>
    );
  }

  console.log('pub', pub);

  return (
    <>
      {!scraping ? (
        <>
          <StyledTextArea
            id='json'
            value={JSON.stringify(pubsToScrape, null, 2)}
            onChange={(e) => {
              setPubsToScrape(JSON.parse(e.target.value) as JsonData[]);
            }}
            rows={10}
          ></StyledTextArea>
          <ActionButton onClick={lookup}>Start</ActionButton>
        </>
      ) : (
        <>
          {!complete ? (
            <>
              <h3>{pub.name}</h3>
              <pre>{JSON.stringify(pub.dineOut, null, 2)}</pre>
              <ActionButton
                style={{ marginRight: spacing.lg }}
                onClick={() => setScraping(false)}
              >
                Cancel
              </ActionButton>
              <ActionButton onClick={submit}>Looks Good</ActionButton>
            </>
          ) : (
            <Container>
              <span
                style={{ marginBottom: '10px' }}
              >{`Information for ${pub?.name} has been updated successfully.`}</span>
              {pubsToScrape.length > 0 ? (
                <ActionButton
                  onClick={() => {
                    lookup();
                    setComplete(false);
                  }}
                  style={{ width: '180px' }}
                >
                  Next
                </ActionButton>
              ) : (
                <>
                  <span
                    style={{ marginBottom: '10px' }}
                  >{`No more restaurants to process.`}</span>
                  <ActionButton
                    onClick={() => {
                      setPub({} as Pub);
                      setComplete(false);
                      setScraping(false);
                    }}
                    style={{ width: '180px' }}
                  >
                    Reset
                  </ActionButton>
                </>
              )}
            </Container>
          )}
          <div>
            <h2>Remaining</h2>
            <pre>{JSON.stringify(pubsToScrape, null, 2)}</pre>
            <h2>Processed</h2>
            <pre>{JSON.stringify(processed, null, 2)}</pre>
          </div>
        </>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
