import { DineOutMenu } from '../../types';
import { baseUrl } from '../base-url';

interface ResponseData {
  phone: string;
  email: string;
  cuisine: string;
  bookingUrl: string;
  menus: DineOutMenu[];
}

export const scrapeDineOutPage = async (link: string) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${baseUrl}/api/dine-out`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ link }),
    });

    const data: ResponseData = await response.json();

    return data;
  } catch (err) {
    console.log('Failed to scrape Dine Out menu.', err);
  }
};
