import styled from 'styled-components';
import { colors } from './colors';

export const CustomHeading2 = styled.h2`
  margin: 0;
  color: ${colors.blue};
`;

export const CustomHeading3 = styled.h3`
  margin: 0;
  color: ${colors.blue};
`;

export const CustomHeading4 = styled.h4`
  margin: 0;
  color: ${colors.blue};
`;

export const CustomHeading5 = styled.h5`
  margin: 0;
  color: ${colors.blue};
`;
