import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../../components/styled';
import { NavigationSidebar } from '../../../../features/shared/components/navigation-sidebar';
import { Alert } from '../../../../features/shared/styled-components/alerts/alert';
import { useHandleLogout } from '../../hooks/use-handle-logout';
import { AppHeader } from './app-header';

interface Props {}

export const AppWrapper = ({}: Props) => {
  useHandleLogout();

  return (
    <Wrapper>
      <Alert />
      <FullContentWrapper>
        <NavigationSidebar />
        <MainContent data-testid='main-content'>
          <AppHeader />
          <ScrollableContent data-testid='scrollable-wrapper'>
            <Outlet />
          </ScrollableContent>
        </MainContent>
      </FullContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  font-family: 'Raleway';

  h1 {
    line-height: 1;
    font-weight: 500;
  }

  h2 {
    line-height: 1;
    font-weight: 500;
  }

  h3 {
    line-height: 1;
    font-weight: 500;
  }

  h4 {
    font-weight: 500;
  }

  input {
    font-size: 1em;
    font-family: 'Raleway';
  }

  textarea {
    font-size: 1em;
    font-family: 'Raleway';
  }
`;

const FullContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const MainContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${colors.paleGrey};
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;
