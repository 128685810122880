import { pastDate } from '../../../helpers/date';
import { EventDate, PubEvent } from '../../../types';

export const getNextActiveDate = (
  eventDates: EventDate[],
  futureEvents: boolean
) => {
  const nextFutureDate =
    eventDates.find((eventDate) => !pastDate(eventDate)) ??
    eventDates[eventDates.length - 1];

  return futureEvents ? nextFutureDate : eventDates[eventDates.length - 1];
};

export const sortEventsByDate = (events: PubEvent[], futureEvents: boolean) => {
  return [...events].sort((a, b) => {
    const aDate = getNextActiveDate(a.dates, futureEvents);
    const bDate = getNextActiveDate(b.dates, futureEvents);

    return (
      new Date(`${aDate.date} ${aDate.times.startTime}:00`).getTime() -
      new Date(`${bDate.date} ${bDate.times.startTime}:00`).getTime()
    );
  });
};
