import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import styled from 'styled-components';
import { TimeInput } from '../features/shared/components/time-input';
import { TimeFormat } from '../helpers/happy-hour-times';
import { Pub } from '../types';
import { colors, fontSize, spacing } from './styled';

interface Props {
  happyHours: TimeFormat[];
  setHappyHours: (times: TimeFormat[]) => void;
  openingHours: Pub['openingHours'];
}

export const HappyHours = ({
  happyHours,
  setHappyHours,
  openingHours,
}: Props) => {
  const daysNumbers = [0, 1, 2, 3, 4, 5, 6];
  const daysOpen = Object.values(openingHours).reduce((acc: number[], day) => {
    if (!acc.includes(day.open.day)) {
      return [...acc, day.open.day];
    }

    return acc;
  }, []);

  const daysString = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const handleAddTime = () => {
    const newTimeSlot: TimeFormat = {
      time: { startTime: '21:00', endTime: '22:00' },
      extra: { useOpen: false, useClose: true },
      days: [],
    };

    setHappyHours([...happyHours, newTimeSlot]);
  };

  const handleDeleteTime = (index: number) => {
    const newTimes = happyHours.filter(
      (_time, timeIndex) => timeIndex !== index
    );
    setHappyHours(newTimes);
  };

  const handleSetTime = (
    index: number,
    newTime: string | null,
    endTime?: boolean
  ) => {
    if (newTime) {
      let newTimes = [...happyHours];

      if (endTime) {
        newTimes[index] = {
          ...newTimes[index],
          time: { ...newTimes[index].time, endTime: newTime },
        };
      } else {
        newTimes[index] = {
          ...newTimes[index],
          time: { ...newTimes[index].time, startTime: newTime },
        };
      }

      setHappyHours(newTimes);
    }
  };

  const toggleUseOpenCloseTime = (index: number, closeTime?: boolean) => {
    const newTimes = happyHours.map((time, timeIndex) => {
      if (timeIndex === index) {
        const newExtra = closeTime
          ? { useClose: !time.extra.useClose }
          : { useOpen: !time.extra.useOpen };

        return { ...time, extra: { ...time.extra, ...newExtra } };
      }

      return time;
    });

    setHappyHours(newTimes);
  };

  const toggleCheckBox = (index: number, day: number) => {
    const newTimes = happyHours.map((time, timeIndex) => {
      if (timeIndex === index) {
        const newDays = time.days.includes(day)
          ? time.days.filter((dayVal) => dayVal !== day)
          : [...time.days, day];

        return { ...time, days: newDays };
      }

      return time;
    });

    setHappyHours(newTimes);
  };

  const handleDailyCheckbox = (index: number) => {
    const baseDays =
      happyHours[index].days.length === daysOpen.length
        ? []
        : [0, 1, 2, 3, 4, 5, 6];
    const newDays = baseDays.filter((day) => daysOpen.includes(day));

    const newTimes = happyHours.map((time, timeIndex) => {
      if (timeIndex === index) {
        return { ...time, days: newDays };
      }

      return time;
    });

    setHappyHours(newTimes);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledContainer data-testid='happy-hour-times-container'>
        <Table>
          <HeadingRow>
            <TimeCell>Time</TimeCell>
            <Cell style={{ borderRight: `0.5px ${colors.slate} solid` }}>
              Daily
            </Cell>
            {daysString.map((day) => {
              return <Cell key={day}>{day}</Cell>;
            })}
            <DeleteCell />
          </HeadingRow>
          {happyHours.map(({ time, days, extra }, index) => {
            return (
              <Row key={`${time.startTime}-${time.endTime}-${index}`}>
                <TimeCell>
                  <StyledHorizontalInputs>
                    <>
                      {extra.useOpen ? (
                        <div>
                          <span>From Open</span>
                          <Tooltip title='Set a Time'>
                            <Button
                              onClick={() => toggleUseOpenCloseTime(index)}
                            >
                              <AccessTimeIcon htmlColor={colors.blue} />
                            </Button>
                          </Tooltip>
                        </div>
                      ) : (
                        <VerricalWrapper>
                          <TimeInput
                            value={time.startTime}
                            startTime={true}
                            onTimeChange={(newTime) =>
                              handleSetTime(index, newTime)
                            }
                          />
                          <Button
                            onClick={() => toggleUseOpenCloseTime(index)}
                            style={{ fontSize: fontSize.textSm }}
                          >
                            Use Opening Time
                          </Button>
                        </VerricalWrapper>
                      )}
                    </>
                    <span>-</span>
                    <Wrapper>
                      {extra.useClose ? (
                        <>
                          <span>Till Close</span>
                          <Tooltip title='Set a Time'>
                            <Button
                              onClick={() =>
                                toggleUseOpenCloseTime(index, true)
                              }
                            >
                              <AccessTimeIcon htmlColor={colors.blue} />
                            </Button>
                          </Tooltip>
                        </>
                      ) : (
                        <VerricalWrapper>
                          <TimeInput
                            value={time.endTime}
                            startTime={false}
                            onTimeChange={(newTime) =>
                              handleSetTime(index, newTime, true)
                            }
                          />
                          <Button
                            onClick={() => toggleUseOpenCloseTime(index, true)}
                            style={{ fontSize: fontSize.textSm }}
                          >
                            Use Closing Time
                          </Button>
                        </VerricalWrapper>
                      )}
                    </Wrapper>
                  </StyledHorizontalInputs>
                </TimeCell>
                <Cell style={{ borderRight: `0.5px ${colors.slate} solid` }}>
                  <Checkbox
                    checked={days.length === daysOpen.length}
                    onClick={() => handleDailyCheckbox(index)}
                    style={{ color: colors.blue }}
                  />
                </Cell>
                {daysNumbers.map((day) => {
                  const disabled = !daysOpen.includes(day);

                  return (
                    <Cell
                      key={day}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      {!daysOpen.includes(day) && (
                        <span
                          style={{
                            fontSize: fontSize.textSm,
                            color: 'grey',
                          }}
                        >
                          Closed
                        </span>
                      )}
                      <Checkbox
                        checked={days.includes(day)}
                        onClick={() => toggleCheckBox(index, day)}
                        style={!disabled ? { color: colors.blue } : {}}
                        disabled={disabled}
                      />
                    </Cell>
                  );
                })}
                <DeleteCell>
                  <StyledButton onClick={() => handleDeleteTime(index)}>
                    X
                  </StyledButton>
                </DeleteCell>
              </Row>
            );
          })}
          <Row>
            <TimeCell>
              <StyledButton onClick={handleAddTime}>Add</StyledButton>
            </TimeCell>
          </Row>
        </Table>
      </StyledContainer>
    </LocalizationProvider>
  );
};

const Table = styled.div`
  width: 100%;
  border: 1px ${colors.slate} solid;
`;

const Row = styled.div`
  display: flex;
  text-align: center;
  border-top: 0.5px ${colors.slate} solid;
`;

const HeadingRow = styled(Row)`
  border-bottom: 1px ${colors.slate} solid;
`;

const Cell = styled.div`
  padding: ${spacing.md};
  flex-basis: 6.5%;
  display: flex;
  justify-content: center;
`;

const TimeCell = styled(Cell)`
  flex-basis: 30%;
  min-width: 340px;
  border-right: 0.5px ${colors.slate} solid;
`;

const DeleteCell = styled(Cell)`
  flex-basis: 4%;
`;

const StyledButton = styled(Button)`
  width: 100%;
  min-width: 20px !important;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledHorizontalInputs = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.3fr 1.5fr;
  justify-content: space-around;
  width: 100%;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VerricalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
