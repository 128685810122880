import { APIProvider } from '@vis.gl/react-google-maps';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AlertProvider } from './app/features/shared/styled-components/alerts/alert-provider';
import AppRoot from './app/routes/app-root';
import { createStore } from './app/store/createStore';
import { getGooglePlacesKey } from './shared-constants';

const bootstrap = async () => {
  const containerElement = document.getElementById('app-root');
  const root = createRoot(containerElement!);
  const store = createStore();

  root.render(
    <React.StrictMode>
      <APIProvider apiKey={getGooglePlacesKey()}>
        <Provider store={store}>
          <BrowserRouter basename={'/app'}>
            <AlertProvider>
              <Routes>
                <Route path='*' element={<AppRoot />} />
              </Routes>
            </AlertProvider>
          </BrowserRouter>
        </Provider>
      </APIProvider>
    </React.StrictMode>
  );
};

bootstrap();
