export const colors = {
  blue: '#51A6F9',
  backgroundBlue: '#D7EBFF',
  slate: '#2B2D42',
  paleGrey: '#f2f6fa',
  lightGrey: '#E8EFF5',
  mediumGrey: 'rgba(43, 45, 66, 0.3)',
  greyBlue: '#d1dbe5',
  lightGreen: '#e0fff3',
  lightOrange: '#fff8dc',
};
