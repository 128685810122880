import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors, spacing } from '../../../components/styled';
import { readableDayAndDate } from '../../../helpers/date';
import { displayTime } from '../../../helpers/time';
import {
  CustomRowH3,
  CustomRowP,
  CustomRowTextContainer,
  CustomRowTextWrapper,
} from '../../../routes/admin/dashboard/styled';
import {
  dashboardRoute,
  duplicateEventPath,
  eventRoute,
} from '../../../routes/routes';
import { useAppDispatch } from '../../../store';
import {
  setActiveEvent,
  setActivePub,
  setExistingPub,
} from '../../../store/app-slice';
import { PubEvent } from '../../../types';
import { getNextActiveDate } from '../helpers/events';
import { useLookupPub } from '../hooks/use-lookup-pub';
import { CustomTableRow } from './custom-table-row';

interface Props {
  event: PubEvent;
  onDelete: (event: PubEvent) => void;
  futureEvents?: boolean;
  showDuplicate?: boolean;
  setCopyModalOpen: (open: boolean) => void;
}

export const EventRow = ({
  event,
  onDelete,
  futureEvents = true,
  showDuplicate = false,
  setCopyModalOpen,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const eventId = event.id;
  const eventDate = getNextActiveDate(event.dates, futureEvents);
  const startDate = readableDayAndDate(eventDate.date);
  const endDate = '';
  const pub = useLookupPub(event.pubId);
  const { startTime, endTime } = eventDate.times;
  const timeText = endTime
    ? `${displayTime(startTime)} - ${displayTime(endTime)}`
    : displayTime(startTime);

  useEffect(() => {
    // Shouldn't be possible to get here
    if (!eventId) {
      navigate(dashboardRoute);
      return;
    }
  }, [eventId]);

  const handleRowClick = () => {
    dispatch(setActivePub(pub));
    dispatch(setActiveEvent(event));
    dispatch(setExistingPub(true));
    navigate(eventRoute(event));
  };

  const handleDuplicateClick = () => {
    dispatch(setActivePub(pub));
    dispatch(setActiveEvent({ ...event, id: undefined }));
    navigate(duplicateEventPath);
  };

  const handleCopyToAnotherBusiness = () => {
    setCopyModalOpen(true);
    dispatch(setActiveEvent(event));
  };

  const duplicateOption = showDuplicate
    ? [
        { text: 'Duplicate', handler: handleDuplicateClick },
        {
          text: 'Copy to Another Business',
          handler: handleCopyToAnotherBusiness,
        },
      ]
    : [];
  const dropdownOptions = [
    { text: 'Edit', handler: handleRowClick },
    ...duplicateOption,
    { text: 'Delete', handler: () => onDelete(event) },
  ];

  return (
    <CustomTableRow
      key={event.id}
      onClick={handleRowClick}
      dropdownOptions={dropdownOptions}
    >
      <StyledImage src={event.imageUrl} />
      <CustomRowTextContainer>
        <CustomRowTextWrapper>
          <CustomRowH3>{event.name}</CustomRowH3>
          <CustomRowP>{pub?.name}</CustomRowP>
        </CustomRowTextWrapper>
        <CustomRowTextWrapper>
          <CustomRowP>{`${event.type}, $${event.price}`}</CustomRowP>
          <CustomRowP>{`${timeText}, ${startDate}${endDate}`}</CustomRowP>
        </CustomRowTextWrapper>
      </CustomRowTextContainer>
    </CustomTableRow>
  );
};

const StyledImage = styled.img`
  width: 10%;
  object-fit: contain;
  max-width: 70px;
  max-height: 50px;
  margin-right: ${spacing.lg};
`;

const StyledButton = styled(Button)`
  && {
    color: ${colors.blue};
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;
