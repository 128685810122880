import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUpdateUser } from '../../../../api/hooks/use-update-user';
import { Loader } from '../../../../components/loader';
import { spacing } from '../../../../components/styled';
import { DetailsRow } from '../../../../features/shared/components/details-row';
import { RoleInput } from '../../../../features/shared/components/role-input';
import { HorizontalWrapper } from '../../../../features/shared/components/styled';
import {
  StyledInput,
  StyledLabel,
} from '../../../../features/shared/components/styled-form';
import { AppSectionWrapper } from '../../../../shared/components/section-wrapper';
import { useAppSelector } from '../../../../store';
import { selectActiveUser } from '../../../../store/app-selectors';
import { UserRole } from '../../../../types';

export const UserDetails = () => {
  const navigate = useNavigate();
  const activeUser = useAppSelector(selectActiveUser);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [role, setRole] = useState<UserRole>(activeUser?.role ?? 'owner');
  const [accountId, setAccountId] = useState(activeUser?.accountId);
  const { updateUserData, loading } = useUpdateUser();

  if (!activeUser || loading) {
    return <Loader />;
  }

  const handleUpdateUser = async () => {
    if (activeUser && unsavedChanges) {
      await updateUserData(
        { ...activeUser, role, accountId: accountId ?? null },
        false,
        true
      );
      setUnsavedChanges(false);
      navigate('../users');
    }
  };

  const handleCancel = () => {
    setRole(activeUser.role);
    setUnsavedChanges(false);
  };

  const handleRoleUpdate = (role: UserRole) => {
    setRole(role);
    setUnsavedChanges(true);
  };

  return (
    <div>
      <AppSectionWrapper>
        <Container>
          <h2>User Details</h2>
          {activeUser && (
            <Content>
              <h3>{`${activeUser.firstName} ${activeUser.lastName}`}</h3>
              <p>{`ID: ${activeUser.id}`}</p>
              <>
                <StyledLabel htmlFor='account-id'>Account ID</StyledLabel>
                <StyledInput
                  id='account-id'
                  value={accountId ?? ''}
                  onChange={(e) => {
                    setUnsavedChanges(true);
                    if (e.target.value === '') {
                      setAccountId(null);
                    } else {
                      setAccountId(e.target.value);
                    }
                  }}
                  required
                />
              </>
              <DetailsRow property='First Name' value={activeUser.firstName} />
              <DetailsRow property='Last Name' value={activeUser.lastName} />
              <DetailsRow property='Email' value={activeUser.email} />
              <RoleInput
                role={role}
                setRole={handleRoleUpdate}
                isAdmin={true}
              />
              {unsavedChanges && (
                <HorizontalWrapper>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button onClick={handleUpdateUser}>Update</Button>
                </HorizontalWrapper>
              )}
            </Content>
          )}
        </Container>
      </AppSectionWrapper>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
`;

const Content = styled.div`
  margin: ${spacing.xl} 0;
`;
