import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../components/styled';
import { AddButton } from '../../../components/styled/buttons';
import { CustomHeading4 } from '../../../components/styled/headings';
import { useAppDispatch } from '../../../store';
import { setUnpublishedChanges } from '../../../store/app-slice';
import { MenuItem, Pub } from '../../../types';
import { MenuList } from './menu-list';

interface Props {
  food: Pub['food'];
  drinks: Pub['drinks'];
  setDrinks: (drinks: MenuItem[]) => void;
  setFood: (food: MenuItem[]) => void;
}

export const Menu = ({ food, drinks, setFood, setDrinks }: Props) => {
  const dispatch = useAppDispatch();

  const handleUpdate = (newItems: MenuItem[], foodUpdate?: boolean) => {
    dispatch(setUnpublishedChanges(true));
    foodUpdate ? setFood(newItems) : setDrinks(newItems);
  };

  const handleAdd = (newFood: boolean) => {
    if (newFood) {
      handleUpdate([...food, { label: '', price: '' }], true);
    } else {
      handleUpdate([...drinks, { label: '', price: '' }], false);
    }
  };

  return (
    <Container>
      <CustomHeading4>Drinks</CustomHeading4>
      <MenuWrapper>
        <MenuList
          items={drinks}
          onUpdate={(newDrinks) => handleUpdate(newDrinks)}
        />
        <AddButton onClick={() => handleAdd(false)}>Add +</AddButton>
      </MenuWrapper>
      <CustomHeading4>Food</CustomHeading4>
      <MenuWrapper>
        <MenuList
          items={food}
          onUpdate={(newFood) => handleUpdate(newFood, true)}
        />
        <AddButton onClick={() => handleAdd(true)}>Add +</AddButton>
      </MenuWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.md};
`;

const MenuWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
