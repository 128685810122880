import { useMapsLibrary } from '@vis.gl/react-google-maps';

/**
 * FILE MUST BE .TSX to account for dummy input element
 */

export const useGooglePlaces = () => {
  const placesLib = useMapsLibrary('places');

  const getPlaceDetails = async (
    placeId: string,
    fields: google.maps.places.PlaceDetailsRequest['fields']
  ) => {
    if (placesLib && fields) {
      // File must be .TSX
      const dummyInput = document.createElement('input');
      let placesService = new placesLib.PlacesService(dummyInput);

      return await new Promise<google.maps.places.PlaceResult>(function (
        resolve,
        reject
      ) {
        placesService.getDetails({ placeId, fields }, (place, status) => {
          if (place) {
            const data = fields.reduce((acc, field) => {
              return {
                ...acc,
                [field]: place[field as keyof google.maps.places.PlaceResult],
              };
            }, {});

            resolve(data);
          } else {
            console.error('missing place');
            reject('Missing place in response');
          }
        });
      });
    } else {
      throw new Error('no places lib');
    }
  };

  return { placesLib, getPlaceDetails };
};
