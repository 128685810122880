import React from 'react';
import styled from 'styled-components';
import { DineOutItem } from '../../../../types';
import { DineOutListItem } from './dine-out-list-item';

interface Props {
  items: DineOutItem[];
  onUpdate: (newItems: DineOutItem[]) => void;
}

export const DineOutMenuList = ({ items, onUpdate }: Props) => {
  const handleChange = (newValue: DineOutItem, index: number) => {
    const newItems = [...items];
    newItems[index] = newValue;

    onUpdate(newItems);
  };

  const handleRemove = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onUpdate(newItems);
  };

  if (!items || items.length === 0) {
    return <></>;
  }

  return (
    <>
      {items.map((item, index) => {
        return (
          <DineOutListItem
            label={item.label}
            subLabel={item.subLabel}
            handleChange={(newValue) => handleChange(newValue, index)}
            handleRemove={() => handleRemove(index)}
            key={`${item.label}-${item.subLabel}-${index}`}
          />
        );
      })}
    </>
  );
};

export const HorizontalInputs = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
