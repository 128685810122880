import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Turnstile from 'react-turnstile';
import styled from 'styled-components';
import { turnstileSiteKey } from '../../../shared-constants';
import { SectionWrapper } from '../../../website/shared/components/section-wrapper';
import { Loader } from '../../components/loader';
import { colors, spacing } from '../../components/styled';
import { ActionButton } from '../../features/shared/components/styled';
import { useHandleRefresh } from '../../features/shared/hooks/use-handle-refresh';
import { useAppSelector } from '../../store';
import { selectLoggedInUser } from '../../store/user-selectors';
import { dashboardRoute, registerRoute } from '../routes';
import { useBusinessLogin } from './hooks/use-business-login';

export const BusinessLogin = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const { error, login, loading } = useBusinessLogin();
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const navigate = useNavigate();
  const { loading: loadingData } = useHandleRefresh();

  useEffect(() => {
    // Redirect to the dashboard if logged in.
    if (loggedInUser) {
      navigate(dashboardRoute);
    }
  }, [loggedInUser]);

  if (loading || loadingData) {
    return <Loader />;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const loginUser = async () => {
      login({ email, password }, turnstileToken);
    };

    loginUser();
  };

  return (
    <SectionWrapper>
      <Container>
        <FormWrapper>
          <StyledForm onSubmit={handleSubmit}>
            <StyledLabel htmlFor='login-email'>Email</StyledLabel>
            <StyleInput
              id='login-email'
              style={{ width: '100%' }}
              placeholder='Email'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <StyledLabel htmlFor='login-password'>Password</StyledLabel>
            <StyleInput
              id='login-password'
              style={{ width: '100%' }}
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type='password'
            />
            {!!turnstileToken ? (
              <ActionButton
                type='submit'
                disabled={email === '' || password === ''}
              >
                Login
              </ActionButton>
            ) : (
              <TurnstileWrapper>
                <Turnstile
                  sitekey={turnstileSiteKey}
                  onVerify={(token) => setTurnstileToken(token)}
                />
              </TurnstileWrapper>
            )}
          </StyledForm>
          {error && (
            <span style={{ color: 'red' }}>There was an error logging in.</span>
          )}
        </FormWrapper>
        <LinkWrapper>
          <Link to={registerRoute}>Register</Link>
        </LinkWrapper>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  color: ${colors.blue};
  text-align: center;
  max-width: 600px;
`;

const StyledLabel = styled.label``;

const StyleInput = styled.input`
  width: 100%;
  margin-bottom: ${spacing.lg};
  margin-top: ${spacing.sm};
  padding: ${spacing.md};
  border: none;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  color: ${colors.slate};
`;

const FormWrapper = styled.div`
  background-color: ${colors.paleGrey};
  padding: ${spacing.lg};
  padding-top: ${spacing.md};
  padding-bottom: ${spacing.lg};
  border-radius: 10px;
  margin-top: ${spacing.xl};
  margin-bottom: ${spacing.lg};
`;

const StyledForm = styled.form`
  width: 100%;
  color: ${colors.slate};
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const LinkWrapper = styled.div`
  margin-bottom: ${spacing.lg};
`;

const TurnstileWrapper = styled.div`
  margin-top: ${spacing.lg};
`;
