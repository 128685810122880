import { useState } from 'react';
import { useUpdateAccountsState } from '../../features/shared/hooks/use-update-accounts-state';
import { Account } from '../../types';
import { updateAccount } from '../accounts/update-account';

export const useUpdateAccount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const updateAccountsState = useUpdateAccountsState();

  const updateAccountData = async (
    account: Partial<Account>,
    updateLoggedInAccount = false,
    updateActiveAccount = false
  ) => {
    setLoading(true);
    try {
      const response = await updateAccount(account);

      if (response && (response.status === 200 || response.status === 201)) {
        const accountData: Account = await response.json();

        updateAccountsState(
          accountData,
          updateLoggedInAccount,
          updateActiveAccount
        );
        setLoading(false);

        return accountData;
      } else {
        setError('Received non-200 response while updating account.');
        setLoading(false);
      }
    } catch (err) {
      setError('Failed to update account.');
      setLoading(false);
    }

    setLoading(false);
  };

  return { loading, error, updateAccountData };
};
