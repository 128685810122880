import dayjs from 'dayjs';
import { EventTimes } from '../types';

export const displayTime = (time: string) => {
  const dateTime = new Date(`01-01-1970 ${time}`);
  const hours = dateTime.getHours();
  const mins = dateTime.getMinutes();
  const displayMins = mins === 0 ? '' : `:${mins}`;
  if (hours === 24 || hours === 0) return `12${displayMins}am`;

  return hours === 12
    ? `${hours}${displayMins}pm`
    : hours > 12
    ? `${hours - 12}${displayMins}pm`
    : `${hours}${displayMins}am`;
};

/**
 * Verify that an end time is after a start time. Allowance is given
 * to events that span midnight
 */
export const checkValidTimes = (times?: EventTimes) => {
  if (!times) {
    return false;
  }

  const { startTime, endTime } = times;
  if (startTime && !endTime) {
    return true;
  }

  const start = dayjs(startTime, 'HH:mm');
  const end = dayjs(endTime, 'HH:mm');

  if (end.hour() < 6) {
    return true;
  }

  return start.hour() < end.hour();
};
