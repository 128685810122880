import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../components/loader';
import { spacing } from '../../components/styled';
import { useHandleRefresh } from '../../features/shared/hooks/use-handle-refresh';
import { AppSectionWrapper } from '../../shared/components/section-wrapper';

export const Dashboard = () => {
  const { loading } = useHandleRefresh();

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <AppSectionWrapper>
        <Container>
          <TableContainer>
            <Outlet />
          </TableContainer>
        </Container>
      </AppSectionWrapper>
    </div>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const TableContainer = styled.div`
  margin: ${spacing.lg} 0;
`;
