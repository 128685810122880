import styled from 'styled-components';
import { colors, spacing } from '../../../components/styled';

export const CustomTable = styled.div`
  border: 1px ${colors.slate} solid;
  font-size: 14px;
`;

export const Row = styled.div`
  display: flex;
  border-top: 0.5px ${colors.slate} solid;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgb(81, 166, 249, 0.1);
  }
`;

export const CustomRowTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: ${spacing.lg};
`;

export const CustomRowTextWrapper = styled.div`
  min-width: 400px;
  margin-right: ${spacing.lg};
`;

export const CustomRowH3 = styled.h3`
  margin-top: 0;
  margin-bottom: ${spacing.sm};
`;

export const CustomRowP = styled.p`
  margin: 0;
  text-transform: capitalize;
`;

export const CustomRowExtraP = styled.p`
  font-style: italic;
  margin-top: ${spacing.xs};
  margin-bottom: ${spacing.xs};
`;

export const HeadingRow = styled(Row)`
  border-bottom: 1px ${colors.slate} solid;
  cursor: auto;

  &:hover {
    background-color: white;
  }
`;

export const Cell = styled.div`
  padding: ${spacing.md};
  flex-basis: 12%;
`;

export const ButtonCell = styled(Cell)`
  flex-basis: 8%;
  min-width: 65px;
  text-align: center;
`;
