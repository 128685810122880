import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../../components/loader';
import { useHandleRefresh } from '../../../../features/shared/hooks/use-handle-refresh';
import { MenuOptionInstructions } from '../../../../shared/components/menu-options-instructions';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectActivePub } from '../../../../store/app-selectors';
import { setActiveEvent } from '../../../../store/app-slice';
import { bulkEventsPath, manualEventPath } from '../../../routes';
import { generateInitialEvent } from '../../helpers/empty';

export const NewEventsOptions = () => {
  const { loading } = useHandleRefresh();
  const activePub = useAppSelector(selectActivePub);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (loading || !activePub) {
    return <Loader />;
  }

  const handleBulkClick = () => {
    navigate(bulkEventsPath);
  };

  const handleManualClick = () => {
    const initialEvent = generateInitialEvent(activePub?.googlePlaceId);
    dispatch(setActiveEvent(initialEvent));
    navigate(manualEventPath);
  };

  return (
    <>
      <p>How would you like to add your events?</p>
      <MenuOptionInstructions
        heading='Automatically (Recommended)'
        description='Input a list of events to be automatically processed. Recommended
            even for a single event.'
        buttonText='Automatically'
        onClick={handleBulkClick}
      />
      <MenuOptionInstructions
        heading='Manually'
        description='Input individual event details one-by-one.'
        buttonText='Manually'
        onClick={handleManualClick}
      />
    </>
  );
};
