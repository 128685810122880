import React from 'react';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  fontWeight,
  spacing,
} from '../../../../components/styled';
import { AppSectionWrapper } from '../../../../shared/components/section-wrapper';

export const AppHeader = () => {
  return (
    <AppSectionWrapper
      backgroundColor={colors.paleGrey}
      isSticky={true}
      sectionStyle={{ width: '100%' }}
    >
      <ContentWrapper>
        <Nav>
          {/* <NavItem>
            <Link to='/'>Home</Link>
          </NavItem> */}
          {/* TODO */}
          {/* <NavItem>
            <Link to='business/login'>Login</Link>
          </NavItem>
          <NavItem>
            <Link to='business/register'>Register</Link>
          </NavItem> */}
        </Nav>
      </ContentWrapper>
    </AppSectionWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: ${spacing.md};
  display: flex;
  flex-direction: row;
`;

const Nav = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const NavItem = styled.li`
  display: inline;
  margin-right: ${spacing.md};
  padding: ${spacing.md};

  a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: ${fontWeight.medium};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AppStoreButton = styled.img`
  margin-right: ${spacing.md};
  object-fit: cover;
  border-radius: 8px;
  border-width: 0.5px;
  border-style: solid;
  border-color: white;
  max-height: 35px;

  @media ${desktopBreakpoint} {
    max-height: 50px;
  }
`;

const GooglePlayButton = styled.img`
  margin-left: ${spacing.md};
  object-fit: cover;
  border-radius: 8px;
  border-width: 0.5px;
  border-style: solid;
  border-color: white;
  max-height: 35px;

  @media ${desktopBreakpoint} {
    max-height: 50px;
  }
`;
