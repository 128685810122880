import { useEffect } from 'react';
import { loginRoute } from '../../routes';

export const useHandleLogout = () => {
  useEffect(() => {
    window.addEventListener('storage', (event) => {
      const url = window.location.href;
      const loggedInPaths = ['dashboard', 'admin', 'user', 'account'];
      const requireLoggedInUser = loggedInPaths.some((path) =>
        url.includes(path)
      );

      if (event.key === 'token' && !event.newValue && requireLoggedInUser) {
        window.location.href = `/app${loginRoute}`;
      }
    });
  }, []);
};
