import React from 'react';
import { styled } from 'styled-components';
import { colors, spacing } from '../../components/styled';
import { ActionButton } from '../../features/shared/components/styled';

interface Props {
  heading: string;
  description: string;
  buttonText: string;
  onClick: () => void;
}

export const MenuOptionInstructions = ({
  heading,
  description,
  buttonText,
  onClick,
}: Props) => {
  return (
    <StyledDivButton onClick={onClick}>
      <StyledTextWrapper>
        <StyledH3>{heading}</StyledH3>
        <StyledParagraph>{description}</StyledParagraph>
      </StyledTextWrapper>
      <StyledActionButton onClick={onClick}>{buttonText}</StyledActionButton>
    </StyledDivButton>
  );
};

const StyledDivButton = styled.div`
  && {
    display: flex;
    flex-direction: row;
    padding: ${spacing.lg};
    border-radius: ${spacing.md};
    margin-bottom: ${spacing.lg};
    border: 1px solid ${colors.blue};

    &:hover {
      background-color: rgba(81, 166, 249, 0.2);
      cursor: pointer;
    }
  }
`;

const StyledTextWrapper = styled.div`
  flex-basis: 70%;
`;

const StyledH3 = styled.h3`
  margin-top: 0;
`;

const StyledParagraph = styled.p`
  margin-bottom: 0;
`;

const StyledActionButton = styled(ActionButton)`
  && {
    align-self: flex-end;
    margin-left: auto;
    height: fit-content;
    min-width: 152px;
  }
`;
