import React from 'react';
import styled from 'styled-components';
import { PubEvent } from '../../../types';
import { EventListItem } from './event-list-item';

interface Props {
  events: PubEvent[];
}

export const EventsList = ({ events }: Props) => {
  return (
    <Container>
      {events.map((event) => {
        return <EventListItem event={event} key={event.id} />;
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
