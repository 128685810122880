import React from 'react';
import styled from 'styled-components';
import { CustomTable } from '../../../routes/admin/dashboard/styled';
import { PubEvent } from '../../../types';
import { sortEventsByDate } from '../helpers/events';
import { EventRow } from './event-row';

interface Props {
  events?: PubEvent[];
  onDelete: (event: PubEvent) => void;
  futureEvents?: boolean;
  showDuplicate?: boolean;
  setCopyModalOpen: (open: boolean) => void;
}

export const EventsTable = ({
  events,
  onDelete,
  futureEvents = true,
  showDuplicate = false,
  setCopyModalOpen,
}: Props) => {
  const sortedEvents = sortEventsByDate(events ?? [], futureEvents);

  return (
    <StyledCustomTable>
      {sortedEvents.map((event, index) => (
        <EventRow
          key={event.id ?? index}
          event={event}
          onDelete={() => onDelete(event)}
          futureEvents={futureEvents}
          showDuplicate={showDuplicate}
          setCopyModalOpen={setCopyModalOpen}
        />
      ))}
    </StyledCustomTable>
  );
};

const StyledCustomTable = styled(CustomTable)`
  border: 0;
`;
