import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { spacing } from '../../../components/styled';
import { PasteImageUploader } from './image-paste';
import { ActionButton } from './styled';
import { PhotoInputWrapper } from './styled-form';

interface Props {
  imgFile: File | undefined | null;
  imageUrl?: string;
  setImgFile: (file: File | undefined | null) => void;
  handleRemoveImage: () => void;
}

export const EventImageInput = ({
  imgFile,
  imageUrl,
  setImgFile,
  handleRemoveImage,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageLink = imageUrl
    ? imageUrl
    : imgFile
    ? URL.createObjectURL(imgFile)
    : '';

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImgFile(e.target.files?.[0]);
  };

  return (
    <Wrapper>
      {imageLink ? (
        <ImagePreviewWrapper>
          <div style={{ position: 'relative' }}>
            <ImagePreview src={imageLink} alt='img' />
            <Tooltip title='Remove Image'>
              <StyledRemoveButton onClick={handleRemoveImage}>
                <CloseIcon />
              </StyledRemoveButton>
            </Tooltip>
          </div>
        </ImagePreviewWrapper>
      ) : (
        <ImageInputWrapper>
          <PasteImageUploader setImgFile={setImgFile} />
          <StyledButton onClick={() => fileInputRef.current?.click()}>
            <ButtonWrapper>
              <UploadIcon />
              <span>Upload an Image</span>
            </ButtonWrapper>
            <input
              onChange={handleAddImage}
              multiple={false}
              ref={fileInputRef}
              type='file'
              hidden
            />
          </StyledButton>
        </ImageInputWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(PhotoInputWrapper)`
  margin-left: ${spacing.lg};
  width: 45%;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(ActionButton)`
  && {
    margin-top: ${spacing.md};
    width: 220px;
    box-sizing: border-box;
  }
`;

const ImageInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  margin-right: ${spacing.md};
`;

const ImagePreview = styled.img`
  max-width: 400px;
  max-height: 300px;
`;

const ImagePreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledRemoveButton = styled(Button)`
  && {
    position: absolute;
    right: -25px;
    top: -15px;
    margin: 0;
    padding: 0;
    min-width: 0;
  }
`;
