import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { bulkPublishEvents } from '../../../../../api/events/bulk-publish-events';
import { useUpdatePub } from '../../../../../api/hooks/use-update-pub';
import { Loader } from '../../../../../components/loader';
import { useHandleRefresh } from '../../../../../features/shared/hooks/use-handle-refresh';
import { useSendAlert } from '../../../../../features/shared/hooks/use-send-alert';
import { useUpdateEventsState } from '../../../../../features/shared/hooks/use-update-events-state';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  selectActivePub,
  selectExistingPub,
} from '../../../../../store/app-selectors';
import { setActiveEvent, setExistingPub } from '../../../../../store/app-slice';
import { EventWithFile, Pub, PubEvent } from '../../../../../types';
import { businessEventsRoute } from '../../../../routes';
import { EventForm } from '../../components/event-form';
import { BulkEventsForm } from './bulk-events-form';
import { BulkEventsResult } from './bulk-events-result';

export const BulkEvents = () => {
  const { loading: refreshLoading } = useHandleRefresh();
  const navigate = useNavigate();
  const activePub = useAppSelector(selectActivePub);
  const existingPub = useAppSelector(selectExistingPub);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<EventWithFile[]>([]);
  const [index, setIndex] = useState(0);
  const [showSummary, setShowSummary] = useState(true);
  const cancelButtonText = index === 0 ? 'Cancel' : 'Previous';
  const actionButtonText = index === events.length - 1 ? 'Publish All' : 'Next';
  const { updateEventsState } = useUpdateEventsState();
  const dispatch = useAppDispatch();
  const { updatePub } = useUpdatePub();
  const sendAlert = useSendAlert();

  if (refreshLoading || loading || !activePub) {
    // Might need to just navigate to the dashboard
    return <Loader />;
  }

  const handlePrevious = (
    newEvent: PubEvent,
    imgFile: File | undefined | null
  ) => {
    if (index === 0) {
      const canelConfirmed = confirm(
        `Are you sure you want to cancel? All the processed data will be lost.`
      );

      if (canelConfirmed) {
        navigate(businessEventsRoute(activePub.googlePlaceId));
      }
    } else {
      updateEvent(newEvent, imgFile);
      dispatch(setActiveEvent(events[index - 1]));
      setIndex(index - 1);
    }
  };

  const updateEvent = (
    newEvent: PubEvent,
    imgFile: File | undefined | null
  ) => {
    const newEvents = events.map((event, i) => {
      if (i === index) {
        const imageUrl = imgFile
          ? URL.createObjectURL(imgFile)
          : newEvent.imageUrl;
        return { ...newEvent, imageUrl, imgFile };
      } else {
        return event;
      }
    });

    setEvents(newEvents);
    return newEvents;
  };

  const handleBulkPublish = async (newEvents: EventWithFile[]) => {
    setLoading(true);
    try {
      // If pub doesn't exist, create it first
      if (!existingPub) {
        await updatePub(activePub);
      }
      const publishedEvents = await bulkPublishEvents(newEvents);

      // Update state. Setting the events as activePub won't be updated for each iteration.
      const newPub: Pub = {
        ...activePub,
        events: [...(activePub.events ?? []), ...publishedEvents],
      };
      publishedEvents.forEach((e) => updateEventsState(newPub, e));

      setLoading(false);
      navigate(businessEventsRoute(activePub.googlePlaceId));
      dispatch(setExistingPub(true));
      sendAlert({ message: 'PUBLISH_SUCCESS' });
    } catch (error) {
      setLoading(false);
      sendAlert({ message: 'PUBLISH_ERROR', type: 'error' });
    }
  };

  const handleActionButton = async (
    newEvent: PubEvent,
    imgFile: File | undefined | null
  ) => {
    const newEvents = updateEvent(newEvent, imgFile);

    if (index === events.length - 1) {
      await handleBulkPublish(newEvents);
    } else {
      dispatch(setActiveEvent(events[index + 1]));
      setIndex(index + 1);
    }
  };

  // The event won't have an ID yet so we can't use it for bulk events.
  const onDelete = (_pubEvent: PubEvent) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete this event: \n\n${events[index].name}`
    );

    if (deleteConfirmed) {
      const filteredEvents = events.filter((_e, i) => i !== index);
      setEvents(filteredEvents);

      if (filteredEvents.length === 0) {
        setShowSummary(true);
      } else if (index < filteredEvents.length) {
        dispatch(setActiveEvent(filteredEvents[index]));
      } else if (index > filteredEvents.length - 1) {
        dispatch(setActiveEvent(filteredEvents[filteredEvents.length - 1]));
        setIndex(filteredEvents.length - 1);
      } else {
        dispatch(setActiveEvent(filteredEvents[filteredEvents.length - 1]));
      }
    }
  };

  const handleGetStartedClick = () => {
    setShowSummary(false);
    dispatch(setActiveEvent(events[0]));
  };

  return (
    <>
      {events.length === 0 ? (
        <BulkEventsForm setEvents={setEvents} />
      ) : showSummary ? (
        <BulkEventsResult
          events={events}
          handleNextClick={handleGetStartedClick}
        />
      ) : (
        <EventForm
          onSubmit={handleActionButton}
          actionButtonText={actionButtonText}
          cancelButtonText={cancelButtonText}
          onDelete={onDelete}
          onCancel={handlePrevious}
          index={index}
          listLength={events.length}
        />
      )}
    </>
  );
};
