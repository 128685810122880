import { FormControl } from '@mui/material';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';
import { EventImageInput } from '../../../../features/shared/components/event-image-input';
import {
  StyledInput,
  StyledLabel,
  StyledTextArea,
} from '../../../../features/shared/components/styled-form';
import { PubEvent, PubEventValue } from '../../../../types';
import { EventTypeSelect } from './event-type-select';
import { EventSection } from './events-section';
import { PricePrefixSelect } from './price-prefix-select';

interface Props {
  event: PubEvent;
  handleChange: (param: keyof PubEvent, value: PubEventValue) => void;
  imgFile: File | undefined | null;
  setImgFile: (file: File | undefined | null) => void;
  headingRef: React.RefObject<HTMLHeadingElement>;
  setUnpublishedChanges: (value: boolean) => void;
}

export const EventDetailsInputs = ({
  event,
  handleChange,
  imgFile,
  setImgFile,
  headingRef,
  setUnpublishedChanges,
}: Props) => {
  const { description, imageUrl, name, type, pricePrefix, price } = event;

  const handleRemoveImage = () => {
    setImgFile(null);
    handleChange('imageUrl', null);
  };

  const handleImageChange = (file: File | undefined | null) => {
    setUnpublishedChanges(true);
    setImgFile(file);
  };

  return (
    <EventSection heading='Details' headingRef={headingRef}>
      <StyledEventDetailsWrapper>
        <div style={{ width: '55%' }}>
          <StyledLabel htmlFor='event-name'>Event Name*</StyledLabel>
          <StyledInput
            id='event-name'
            value={name ?? ''}
            onChange={(e) => handleChange('name', e.target.value)}
            required
          />
          <div>
            <StyledLabel htmlFor='description'>Description</StyledLabel>
            <StyledTextArea
              id='description'
              value={description ?? ''}
              onChange={(e) => handleChange('description', e.target.value)}
              rows={7}
            />
          </div>
          <HorizontalWrapper style={{ justifyContent: 'space-between' }}>
            <FormControl style={{ width: '28%' }}>
              <PricePrefixSelect
                pricePrefix={pricePrefix}
                setPricePrefix={(newPrefix) =>
                  handleChange('pricePrefix', newPrefix)
                }
                disabled={!price || price === ''}
              />
            </FormControl>
            <FormControl style={{ width: '20%' }}>
              <StyledLabel htmlFor='price'>Price*</StyledLabel>
              <StyledCurrencyInput
                id='price-input'
                name='price-input'
                prefix='$ '
                placeholder='Price'
                value={price}
                defaultValue={'0'}
                decimalsLimit={2}
                onValueChange={(value) => {
                  // Need to use string value to handle price properly. Float was troublesome.
                  if (value) {
                    handleChange('price', value);
                  } else {
                    handleChange('price', '');
                  }
                }}
                required
              />
            </FormControl>
            <FormControl style={{ width: '45%' }}>
              <EventTypeSelect
                eventType={type}
                setEventType={(newType) => handleChange('type', newType)}
              />
            </FormControl>
            {/* <p>Do you want this to be a featured spotlight event? Fee applies.</p>
          <StyledRadioButton>
            <input
              id='spotlight-yes'
              type='radio'
              value='Yes'
              checked={activeEvent?.spotlightEvent ?? false}
              onChange={() => handleChange('spotlightEvent', true)}
            />
            <label htmlFor='spotlight-yes'>Yes</label>
          </StyledRadioButton>
          <StyledRadioButton>
            <input
              id='spotlight-no'
              type='radio'
              value='No'
              checked={!activeEvent?.spotlightEvent ?? true}
              onChange={() => handleChange('spotlightEvent', false)}
            />
            <label htmlFor='owner-no'>No</label>
          </StyledRadioButton>
          <br />
          <p /> */}
          </HorizontalWrapper>
        </div>
        <EventImageInput
          imgFile={imgFile}
          imageUrl={imageUrl}
          setImgFile={handleImageChange}
          handleRemoveImage={handleRemoveImage}
        />
      </StyledEventDetailsWrapper>
    </EventSection>
  );
};

const StyledEventDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  padding: 11px;
  border: none;
  border-radius: 5px;
`;
