import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../components/protected-route';
import { useFetchData } from '../features/shared/hooks/use-fetch-data';
import { AccountOverview } from './account/account-overview';
import { AccountInvite } from './invite/invite';
import { BusinessLogin } from './login/business-login';
import { BusinessRegister } from './register/register';
import { AdminRoot } from './root/components/routes/admin-root';
import { AppWrapper } from './root/components/routes/app-wrapper';
import { DashboardRoot } from './root/components/routes/dashboard-root';
import { UserOverview } from './user/user-overview';

export const AppRoot = () => {
  useFetchData();

  return (
    <Routes>
      <Route path='' element={<AppWrapper />}>
        <Route path='' element={<BusinessLogin />} />
        <Route path='login' element={<BusinessLogin />} />
        <Route path='register' element={<BusinessRegister />} />
        <Route path='invite' element={<AccountInvite />} />
        <Route
          path='admin/*'
          element={<ProtectedRoute element={AdminRoot} />}
        />
        <Route path='dashboard/*' element={<DashboardRoot />} />
        <Route
          path='/account/:accountId'
          element={<ProtectedRoute element={AccountOverview} />}
        />
        <Route
          path='/user/:userId'
          element={<ProtectedRoute element={UserOverview} />}
        />
      </Route>
    </Routes>
  );
};

export default AppRoot;
