import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';

interface Props {
  heading: string;
  width?: string;
  headingRef?: React.RefObject<HTMLHeadingElement>;
}

export const EventSection = ({
  heading,
  width,
  headingRef,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Container $width={width}>
      <StyledHeading ref={headingRef}>{heading}</StyledHeading>
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled.div<{ $width?: string }>`
  ${({ $width }) => $width && `width: ${$width};`}
  border-radius: ${spacing.md};
  border: 1px solid grey;
  box-sizing: border-box;
  margin-bottom: ${spacing.lg};
`;

const Content = styled.div`
  padding: ${spacing.xl};
`;

const StyledHeading = styled.h2`
  color: white;
  margin: 0;
  padding: ${spacing.sm};
  background-color: grey;
  border-top-left-radius: ${spacing.md};
  border-top-right-radius: ${spacing.md};

  scroll-margin-top: 100px;
`;
