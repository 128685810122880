import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../../../../components/protected-route';
import { DineOutMenuInputs } from '../../../business/new/dine-out/dine-out';
import { NewEventsOptions } from '../../../business/new/events/new-events-options';
import { NewBusinessHappyHours } from '../../../business/new/happy-hours/new-business-happy-hours';
import { NewBusinessOverview } from '../../../business/new/new-business-overview';
import { EventsRoot } from './events-root';

export const NewBusinessRoot = () => {
  return (
    <Routes>
      <Route
        path=''
        element={
          <ProtectedRoute element={NewBusinessOverview} requireAdmin={true} />
        }
      >
        <Route
          path='happy-hours'
          element={
            <ProtectedRoute
              element={NewBusinessHappyHours}
              requireAdmin={true}
            />
          }
        />
        <Route
          path='events'
          element={
            <ProtectedRoute element={NewEventsOptions} requireAdmin={true} />
          }
        />
        <Route
          path='events/*'
          element={<ProtectedRoute element={EventsRoot} requireAdmin={true} />}
        />
        <Route
          path='dine-out'
          element={
            <ProtectedRoute element={DineOutMenuInputs} requireAdmin={true} />
          }
        />
      </Route>
    </Routes>
  );
};
