import { baseUrl } from '../base-url';

export const loginRequest = async (
  user: { email: string; password: string },
  turnstileToken: string
) => {
  try {
    return await fetch(`${baseUrl}/api/user/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...user, turnstileToken }),
    });
  } catch (error) {
    console.error('There was an error logging in');
    throw new Error('There was an error logging in');
  }
};
