import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';
import { StyledLabel } from '../../../../features/shared/components/styled-form';
import { EventType, eventTypes } from '../../../../types';

interface Props {
  eventType: EventType | null;
  setEventType: (newType: EventType) => void;
}

export const EventTypeSelect = ({ eventType, setEventType }: Props) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setEventType(event.target.value as EventType);
  };

  return (
    <>
      <StyledLabel htmlFor='event-type-input'>Event Type*</StyledLabel>
      <StyledSelect
        id='event-type-input'
        value={eventType ?? ''}
        onChange={handleChange}
        size='small'
      >
        {eventTypes.map((event) => {
          return (
            <StyledMenuItem key={event} value={event}>
              {event}
            </StyledMenuItem>
          );
        })}
      </StyledSelect>
      {eventType === null && (
        <span style={{ color: 'red' }}>You must set a category.</span>
      )}
    </>
  );
};

const StyledSelect = styled(Select)`
  min-width: 150px;
  margin-top: ${spacing.sm};
  background-color: white;
  text-transform: capitalize;
`;

const StyledMenuItem = styled(MenuItem)`
  min-width: 150px;
  text-transform: capitalize;
`;
