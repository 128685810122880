import { baseUrl } from '../base-url';

export const deleteAccount = async (accountId: string) => {
  try {
    const token = localStorage.getItem('token');
    return await fetch(`${baseUrl}/api/account/${accountId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    console.log('Failed to delete account.', err);
  }
};
