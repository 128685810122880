import React from 'react';
import {
  StyledInput,
  StyledLabel,
} from '../../../../features/shared/components/styled-form';
import { PubEvent, PubEventValue } from '../../../../types';
import { ButtonTextSelect } from './button-text-select';
import { EventSection } from './events-section';

interface Props {
  event: PubEvent;
  handleChange: (param: keyof PubEvent, value: PubEventValue) => void;
}

export const EventBookingInputs = ({ event, handleChange }: Props) => {
  const { bookingUrl, buttonText, email, phone } = event;

  return (
    <EventSection heading='Booking Info' width='49%'>
      <StyledLabel htmlFor='booking-url'>Booking/Reserve URL</StyledLabel>
      <StyledInput
        id='booking-url'
        value={bookingUrl ?? ''}
        onChange={(e) => handleChange('bookingUrl', e.target.value)}
        placeholder='https://www.event.com'
      />
      {bookingUrl && (
        <ButtonTextSelect
          buttonText={buttonText}
          setButtonText={(newButtonText) =>
            handleChange('buttonText', newButtonText)
          }
        />
      )}
      <StyledLabel htmlFor='email'>Email</StyledLabel>
      <StyledInput
        id='email'
        value={email ?? ''}
        onChange={(e) => handleChange('email', e.target.value)}
        placeholder={'Booking Email'}
        type='email'
      />
      <StyledLabel htmlFor='phone'>Phone</StyledLabel>
      <StyledInput
        id='phone'
        value={phone ?? ''}
        onChange={(e) => handleChange('phone', e.target.value)}
        placeholder={'Booking Phone Number'}
        type='tel'
      />
    </EventSection>
  );
};
