import { useCallback, useState } from 'react';
import { UserType } from '../../../../../server/database/models/users';
import { baseUrl } from '../../../api/base-url';
import { useAppDispatch } from '../../../store';
import { setToken } from '../../../store/app-slice';
import { setLoggedInUser } from '../../../store/user-slice';
import { Pub } from '../../../types';

type ResponseBody = UserType & { pubs: Pub[] };

export const useFetchLoggedInUser = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchLoggedInUser = useCallback(() => {
    const fetchData = async () => {
      if (!loading) {
        setLoading(true);

        try {
          const token = localStorage.getItem('token');

          if (token) {
            const response = await fetch(`${baseUrl}/api/user`, {
              headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status !== 200) {
              setLoading(false);
              // TODO: Set error in state and handle app wide
              setError(true);
            } else {
              const userData: ResponseBody = await response.json();

              const accessToken = localStorage.getItem('token') as string;
              dispatch(setToken(accessToken));
              dispatch(setLoggedInUser(userData));

              // TODO: Set error in state and handle app wide?
              setError(false);
              setLoading(false);

              return userData;
            }
          } else {
            setLoading(false);
            console.log('No token. Not fetching user.');
          }
        } catch (err) {
          setError(true);
          setLoading(false);
          console.log('Error getting current user');
        }
      }
    };

    return fetchData();
  }, []);

  return { fetchLoggedInUser, error, loading };
};
