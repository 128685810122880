import React from 'react';
import styled from 'styled-components';
import { removePub } from '../../../api';
import { Loader } from '../../../components/loader';
import { CustomTable } from '../../../routes/admin/dashboard/styled';
import { useAppSelector } from '../../../store';
import { selectAdminAccountsList } from '../../../store/admin-selectors';
import { selectLoggedInUser } from '../../../store/user-selectors';
import { Pub } from '../../../types';
import { useUpdatePubsState } from '../hooks/use-update-pubs-state';
import { PubRow } from './pub-row';

interface Props {
  pubs: Pub[];
  showLoader?: boolean;
  readOnly?: boolean;
}

export const PubsTable = ({
  pubs,
  showLoader = true,
  readOnly = false,
}: Props) => {
  const accounts = useAppSelector(selectAdminAccountsList);
  const user = useAppSelector(selectLoggedInUser);
  const isAdmin = user?.role === 'admin';
  const { deletePubsState } = useUpdatePubsState();

  const handleDeletePub = async (pubToDelete: Pub) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete: \n\n${pubToDelete.name}`
    );

    if (deleteConfirmed) {
      const response = await removePub(pubToDelete.googlePlaceId);

      if (response?.status === 200) {
        deletePubsState(pubToDelete);
      }
    }
  };

  if (!pubs && showLoader === true) {
    return <Loader />;
  }

  return (
    <StyledCustomTable>
      {pubs && pubs.length > 0 ? (
        pubs.map((pub) => (
          <PubRow
            isAdmin={isAdmin}
            readOnly={readOnly}
            key={pub.googlePlaceId}
            pub={pub}
            accounts={accounts}
            onDelete={handleDeletePub}
          />
        ))
      ) : (
        <p>There are no businesses associated with this account yet.</p>
      )}
    </StyledCustomTable>
  );
};

const StyledCustomTable = styled(CustomTable)`
  border: 0;
`;
