import { PubEvent } from '../types';

export const adminDashBoardRoute = '/admin';

export const dashboardRoute = '/dashboard';
export const loginRoute = '/login';
export const registerRoute = '/register';

export const dashboardBusinessPath = (businessId: string) =>
  `businesses/${businessId}`;
export const dashboardBusinessRoute = (businessId: string) =>
  `${dashboardRoute}/${dashboardBusinessPath(businessId)}`;

export const businessHappyHoursPath = `happy-hours`;
export const businessEventsPath = `events`;
export const businessNewEventsPath = `${businessEventsPath}/new`;

export const businessHappyHoursRoute = (businessId: string) =>
  `${dashboardBusinessRoute(businessId)}/${businessHappyHoursPath}`;

export const businessEventsRoute = (businessId: string) =>
  `${dashboardBusinessRoute(businessId)}/${businessEventsPath}`;

export const businessDineOutRoute = (businessId: string) =>
  `${dashboardBusinessRoute(businessId)}/dine-out`;

export const newBusinessPath = (googlePlaceId: string) => `${googlePlaceId}`;
export const newBusinessDineOutPath = `dine-out`;

export const newEventsPath = 'new';
export const manualEventPath = 'manual';
export const duplicateEventPath = `${newEventsPath}/${manualEventPath}`;
export const copyEventToBusinessPath = (businessId: string) =>
  `${businessEventsRoute(businessId)}/${duplicateEventPath}`;
export const bulkEventsPath = 'bulk';
export const eventRoute = ({ pubId, id }: PubEvent) =>
  `${dashboardBusinessRoute(pubId)}/events/${id}`;

export const accountOverviewRoute = (accountId: string) =>
  `/account/${accountId}`;
export const userOverviewRoute = (userId: string) => `/user/${userId}`;
