import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../components/styled';

interface Props {
  /** One of the icons specified above as a JSX Element */
  children: React.ReactNode;
  /** Color of the icon. See Colors package for more information */
  color?: string;
  /** Size of the icon */
  size?: string;
  /** Adds additional classes to the component */
  className?: string;
}
const iconSize = '16px';
const defaultColor = colors.paleGrey;

export const Icon = ({
  children,
  color = defaultColor,
  size = iconSize,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <IconWrapper
      $color={color}
      style={{
        width: size,
        height: size,
      }}
      {...rest}
    >
      {children}
    </IconWrapper>
  );
};

const IconWrapper = styled.div<{ $color: string }>`
  fill: ${({ $color }) => $color};
`;
