import React from 'react';
import styled from 'styled-components';
import { Loader } from '../../../../components/loader';
import { spacing } from '../../../../components/styled';
import { DetailsRow } from '../../../../features/shared/components/details-row';
import { PubsTable } from '../../../../features/shared/components/pubs-table';
import { useHandleRefresh } from '../../../../features/shared/hooks/use-handle-refresh';
import { AppSectionWrapper } from '../../../../shared/components/section-wrapper';
import { useAppSelector } from '../../../../store';
import { selectActiveAccount } from '../../../../store/app-selectors';
import { selectLoggedInUser } from '../../../../store/user-selectors';
import { AccountDetailsForm } from './account-details-form';

export const AccountDetails = () => {
  useHandleRefresh();
  const account = useAppSelector(selectActiveAccount);
  const user = useAppSelector(selectLoggedInUser);
  const isAdmin = user?.role === 'admin';

  if (!account) {
    return <Loader />;
  }

  return (
    <div>
      <AppSectionWrapper>
        <Container>
          <h2>Account Details</h2>
          <DetailsRow property={'ID'} value={account?.id} />
          <DetailsRow
            property={'Invite Link'}
            value={`https://www.vibeapp.ca/app/invite?account_name=${account.name}&account_id=${account.id}`}
            link
          />
          <Content>
            <AccountDetailsForm account={account} />
            {isAdmin && (
              <TableWrapper>
                <PubsTable
                  pubs={account.pubs}
                  showLoader={false}
                  readOnly={true}
                />
              </TableWrapper>
            )}
          </Content>
        </Container>
      </AppSectionWrapper>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
`;

const Content = styled.div`
  margin: ${spacing.xl} 0;
`;

const TableWrapper = styled.div`
  margin-top: ${spacing.lg};
`;
