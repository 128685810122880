import React, { useState } from 'react';

import Button from '@mui/material/Button';
import styled from 'styled-components';
import { useUpdatePub } from '../../api/hooks/use-update-pub';
import { spacing } from '../../components/styled';
import { HorizontalWrapper } from '../../features/shared/components/styled';
import { Account, Pub } from '../../types';
import { StyledPubInfo } from './styled';

interface Props {
  pub: Pub;
  account?: Account | null;
}

export const OwnerInput = ({ pub, account }: Props) => {
  const [showOwnerInput, setShowOwnerInput] = useState(false);
  const [accountId, setAccountId] = useState<string | undefined | null>(
    account?.id
  );
  const { updatePub } = useUpdatePub();
  const fieldText = showOwnerInput
    ? `Account ID:`
    : `Account: ${account?.name}`;

  const handleUpdateOwner = async () => {
    await updatePub({ ...pub, accountId });

    setShowOwnerInput(false);
  };

  const handleCancel = () => {
    setAccountId(account?.id);
    setShowOwnerInput(false);
  };

  return (
    <Container>
      <HorizontalWrapper style={{ alignItems: 'baseline' }}>
        <StyledPubInfo>{fieldText}</StyledPubInfo>

        {showOwnerInput ? (
          <>
            <StyledInput
              id={`user-id`}
              value={accountId ?? ''}
              onChange={(e) => {
                if (e.target.value) {
                  setAccountId(e.target.value);
                } else {
                  setAccountId(null);
                }
              }}
            />
            <StyledButton size='small' onClick={handleCancel}>
              Cancel
            </StyledButton>
            <StyledButton size='small' onClick={handleUpdateOwner}>
              Submit
            </StyledButton>
          </>
        ) : (
          <StyledButton size='small' onClick={(e) => setShowOwnerInput(true)}>
            {account ? 'Update Owner' : 'Assign Owner'}
          </StyledButton>
        )}
      </HorizontalWrapper>
      {showOwnerInput && (
        <StyledPubInfo>
          You'll need to look up the user ID from the Users table.
        </StyledPubInfo>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${spacing.lg};
`;

const StyledInput = styled.input`
  margin-left: ${spacing.md};
  min-width: 250px;

  && {
    font-size: 12px;
  }
`;

const StyledButton = styled(Button)`
  margin-left: ${spacing.md};

  && {
    font-size: 10px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;
