import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GridViewIcon from '@mui/icons-material/GridView';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors, spacing } from '../../../components/styled';
import { iconAndNameWhite } from '../../../images';
import {
  accountOverviewRoute,
  adminDashBoardRoute,
  dashboardRoute,
  userOverviewRoute,
} from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setActiveUser } from '../../../store/app-slice';
import { selectLoggedInUser } from '../../../store/user-selectors';
import { TabLink } from './styled';

export const NavigationSidebar = () => {
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('admin')) {
      setActiveTab(3);
    } else if (location.pathname.includes('dashboard')) {
      setActiveTab(2);
    } else if (location.pathname.includes('/user/')) {
      setActiveTab(1);
    } else if (location.pathname.includes('/account/')) {
      setActiveTab(0);
    } else {
      setActiveTab(null);
    }
  }, [location.pathname]);

  if (!loggedInUser) {
    return <></>;
  }
  const { accountId } = loggedInUser;

  const handleAccountClick = () => {
    dispatch(setActiveUser(loggedInUser));
  };

  return (
    <SideBar>
      <Container>
        <StyledLogoContainer>
          <Link to={dashboardRoute}>
            <Logo src={iconAndNameWhite} />
          </Link>
        </StyledLogoContainer>
        <StyledTabLink $active={activeTab === 2} to={dashboardRoute}>
          <GridViewIcon />
          <StyledText>Dashboard</StyledText>
        </StyledTabLink>
        {/* TODO: Remove once production accounts and users have been created */}
        {accountId && (
          <StyledTabLink
            to={accountOverviewRoute(accountId)}
            $active={activeTab === 0}
            onClick={handleAccountClick}
          >
            <ManageAccountsIcon />
            <StyledText>Account</StyledText>
          </StyledTabLink>
        )}
        <StyledTabLink
          to={userOverviewRoute(loggedInUser.id)}
          $active={activeTab === 1}
          onClick={handleAccountClick}
        >
          <AccountCircleIcon />
          <StyledText>User</StyledText>
        </StyledTabLink>
        {loggedInUser?.role === 'admin' && (
          <StyledTabLink to={adminDashBoardRoute} $active={activeTab === 3}>
            <AdminPanelSettingsIcon />
            <StyledText>Admin Dashboard</StyledText>
          </StyledTabLink>
        )}
      </Container>
    </SideBar>
  );
};

const SideBar = styled.div`
  width: 15%;
  min-width: 220px;
  background-color: ${colors.paleGrey};
  border-right: 2px solid ${colors.backgroundBlue};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.paleGrey};
  margin-bottom: ${spacing.sm};
`;

const StyledLogoContainer = styled.div`
  max-height: 70px;
`;

const Logo = styled.img`
  width: 100%;
  object-fit: contain;
  max-height: 70px;
  box-sizing: border-box;
  background-color: ${colors.blue};
  padding: ${spacing.md} ${spacing.lg};
`;

const StyledTabLink = styled(TabLink)<{ $active: boolean }>`
  && {
    display: flex;
    align-items: center;

    ${({ $active }) =>
      $active &&
      `
    background-color: ${colors.backgroundBlue};
    border-bottom: 0;
    border-radius: 0;
    margin-bottom: 0;
    font-weight: bold;
  `};

    &:hover {
      opacity: 1;
      background-color: ${colors.backgroundBlue};
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
`;

const StyledText = styled.span`
  margin-left: ${spacing.md};
  padding-top: 0;
`;
