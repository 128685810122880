import { useState } from 'react';
import { useAppSelector } from '../../../store';
import { Pub } from '../../../types';

export interface Result {
  pub: Pub;
  score: number;
}

export const useSearchPubs = () => {
  const pubs = useAppSelector((state) => state.admin.pubs);
  const [results, setResults] = useState<Pub[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const regexMatch = /['._-]/g;

  const handleSearch = (term: string) => {
    if (term === '') {
      resetSearch();
    } else {
      setSearchTerm(term.replace(regexMatch, ''));

      if (term === '') {
        setResults([]);
      }

      const searchWords = term.toLowerCase().split(' ');

      const matchingPubs = pubs
        .map((pub) => {
          const pubName = pub.name.toLocaleLowerCase().replace(regexMatch, '');
          let score = 0;

          searchWords.forEach((word) => {
            if (word !== 'the' && pubName === word) score += 10;

            if (word !== 'the' && pubName.startsWith(word)) score += 7;

            if (word !== 'the' && pubName.includes(word)) score += 5;

            if (word === 'the' && pubName.includes(word)) score += 0.5;
          });
          return { pub, score };
        })
        .filter((result) => result.score > 0);

      const results = matchingPubs
        .sort((a, b) => b.score - a.score)
        .slice(0, 5)
        .map((result) => result.pub);

      setResults(results);
    }
  };

  const resetSearch = () => {
    setSearchTerm('');
    setResults([]);
  };

  const handleListSelection = (pubName: string) => {
    setSearchTerm(pubName);
    setResults([]);
  };

  return {
    results,
    searchTerm,
    handleSearch,
    resetSearch,
    handleListSelection,
  };
};
