import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUpdateAccount } from '../../../../api/hooks/use-update-account';
import { Loader } from '../../../../components/loader';
import { spacing } from '../../../../components/styled';
import { ActionButton } from '../../../../features/shared/components/styled';
import {
  StyledForm,
  StyledInput,
  StyledLabel,
} from '../../../../features/shared/components/styled-form';
import { Account } from '../../../../types';

interface Props {
  account: Account;
}

export const AccountDetailsForm = ({ account }: Props) => {
  const [name, setName] = useState(account?.name);
  const [ownerId, setOwnerId] = useState(account?.ownerId);
  const { loading, updateAccountData } = useUpdateAccount();
  const navigate = useNavigate();
  const enableSubmit = name !== account.name || ownerId !== account.ownerId;

  if (loading) {
    return <Loader />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await updateAccountData(
      {
        ...account,
        name: name ?? '',
        ownerId: ownerId ?? null,
      },
      false,
      true
    );
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    navigate('../accounts');
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledLabel htmlFor='name'>Account Name</StyledLabel>
      <StyledInput
        id='name'
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <StyledLabel htmlFor='owner-id'>Owner User ID</StyledLabel>
      <StyledInput
        id='owner-id'
        value={ownerId ?? ''}
        onChange={(e) => {
          if (e.target.value !== '') {
            setOwnerId(e.target.value.trim());
          } else {
            setOwnerId(null);
          }
        }}
      />
      <StyledActionButton cancel={'true'} onClick={handleCancel} type='button'>
        Cancel
      </StyledActionButton>
      <StyledActionButton type='submit' disabled={!enableSubmit}>
        Update
      </StyledActionButton>
    </StyledForm>
  );
};

const StyledActionButton = styled(ActionButton)`
  && {
    margin-right: ${spacing.md};
    margin-left: ${spacing.md};
  }
`;
