import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../components/styled';
import { emptyImage } from '../../../images';

interface Props {
  setImgFile: (file: File | undefined | null) => void;
}

export const PasteImageUploader = ({ setImgFile }: Props) => {
  const handlePaste = (event: React.ClipboardEvent) => {
    const items = event.clipboardData.items;
    for (const item of items) {
      if (item.type.startsWith('image/')) {
        const file = item.getAsFile();
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          setImgFile(file);
        }
      }
    }
  };

  return (
    <HiddenInput
      placeholder='Copy and Paste an Image Here'
      onPaste={handlePaste}
      // Prevent text input
      value={''}
      onChange={() => {}}
    />
  );
};

const HiddenInput = styled.textarea`
  background-image: url(${emptyImage});
  background-position: center;
  height: 220px;
  width: 220px;
  box-sizing: border-box;
  text-align: center;
  resize: none;
  padding: ${spacing.md};
`;
