import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from 'styled-components';
import { colors, spacing } from '../../../../components/styled';
import { BannerType } from '../../../../types';
import { Icon } from './icon';
import { IconButton } from './icon-button';

interface BannerProps {
  /** Message to be shown on the banner */
  children?: React.ReactNode;
  /** Provides visual elements that determine if the banner is an error, a warning or a success message */
  type?: BannerType;
  /** Callback function for when the close icon is clicked. When empty, close icon will not be shown */
  onClose?: () => void;
  /** Provides a data-testid */
  'data-testid'?: string;
}

type ColorMap = { [key in BannerType]: string };

const colorMap: ColorMap = {
  error: 'red',
  success: 'green',
  warning: 'orange',
};

/**
 * Banner Component
 *
 * @param {BannerProps} props
 *
 * @returns JSX.ELEMENT
 */
export const Banner = React.forwardRef<HTMLDivElement, BannerProps>(
  (
    {
      children,
      onClose,
      'data-testid': dataTestId = 'banner',
      type = 'warning',
    },
    ref
  ) => {
    return (
      <StyledContainer $type={type} data-testid={dataTestId} ref={ref}>
        <StyledIcon color={colorMap[type]}>
          {type === 'success' ? (
            <CheckIcon />
          ) : type === 'error' ? (
            <ErrorIcon />
          ) : (
            <WarningIcon />
          )}
        </StyledIcon>
        <StyledTextWrapper data-testid='banner-text'>
          {children}
        </StyledTextWrapper>
        {onClose && (
          <IconWrapper>
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ color: colorMap[type] }} />
            </IconButton>
          </IconWrapper>
        )}
      </StyledContainer>
    );
  }
);

const StyledContainer = styled.div<{ $type: string }>`
  border-radius: 4px;
  display: flex;
  padding: ${spacing.lg};
  align-items: center;

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  ${({ $type }) => {
    if ($type === 'error') {
      return `
        background-color: pink;
        color: red;

        a {
          color: blue;
        }
      `;
    } else if ($type === 'warning') {
      return `
        background-color: ${colors.lightOrange};
        color: orange;

        a {
          color: blue;
        }
      `;
    } else {
      return `
        background-color: ${colors.lightGreen};
        color: green;
      

        a {
          color: blue;
        }
      `;
    }
  }}
`;

const StyledTextWrapper = styled.div`
  margin-left: ${spacing.md};
  flex: 1 1 auto;
`;

const IconWrapper = styled.div`
  margin-left: auto;
`;

const StyledIcon = styled(Icon)`
  flex: 0 0 auto;

  svg {
    width: 18px;
    height: 18px;
  }
`;
