import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { generateMenu } from '../../../api';
import { useUpdatePub } from '../../../api/hooks/use-update-pub';
import { HappyHours } from '../../../components/happy-hours';
import { Loader } from '../../../components/loader';
import { spacing } from '../../../components/styled';
import { CustomHeading2 } from '../../../components/styled/headings';
import { ActionButton } from '../../../features/shared/components/styled';
import {
  convertToHappyHoursByDay,
  TimeFormat,
  uniqueHappyHourTimes,
} from '../../../helpers/happy-hour-times';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  selectActivePub,
  selectUnpublishedChanges,
} from '../../../store/app-selectors';
import { setUnpublishedChanges } from '../../../store/app-slice';
import { HappyHourOptions } from '../new/happy-hours/happy-hours-options';
import { AddToMenuOption } from './add-to-menu-option';
import { Menu } from './menu';

export const UpdateHappyHours = () => {
  const activePub = useAppSelector(selectActivePub);
  const dispatch = useAppDispatch();
  const [food, setFood] = useState(activePub?.food ?? []);
  const [drinks, setDrinks] = useState(activePub?.drinks ?? []);
  const navigate = useNavigate();
  const [showAddToMenuInput, setShowAddToMenuInput] = useState(false);
  const [showNewMenuInput, setShowNewMenuInput] = useState(false);
  const { updatePub } = useUpdatePub();
  const unpublishedChanges = useAppSelector(selectUnpublishedChanges);
  const [updatingMenu, setUpdatingMenu] = useState(false);
  const [tempHappyHours, setTempHappyHours] = useState<TimeFormat[]>([]);
  const [error, setError] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    if (activePub) {
      const { happyHours, openingHours } = activePub;
      setTempHappyHours(uniqueHappyHourTimes(happyHours, openingHours));
    }
  }, [activePub]);

  if (!activePub) {
    return <Loader />;
  }

  const { openingHours } = activePub;

  const handleNewTimes = (newTimes: TimeFormat[]) => {
    dispatch(setUnpublishedChanges(true));
    setTempHappyHours(newTimes);
  };

  const getMenuItems = async (
    text?: string,
    link?: string,
    file?: File | null
  ) => {
    setUpdatingMenu(true);

    const { name, vicinity } = activePub;
    try {
      return await generateMenu({
        name,
        vicinity,
        openingHours,
        link,
        file,
        text,
      });
    } catch (error) {
      setError((error as Error).message);
      setUpdatingMenu(false);
      return { food: [], drinks: [] };
    }
  };

  const handleNewMenuSubmit = async (
    text?: string,
    link?: string,
    file?: File | null
  ) => {
    setUpdatingMenu(true);
    const { food, drinks } = await getMenuItems(text, link, file);

    dispatch(setUnpublishedChanges(true));
    setDrinks(drinks);
    setFood(food);
    setShowNewMenuInput(false);
    setUpdatingMenu(false);
  };

  const handleAddToMenuSubmit = async () => {
    setText(text);
    setUpdatingMenu(true);
    const { food: newFood, drinks: newDrinks } = await getMenuItems(text);

    // Override existing items with new prices
    const newFoodLabels = newFood.map((food) => food.label);
    const newDrinkLabels = newDrinks.map((drink) => drink.label);
    const existingFood = food.filter(
      (item) => !newFoodLabels.includes(item.label)
    );
    const existingDrink = drinks.filter(
      (item) => !newDrinkLabels.includes(item.label)
    );

    dispatch(setUnpublishedChanges(true));
    setFood([...existingFood, ...newFood]);
    setDrinks([...existingDrink, ...newDrinks]);
    setShowNewMenuInput(false);
    setUpdatingMenu(false);
    setShowAddToMenuInput(false);
    setText('');
  };

  const handleCancel = () => {
    dispatch(setUnpublishedChanges(false));
    navigate('../overview');
  };

  const handlePublishChanges = async () => {
    const newHours = convertToHappyHoursByDay(tempHappyHours, openingHours);
    const newPubData = { ...activePub, food, drinks, happyHours: newHours };
    await updatePub(newPubData);
    dispatch(setUnpublishedChanges(false));
    navigate('../success');
  };

  if (openingHours.length === 0) {
    return (
      <>
        <p style={{ color: 'red' }}>
          Unable to add happy hours. This business is missing opening hour
          information
        </p>
      </>
    );
  }

  return (
    <>
      <>
        <HappyHours
          happyHours={tempHappyHours}
          setHappyHours={handleNewTimes}
          openingHours={openingHours}
        />
        <CustomHeading2
          style={{ marginBottom: spacing.md, marginTop: spacing.lg }}
        >
          Menu
        </CustomHeading2>

        {updatingMenu ? (
          <Loader />
        ) : (
          <>
            {showAddToMenuInput ? (
              <AddToMenuOption
                text={text}
                setText={setText}
                onSubmit={handleAddToMenuSubmit}
                onCancel={() => setShowAddToMenuInput(false)}
              />
            ) : showNewMenuInput ? (
              <HappyHourOptions
                onSubmit={handleNewMenuSubmit}
                error={error}
                setError={setError}
                onCancel={() => setShowNewMenuInput(false)}
              />
            ) : (
              <>
                <ButtonWrapper>
                  <StyledActionButton
                    onClick={() => setShowAddToMenuInput(true)}
                  >
                    Add to Menu
                  </StyledActionButton>
                  <ActionButton onClick={() => setShowNewMenuInput(true)}>
                    Enter a New Menu
                  </ActionButton>
                </ButtonWrapper>
                <Menu
                  drinks={drinks}
                  food={food}
                  setDrinks={setDrinks}
                  setFood={setFood}
                />
                {unpublishedChanges && (
                  <HorizontalWrapper>
                    <StyledActionButton cancel={'true'} onClick={handleCancel}>
                      Cancel
                    </StyledActionButton>
                    <ActionButton onClick={handlePublishChanges}>
                      Publish Changes
                    </ActionButton>
                  </HorizontalWrapper>
                )}
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

const HorizontalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  margin-bottom: ${spacing.lg};
`;

const StyledActionButton = styled(ActionButton)`
  && {
    margin-right: ${spacing.lg};
  }
`;
