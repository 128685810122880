import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../../components/loader';
import { useHandleRefresh } from '../../../../features/shared/hooks/use-handle-refresh';
import { useUpdatePubsState } from '../../../../features/shared/hooks/use-update-pubs-state';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectActivePub } from '../../../../store/app-selectors';
import {
  setActivePub,
  setUnpublishedChanges,
} from '../../../../store/app-slice';
import { useFetchPub } from '../../../admin/dashboard/new-business/search/hooks/use-fetch-pub';
import { businessHappyHoursRoute } from '../../../routes';
import { HappyHourOptions } from './happy-hours-options';

export const NewBusinessHappyHours = () => {
  const activePub = useAppSelector(selectActivePub);
  useHandleRefresh();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, error, setError, fetchPlaceData } = useFetchPub();
  const { updatePubsState } = useUpdatePubsState();

  if (!activePub || loading) {
    return <Loader />;
  }

  const handleSubmit = async (
    text?: string,
    link?: string,
    file?: File | null
  ) => {
    dispatch(setUnpublishedChanges(true));
    const pub = await fetchPlaceData(activePub, text, link, file);
    dispatch(setActivePub(pub));
    updatePubsState(pub);
    navigate(businessHappyHoursRoute(pub.googlePlaceId));
  };

  return (
    <HappyHourOptions
      onSubmit={handleSubmit}
      error={error}
      setError={setError}
      onCancel={() => {}}
    />
  );
};
