import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';
import { pastDate } from '../../../../helpers/date';
import { useAppDispatch } from '../../../../store';
import { setActiveEvent } from '../../../../store/app-slice';
import { EventDate, Pub, PubEvent } from '../../../../types';
import { EventTimePickers } from './event-time-pickers';
import { EventSection } from './events-section';
import { IndividualEventTimes } from './individual-event-times';
import { MultiDatePicker } from './multi-date-picker';

interface Props {
  event: PubEvent;
  futureDates: EventDate[];
  pub: Pub;
  setUnpublishedChanges: (val: boolean) => void;
}

export const EventTimingInputs = ({
  event,
  futureDates,
  pub,
  setUnpublishedChanges,
}: Props) => {
  const dispatch = useAppDispatch();

  const { dates } = event;
  const finalDates = futureDates.length > 0 ? futureDates : dates;

  const handleDatesChange = (eventDates: EventDate[]) => {
    setUnpublishedChanges(true);
    const activeDates = eventDates.filter((eventDate) => !pastDate(eventDate));
    const firstDate = activeDates[0];

    dispatch(
      setActiveEvent({
        ...event,
        dates: activeDates,
        // Deprecate
        date: firstDate?.date ?? null,
        recurrenceDates: activeDates,
      })
    );
  };

  const handleNewStartTime = (newStartTime: string) => {
    setUnpublishedChanges(true);
    let newDates: EventDate[] = [];
    if (finalDates.length === 0) {
      newDates = [{ date: '', times: { startTime: newStartTime } }];
    } else {
      newDates = finalDates.map(({ date, times }) => ({
        date,
        times: { ...times, startTime: newStartTime },
      }));
    }

    dispatch(
      setActiveEvent({
        ...event,
        pubId: pub.googlePlaceId,
        dates: newDates,
        // Deprecate
        times: { ...newDates[0].times, startTime: newStartTime },
      })
    );
  };

  const handleNewEndTime = (newEndTime: string | null) => {
    setUnpublishedChanges(true);

    if (newEndTime) {
      const newDates = finalDates.map(({ date, times }) => ({
        date,
        times: { ...times, endTime: newEndTime },
      }));

      dispatch(
        setActiveEvent({
          ...event,
          dates: newDates,
          pubId: pub.googlePlaceId,
          // Deprecate
          times: { ...newDates[0].times, endTime: newEndTime },
        })
      );
    } else {
      const newDates = finalDates.map(({ date, times }) => ({
        date,
        times: { startTime: times.startTime },
      }));

      dispatch(
        setActiveEvent({
          ...event,
          dates: newDates,
          pubId: pub.googlePlaceId,
          // Deprecate
          times: { startTime: newDates[0].times.startTime },
        })
      );
    }
  };

  return (
    <EventSection heading='Dates' width='49%'>
      <TimePickersWrapper>
        <EventTimePickers
          times={dates[0]?.times}
          onStartChange={handleNewStartTime}
          onEndChange={handleNewEndTime}
        />
      </TimePickersWrapper>
      <MultiDatePicker
        eventDates={finalDates}
        setEventDates={handleDatesChange}
      />
      <IndividualEventTimes
        eventDates={finalDates}
        handleDatesChange={handleDatesChange}
      />
      <p />
    </EventSection>
  );
};

const TimePickersWrapper = styled.div`
  margin-bottom: ${spacing.xl};
`;
