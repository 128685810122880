import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Account, Pub, PubEvent, User } from 'src/client/app/types';

export interface AppSliceState {
  dineOutPubs: Pub[];
  token: string | null;
  activePub?: Pub;
  activeEvent?: PubEvent;
  activeAccount?: Account;
  activeUser?: User;
  unpublishedChanges: boolean;
  loadingData: boolean;
  existingPub: boolean;
}

export const initialState: AppSliceState = {
  dineOutPubs: [],
  token: null,
  unpublishedChanges: false,
  loadingData: false,
  existingPub: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setDineOutPubs(state, action: PayloadAction<Pub[]>) {
      state.dineOutPubs = action.payload;
    },
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
    setActivePub(state, action: PayloadAction<Pub | undefined>) {
      state.activePub = action.payload;
    },
    setActiveEvent(state, action: PayloadAction<PubEvent>) {
      state.activeEvent = action.payload;
    },
    setActiveUser(state, action: PayloadAction<User>) {
      state.activeUser = action.payload;
    },
    setUnpublishedChanges(state, action: PayloadAction<boolean>) {
      state.unpublishedChanges = action.payload;
    },
    setActiveAccount(state, action: PayloadAction<Account>) {
      state.activeAccount = action.payload;
    },
    setLoadingData(state, action: PayloadAction<boolean>) {
      state.loadingData = action.payload;
    },
    setExistingPub(state, action: PayloadAction<boolean>) {
      state.existingPub = action.payload;
    },
  },
});

export const {
  setDineOutPubs,
  setToken,
  setActiveEvent,
  setActivePub,
  setActiveAccount,
  setActiveUser,
  setUnpublishedChanges,
  setLoadingData,
  setExistingPub,
} = appSlice.actions;
export const appReducer = appSlice.reducer;
