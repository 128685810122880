import { PubEvent } from '../../types';
import { baseUrl } from '../base-url';

export const processEventsText = async (pubId: string, text: string) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${baseUrl}/api/events/analyse-text`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ pubId, eventsList: text }),
    });

    const data: PubEvent[] = await response.json();

    return data;
  } catch (err) {
    console.log('Failed to analyse events data.', err);
    throw err;
  }
};
