import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';
import { ActionButton } from '../../../../features/shared/components/styled';
import { PubEvent } from '../../../../types';

interface Props {
  event: PubEvent;
  publishDisabled: boolean;
  handleCancel: () => void;
  actionButtonText?: string;
  cancelButtonText?: string;
  onDelete: (event: PubEvent) => void;
  error: string;
  unpublishedChanges: boolean;
}

export const EventFormButtons = ({
  event,
  publishDisabled,
  handleCancel,
  actionButtonText,
  cancelButtonText,
  onDelete,
  error,
  unpublishedChanges,
}: Props) => {
  const cancelText = cancelButtonText
    ? cancelButtonText
    : unpublishedChanges
    ? 'Discard Changes'
    : 'Back';

  return (
    <>
      <ButtonsWrapper>
        <StyledActionButton
          type='button'
          cancel={'true'}
          onClick={handleCancel}
        >
          {cancelText}
        </StyledActionButton>
        <StyledDeleteButton type='button' onClick={() => onDelete(event)}>
          Delete Event
        </StyledDeleteButton>
        <StyledActionButton
          type='submit'
          disabled={publishDisabled || !unpublishedChanges}
        >
          {actionButtonText ?? 'Publish'}
        </StyledActionButton>
      </ButtonsWrapper>
      <WarningWrapper>
        <span>{error}</span>
      </WarningWrapper>
    </>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${spacing.sm};
`;

const WarningWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${spacing.lg};
  color: red;
`;

const StyledActionButton = styled(ActionButton)`
  && {
    margin-top: 0;
    margin-left: ${spacing.md};
    margin-right: ${spacing.md};
  }
`;

const StyledDeleteButton = styled(StyledActionButton)`
  && {
    color: red;
    border: 1px red solid;
    background-color: white;
  }
`;
