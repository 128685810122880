import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Account, Pub, PubEvent, User } from 'src/client/app/types';

export interface UserSliceState {
  pubs: Pub[];
  user: User | null;
  account: Account | null;
  events: PubEvent[];
}

export const initialState: UserSliceState = {
  pubs: [],
  account: null,
  user: null,
  events: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUsersPubs(state, action: PayloadAction<Pub[]>) {
      state.pubs = action.payload;
    },
    setLoggedInAccount(state, action: PayloadAction<Account | null>) {
      state.account = action.payload;
    },
    setLoggedInUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload;
    },
    setUsersEventsList(state, action: PayloadAction<PubEvent[]>) {
      state.events = action.payload;
    },
  },
});

export const {
  setUsersPubs,
  setLoggedInAccount,
  setLoggedInUser,
  setUsersEventsList,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
