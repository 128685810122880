import { Account } from '../../types';
import { baseUrl } from '../base-url';

export const updateAccount = async (account: Partial<Account>) => {
  try {
    const token = localStorage.getItem('token');

    return await fetch(`${baseUrl}/api/account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(account),
    });
  } catch (err) {
    console.log('Failed to update account data.', err);
    throw err;
  }
};
