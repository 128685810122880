import { useAppDispatch, useAppSelector } from '../../../store';
import { selectAdminAccountsList } from '../../../store/admin-selectors';
import { setAdminAccountsList } from '../../../store/admin-slice';
import { setActiveAccount } from '../../../store/app-slice';
import { selectLoggedInUser } from '../../../store/user-selectors';
import { setLoggedInAccount } from '../../../store/user-slice';
import { Account } from '../../../types';

export const useUpdateAccountsState = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectLoggedInUser);
  const accounts = useAppSelector(selectAdminAccountsList);
  const isAdmin = user?.role === 'admin';

  return (
    newAccountInfo: Account,
    updateLoggedInAccount: boolean,
    updateActiveAccount: boolean
  ) => {
    if (updateLoggedInAccount) {
      dispatch(setLoggedInAccount(newAccountInfo));
    }
    if (updateActiveAccount) {
      dispatch(setActiveAccount(newAccountInfo));
    }

    if (isAdmin) {
      const filteredAccounts =
        accounts?.filter((acc) => acc.id !== newAccountInfo.id) ?? [];
      const newAccountsList = [...filteredAccounts, newAccountInfo];

      dispatch(setAdminAccountsList(newAccountsList));
    }
  };
};
