import { useAppDispatch, useAppSelector } from '../../../store';
import {
  selectAdminAccountsList,
  selectAdminEventsList,
  selectAdminPubs,
} from '../../../store/admin-selectors';
import {
  setAdminAccountsList,
  setAdminEventsList,
  setAdminPubs,
} from '../../../store/admin-slice';
import { selectActivePub } from '../../../store/app-selectors';
import { setActivePub } from '../../../store/app-slice';
import {
  selectLoggedInAccount,
  selectLoggedInUser,
  selectUserPubs,
} from '../../../store/user-selectors';
import { setLoggedInAccount, setUsersPubs } from '../../../store/user-slice';
import { Account, Pub } from '../../../types';

export const useUpdatePubsState = () => {
  const dispatch = useAppDispatch();
  const usersPubs = useAppSelector(selectUserPubs);
  const adminPubs = useAppSelector(selectAdminPubs);
  const activePub = useAppSelector(selectActivePub);
  const loggedInAccount = useAppSelector(selectLoggedInAccount);
  const user = useAppSelector(selectLoggedInUser);
  const accounts = useAppSelector(selectAdminAccountsList);
  const isAdmin = user?.role === 'admin';
  const adminEvents = useAppSelector(selectAdminEventsList);

  const deletePubsState = (pub: Pub) => {
    const { googlePlaceId, events } = pub;

    // Update user's pubs
    if (usersPubs.some((p) => p.googlePlaceId === googlePlaceId)) {
      const newList = usersPubs.filter(
        (p) => p.googlePlaceId !== googlePlaceId
      );
      dispatch(setUsersPubs(newList));
    }

    // Update admin pubs list
    if (isAdmin) {
      const newList = adminPubs.filter(
        (p) => p.googlePlaceId !== googlePlaceId
      );
      dispatch(setAdminPubs(newList));

      // Delete the events from the admin list
      const eventIds = events?.map((e) => e.id);
      const filteredEvents = adminEvents.filter(
        (e) => !eventIds?.includes(e.id)
      );
      dispatch(setAdminEventsList(filteredEvents));
    }
  };

  const updatePubsState = (newPubData: Pub) => {
    const ownedByLoggedInUser = usersPubs.some(
      (p) => p.googlePlaceId === newPubData.googlePlaceId
    );
    const oldPubsList = isAdmin ? adminPubs : usersPubs;
    const oldPub = oldPubsList.find(
      (p) => p.googlePlaceId === newPubData.googlePlaceId
    );

    // Update user's pubs
    if (ownedByLoggedInUser) {
      const filteredPubs = usersPubs.filter(
        (pub) => pub.googlePlaceId !== newPubData.googlePlaceId
      );

      dispatch(setUsersPubs([...filteredPubs, newPubData]));
    }

    // Update admin pubs
    if (isAdmin) {
      const filteredAdminPubs = adminPubs.filter(
        (pub) => pub.googlePlaceId !== newPubData.googlePlaceId
      );
      dispatch(setAdminPubs([...filteredAdminPubs, newPubData]));
    }

    if (oldPub?.accountId !== newPubData.accountId) {
      const previousAccountId = oldPub?.accountId;
      const newAccountId = newPubData?.accountId;
      const newAccount = accounts.find((acc) => acc.id === newAccountId);

      // Update logged in account if it now owns the business. Only affects admin account.
      if (loggedInAccount && loggedInAccount.id === newPubData.accountId) {
        dispatch(
          setLoggedInAccount({
            ...loggedInAccount,
            pubs: [...loggedInAccount?.pubs, newPubData],
          })
        );
      }

      if (isAdmin && newAccount) {
        const newAccountData: Account = {
          ...newAccount,
          pubs: [...newAccount?.pubs, newPubData],
        };
        const otherAccounts = accounts.filter(
          (acc) => acc.id !== newAccount.id && acc.id !== previousAccountId
        );

        if (previousAccountId) {
          // Remove from the old account, if there was one
          const previousAccount = accounts.find(
            (a) => a.id === previousAccountId
          );
          if (previousAccount) {
            const updatedPreviousAccount = {
              ...previousAccount,
              pubs: previousAccount?.pubs.filter(
                (p) => p.googlePlaceId !== newPubData.googlePlaceId
              ),
            };

            if (updatedPreviousAccount) {
              dispatch(
                setAdminAccountsList([
                  ...otherAccounts,
                  newAccountData,
                  updatedPreviousAccount,
                ])
              );
            }
          }
        } else {
          dispatch(setAdminAccountsList([...otherAccounts, newAccountData]));
        }
      }
    }

    // Update active pub - order is important. activePub owner is checked above before this change.
    if (newPubData.googlePlaceId === activePub?.googlePlaceId) {
      dispatch(setActivePub(newPubData));
    }
  };

  return { deletePubsState, updatePubsState };
};
