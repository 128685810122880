import React from 'react';
import { useNavigate } from 'react-router-dom';
import { removeEvent } from '../../../api/events/remove-event';
import { Loader } from '../../../components/loader';
import { EventsTables } from '../../../features/shared/components/events-tables';
import { ActionButton } from '../../../features/shared/components/styled';
import { useSendAlert } from '../../../features/shared/hooks/use-send-alert';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectActivePub } from '../../../store/app-selectors';
import { setActiveEvent, setActivePub } from '../../../store/app-slice';
import { PubEvent } from '../../../types';
import { newEventsPath } from '../../routes';
import { generateInitialEvent } from '../helpers/empty';

export const BusinessEvents = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activePub = useAppSelector(selectActivePub);
  const initialEvent = generateInitialEvent(activePub?.googlePlaceId);
  const sendAlert = useSendAlert();

  if (!activePub) {
    return <Loader />;
  }

  const handleDeleteEvent = async ({ id, name }: PubEvent) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete: \n\n${name}`
    );

    if (id && deleteConfirmed) {
      const response = await removeEvent(id);

      if (activePub?.events && response?.status === 200) {
        const filteredEvents = activePub.events.filter(
          (eventData) => eventData.id !== id
        );
        dispatch(setActivePub({ ...activePub, events: filteredEvents }));
      }
    }
  };

  const handleNewEventClick = () => {
    dispatch(setActiveEvent(initialEvent));
    navigate(newEventsPath);
  };

  if (!activePub) {
    return <Loader />;
  }

  return (
    <>
      <ActionButton onClick={handleNewEventClick}>Add New Event</ActionButton>
      <EventsTables
        events={activePub?.events}
        onDelete={handleDeleteEvent}
        showDuplicate={true}
      />
      <ActionButton onClick={handleNewEventClick}>Add New Event</ActionButton>
    </>
  );
};
