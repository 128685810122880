import { useAppSelector } from '../../../store';
import { selectAdminPubs } from '../../../store/admin-selectors';
import {
  selectLoggedInUser,
  selectUserPubs,
} from '../../../store/user-selectors';

export const useLookupPub = (googlePlaceId: string) => {
  const usersPubs = useAppSelector(selectUserPubs);
  const adminPubs = useAppSelector(selectAdminPubs);
  const user = useAppSelector(selectLoggedInUser);
  const isAdmin = user?.role === 'admin';
  const pubs = isAdmin ? adminPubs : usersPubs;

  return pubs.find((pub) => pub.googlePlaceId === googlePlaceId);
};
