import React, { useContext } from 'react';
import { BannerType } from '../../../../types';

export interface Message {
  message: string;
  timeout?: number;
  type: BannerType;
}

export type MessageWithKey<T> = {
  message: keyof T;
  type?: Message['type'];
  timeout?: Message['timeout'];
};

export type SendAlert = (message: Message) => QueuedMessage;

export interface QueuedMessage extends Message {
  key: number;
  dismiss: () => void;
}

export interface Messages {
  [key: number]: QueuedMessage;
}

interface Context {
  alerts: Messages;
  sendAlert: SendAlert;
  clearAlerts: () => void;
}

const defaultContext: Context = {
  alerts: {},
  sendAlert: (() => null) as unknown as SendAlert,
  clearAlerts: () => null,
};

export const AlertContext = React.createContext(defaultContext);

export const useAlertContext = () => useContext(AlertContext);
