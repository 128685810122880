import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../../../../components/protected-route';
import { AccountDetails } from '../../../admin/dashboard/accounts/account-details-view';
import { AccountsTab } from '../../../admin/dashboard/accounts/accounts-tab';
import { NewAccountForm } from '../../../admin/dashboard/accounts/new-account';
import { AdminDashboard } from '../../../admin/dashboard/admin-dashboard';
import { AdminPubs } from '../../../admin/dashboard/businesses/admin-pubs';
import { DineOutDataFetch } from '../../../admin/dashboard/dine-out-data-fetch/dine-out-trigger';
import { DineOutTab } from '../../../admin/dashboard/dine-out-listings/dine-out-list';
import { AdminEvents } from '../../../admin/dashboard/events/admin-events';
import { PubSearch } from '../../../admin/dashboard/new-business/search/pub-search';
import { NotificationsTab } from '../../../admin/dashboard/notifications/notifications-tab';
import { UserDetails } from '../../../admin/dashboard/users/user-details';
import { UsersTab } from '../../../admin/dashboard/users/users-tab';
import { BusinessRoot } from './business-root';
import { NewBusinessRoot } from './new-business-root';

export const AdminRoot = () => {
  return (
    <Routes>
      <Route
        path=''
        element={
          <ProtectedRoute element={AdminDashboard} requireAdmin={true} />
        }
      >
        <Route
          path=''
          element={<ProtectedRoute element={AdminPubs} requireAdmin={true} />}
        />
        <Route
          path='businesses'
          element={<ProtectedRoute element={AdminPubs} requireAdmin={true} />}
        />
        <Route
          path='accounts'
          element={<ProtectedRoute element={AccountsTab} requireAdmin={true} />}
        />
        <Route
          path='users'
          element={<ProtectedRoute element={UsersTab} requireAdmin={true} />}
        />
        <Route
          path='notifications'
          element={
            <ProtectedRoute element={NotificationsTab} requireAdmin={true} />
          }
        />
        <Route
          path='new-business/*'
          element={<ProtectedRoute element={PubSearch} requireAdmin={true} />}
        />
        <Route
          path='new-account/*'
          element={
            <ProtectedRoute element={NewAccountForm} requireAdmin={true} />
          }
        />
        <Route
          path='events'
          element={<ProtectedRoute element={AdminEvents} requireAdmin={true} />}
        />
        <Route
          path='dine-out'
          element={<ProtectedRoute element={DineOutTab} requireAdmin={true} />}
        />
        <Route
          path='dine-out-data-fetch'
          element={
            <ProtectedRoute element={DineOutDataFetch} requireAdmin={true} />
          }
        />
      </Route>
      <Route
        path=':googlePlaceId/*'
        element={<ProtectedRoute element={BusinessRoot} />}
      />
      <Route
        path='businesses/:googlePlaceId/*'
        element={<ProtectedRoute element={BusinessRoot} />}
      />
      <Route
        path='new-business/:newGooglePlaceId/*'
        element={<ProtectedRoute element={NewBusinessRoot} />}
      />
      <Route
        path='accounts/:accountId/*'
        element={<ProtectedRoute element={AccountDetails} />}
      />
      <Route
        path='users/:userId/*'
        element={<ProtectedRoute element={UserDetails} />}
      />
      <Route
        path='businesses/new-business/:newGooglePlaceId/*'
        element={<ProtectedRoute element={NewBusinessRoot} />}
      />
    </Routes>
  );
};
