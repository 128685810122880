import { useAppDispatch, useAppSelector } from '../../../store';
import { selectAdminEventsList } from '../../../store/admin-selectors';
import { setAdminEventsList } from '../../../store/admin-slice';
import { selectActivePub } from '../../../store/app-selectors';
import { setActiveEvent, setActivePub } from '../../../store/app-slice';
import {
  selectLoggedInUser,
  selectUserPubs,
} from '../../../store/user-selectors';
import { setUsersPubs } from '../../../store/user-slice';
import { Pub, PubEvent } from '../../../types';
import { useUpdatePubsState } from './use-update-pubs-state';

export const useUpdateEventsState = () => {
  const dispatch = useAppDispatch();
  const pubs = useAppSelector(selectUserPubs);
  const usersPubs = useAppSelector(selectUserPubs);
  const activePub = useAppSelector(selectActivePub);
  const user = useAppSelector(selectLoggedInUser);
  const isAdmin = user?.role === 'admin';
  const adminEvents = useAppSelector(selectAdminEventsList);
  const { updatePubsState } = useUpdatePubsState();

  const deleteEventState = (oldPub: Pub, { id, pubId }: PubEvent) => {
    const newEventsList = oldPub.events?.filter(
      (eventData) => eventData.id !== id
    );
    const newPub = {
      ...oldPub,
      events: newEventsList,
    };

    // Update pubs state
    updatePubsState(newPub);

    // Update active pub
    if (pubId === activePub?.googlePlaceId) {
      dispatch(setActivePub(newPub));
    }

    // Update admin events list
    if (isAdmin) {
      const filteredAdminEvents = adminEvents.filter(
        (eventData) => eventData.id !== id
      );

      dispatch(setAdminEventsList(filteredAdminEvents));
    }
  };

  const updateEventsState = (oldPub: Pub, newEvent: PubEvent) => {
    const filteredEvents =
      oldPub.events?.filter((ev) => ev.id !== newEvent.id) ?? [];
    const newEventsList = [...filteredEvents, newEvent];
    const newPub = {
      ...oldPub,
      events: newEventsList,
    };

    // Update user's pubs if included (admin vs non-admin)
    if (usersPubs.some((p) => p.googlePlaceId === newEvent.pubId)) {
      const filteredPubs = pubs.filter(
        (pub) => pub.googlePlaceId !== pub.googlePlaceId
      );
      dispatch(setUsersPubs([...filteredPubs, newPub]));
    }

    // Update pubs state
    updatePubsState(newPub);

    // Update active event
    dispatch(setActiveEvent(newEvent));

    if (isAdmin) {
      // Update admin events list
      const filteredAdminEvents =
        adminEvents.filter((ev) => ev.id !== newEvent.id) ?? [];
      const newAdminEventsList = [...filteredAdminEvents, newEvent];
      dispatch(setAdminEventsList(newAdminEventsList));
    }
  };

  return { updateEventsState, deleteEventState };
};
