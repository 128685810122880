import { Account } from '../../types';
import { baseUrl } from '../base-url';

export const fetchAccounts = async () => {
  try {
    const token = localStorage.getItem('token');
    const result = await fetch(`${baseUrl}/api/accounts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const pubs: Account[] = await result.json();

    return pubs;
  } catch (err) {
    console.log('Failed to fetch accounts.', err);
    throw err;
  }
};
