import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../../components/loader';
import { ActionButton } from '../../../features/shared/components/styled';
import { useAppSelector } from '../../../store';
import { selectActivePub } from '../../../store/app-selectors';

export const PubUpdateSuccess = () => {
  const activePub = useAppSelector(selectActivePub);
  const navigate = useNavigate();

  if (!activePub) {
    return <Loader />;
  }

  return (
    <Container>
      <span
        style={{ marginBottom: '10px' }}
      >{`Information for ${activePub?.name} has been updated successfully.`}</span>
      <ActionButton
        onClick={() => navigate('../overview')}
        style={{ width: '180px' }}
      >
        Go to Overview
      </ActionButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
