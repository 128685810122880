import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeEvent } from '../../../../api/events/remove-event';
import { updateEvent } from '../../../../api/events/update-event';
import { useUpdatePub } from '../../../../api/hooks/use-update-pub';
import { Loader } from '../../../../components/loader';
import { useHandleRefresh } from '../../../../features/shared/hooks/use-handle-refresh';
import { useSendAlert } from '../../../../features/shared/hooks/use-send-alert';
import { useUpdateEventsState } from '../../../../features/shared/hooks/use-update-events-state';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  selectActiveEvent,
  selectActivePub,
  selectExistingPub,
} from '../../../../store/app-selectors';
import { setActivePub, setExistingPub } from '../../../../store/app-slice';
import { selectUserPubs } from '../../../../store/user-selectors';
import { PubEvent } from '../../../../types';
import { businessEventsRoute, dashboardRoute } from '../../../routes';
import { EventForm } from './event-form';

export const EventOverview = () => {
  const { loading } = useHandleRefresh();
  const navigate = useNavigate();
  const activeEvent = useAppSelector(selectActiveEvent);
  const activePub = useAppSelector(selectActivePub);
  const existingPub = useAppSelector(selectExistingPub);
  const dispatch = useAppDispatch();
  const usersPubs = useAppSelector(selectUserPubs);
  const { updateEventsState } = useUpdateEventsState();
  const [updating, setUpdating] = useState(false);
  const { updatePub } = useUpdatePub();
  const sendAlert = useSendAlert();

  useEffect(() => {
    if (!loading && !activeEvent && !activePub) {
      navigate(dashboardRoute);
    } else if (!loading && activePub && !activeEvent) {
      navigate(businessEventsRoute(activePub.googlePlaceId));
    } else if (!loading && activeEvent && !activePub && usersPubs.length > 0) {
      const activePub = usersPubs?.find(
        (pub) => pub.googlePlaceId === activeEvent.pubId
      );
      if (activePub) {
        dispatch(setActivePub(activePub));
      } else {
        navigate(dashboardRoute);
      }
    }
  }, [loading]);

  if (!activeEvent || !activePub) {
    return <Loader />;
  }

  const onCancel = () => {
    navigate(businessEventsRoute(activePub.googlePlaceId));
  };

  const onSubmit = async (
    newEventData: PubEvent,
    imgFile: File | undefined | null
  ) => {
    setUpdating(true);
    try {
      // If pub doesn't exist, create it first
      if (!existingPub) {
        await updatePub(activePub);
      }
      const newEvent = await updateEvent(newEventData, imgFile);
      updateEventsState(activePub, newEvent);

      setUpdating(false);
      navigate(businessEventsRoute(activePub.googlePlaceId));
      dispatch(setExistingPub(true));
      sendAlert({ message: 'PUBLISH_SUCCESS' });
    } catch (error) {
      sendAlert({ message: 'PUBLISH_ERROR', type: 'error' });
      setUpdating(false);
    }
  };

  const handleDeleteEvent = async ({ id, name }: PubEvent) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete: \n\n${name}`
    );

    if (id && deleteConfirmed) {
      try {
        setUpdating(true);
        const response = await removeEvent(id);

        if (activePub?.events && response?.status === 200) {
          const filteredEvents = activePub.events.filter(
            (eventData) => eventData.id !== id
          );
          dispatch(setActivePub({ ...activePub, events: filteredEvents }));
          navigate(businessEventsRoute(activePub.googlePlaceId));
        }
        setUpdating(false);
      } catch (error) {
        sendAlert({ message: 'DELETE_ERROR', type: 'error' });
      }
    }
  };

  if (updating) {
    return <Loader text='Publishing...' />;
  }

  return (
    <EventForm
      onSubmit={onSubmit}
      onCancel={onCancel}
      onDelete={handleDeleteEvent}
    />
  );
};
