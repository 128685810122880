import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledTabButton } from './styled';

const generateRoute = (heading: string) =>
  heading.toLowerCase().replaceAll(' ', '-');

const checkPaths = (url: string, paths?: string[]) => {
  if (!paths) {
    return false;
  }

  return paths.some((path) => url.includes(path));
};

interface Props {
  headings: { heading: string; paths?: string[] }[];
}

export const HeadingTabs = ({ headings }: Props) => {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes(generateRoute(headings[tab].heading))) {
      headings.forEach((heading, index) => {
        if (
          location.pathname.includes(generateRoute(heading.heading)) ||
          checkPaths(location.pathname, heading.paths)
        ) {
          setTab(index);
        }
      });
    }
  }, [location.pathname]);

  return (
    <>
      {headings.map((heading, index) => {
        const active = index === tab;

        return (
          <StyledTabButton
            key={heading.heading}
            $active={active}
            onClick={() => {
              setTab(index);
              navigate(generateRoute(headings[index].heading));
            }}
          >
            {heading.heading}
          </StyledTabButton>
        );
      })}
    </>
  );
};
