import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { spacing } from '../../../components/styled';

interface TimeProps {
  startTime: boolean;
  value: string | undefined | null;
  onTimeChange: (time: string | null) => void;
  customText?: string;
  handleCustomTextClick?: () => void;
  disabled?: boolean;
}

export const TimeInput = ({
  startTime,
  value,
  onTimeChange,
  disabled = false,
}: TimeProps) => {
  const label = startTime ? 'Start Time' : 'End Time';
  const [open, setOpen] = useState(false);

  const handleChange = (newValue: dayjs.Dayjs | null) => {
    const mins = newValue?.minute();
    const minsString = mins === 0 ? '00' : mins;

    if (newValue && (value === '' || !value)) {
      onTimeChange(`${newValue.hour() + 12}:${minsString}`);
    } else {
      onTimeChange(`${newValue?.hour()}:${minsString}`);
    }
  };

  return (
    <StyledContainer>
      <StyledTimePicker
        open={open}
        onClose={() => setOpen(false)}
        label={label}
        minutesStep={15}
        skipDisabled={true}
        value={value ? dayjs(`2018-04-13 ${value}`) : null}
        onChange={handleChange}
        onAccept={handleChange}
        slotProps={{
          textField: {
            size: 'small',
            onClick: () => !disabled && setOpen(!open),
          },
          inputAdornment: { onClick: () => !disabled && setOpen(!open) },
        }}
        disabled={disabled}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: ${spacing.md};
`;

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  max-width: 140px;
  background-color: white;

  input {
    &:hover {
      cursor: pointer;
    }
  }
`;
