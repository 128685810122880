import React, { useState } from 'react';
import styled from 'styled-components';
import { updatePassword } from '../../../api';
import { Loader } from '../../../components/loader';
import { spacing } from '../../../components/styled';
import { ActionButton } from '../../../features/shared/components/styled';
import {
  StyledForm,
  StyledInput,
} from '../../../features/shared/components/styled-form';
import { useAppSelector } from '../../../store';
import { selectActiveUser } from '../../../store/app-selectors';

interface Props {
  setShowPasswordInput: (show: boolean) => void;
}

export const PasswordInputs = ({ setShowPasswordInput }: Props) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const activeUser = useAppSelector(selectActiveUser);

  if (!activeUser || loading) {
    return <Loader />;
  }

  const handleUpdatePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    await updatePassword(activeUser.id, oldPassword, newPassword);
    setLoading(false);
    setShowPasswordInput(false);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setShowPasswordInput(false);
  };

  return (
    <Container>
      <StyledForm onSubmit={handleUpdatePassword}>
        <label htmlFor='old-password'>Old Password</label>
        <StyledInput
          id='old-password'
          placeholder='Old Password'
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          type='password'
        />
        <label htmlFor='new-password'>New Password</label>
        <StyledInput
          id='new-password'
          placeholder='New Password'
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type='password'
        />
        <Row>
          <StyledActionButton cancel={'true'} onClick={handleCancel}>
            Cancel
          </StyledActionButton>
          <StyledActionButton type='submit'>Update</StyledActionButton>
        </Row>
      </StyledForm>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 50px;
`;

const Row = styled.div`
  display: flex;
`;

const StyledActionButton = styled(ActionButton)`
  && {
    margin-right: ${spacing.md};
    margin-left: ${spacing.md};
  }
`;
