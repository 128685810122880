import { PubEvent } from '../../types';
import { baseUrl } from '../base-url';

export const updateEvent = async (event: PubEvent, file?: File | null) => {
  try {
    const formData = new FormData();
    formData.append('event', JSON.stringify(event));

    if (file) {
      formData.append('file', file);
    }

    const token = localStorage.getItem('token');
    const response = await fetch(`${baseUrl}/api/event`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    });

    return await response.json();
  } catch (err) {
    console.log('Failed to create or update event.', err);
    throw err;
  }
};
