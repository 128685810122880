import { HappyHourInfo } from '../types';
import { baseUrl } from './base-url';
import { pdfFileUpload } from './pdf-file-upload';

interface Args {
  name: string;
  vicinity: string;
  openingHours: google.maps.places.PlaceOpeningHoursPeriod[];
  link?: string;
  file?: File | null;
  text?: string;
}

export const generateMenu = async ({
  name,
  vicinity,
  openingHours,
  link,
  file,
  text,
}: Args) => {
  try {
    const url = `${baseUrl}/api/generate-menu`;
    const bodyData = {
      name,
      vicinity,
      openingHours,
      link,
      text,
    };
    let menusAndHappyHours: HappyHourInfo;

    if (file) {
      menusAndHappyHours = await pdfFileUpload(url, { ...bodyData, file });
    } else {
      const token = localStorage.getItem('token');
      const result = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      if (result.status === 200) {
        menusAndHappyHours = await result.json();
      } else {
        const errorMessage: { error: string } = await result.json();

        throw new Error(errorMessage.error);
      }
    }

    console.log('menus', menusAndHappyHours);

    return menusAndHappyHours;
  } catch (err) {
    console.log('Failed to generate menus.', err);
    throw err;
  }
};
