import { EventWithFile, PubEvent } from '../../types';
import { baseUrl } from '../base-url';

export const bulkPublishEvents = async (eventsData: EventWithFile[]) => {
  try {
    const formData = new FormData();
    const { events, files } = eventsData.reduce(
      (acc, { imgFile, ...rest }) => {
        acc.events.push(rest);
        acc.files.push(imgFile || null);
        return acc;
      },
      { events: [] as PubEvent[], files: [] as (File | null)[] }
    );

    const fileIndexes: number[] = [];
    files.forEach((file, index) => {
      if (file) {
        formData.append(`files`, file);
        fileIndexes.push(index); // Track indexes that have files
      }
    });

    // Append event data as JSON
    formData.append('events', JSON.stringify(events));
    formData.append('fileIndexes', JSON.stringify(fileIndexes));

    const token = localStorage.getItem('token');
    const response = await fetch(`${baseUrl}/api/events/bulk`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    });

    if (response.status === 200 || response.status === 201) {
      const publishedEvents: PubEvent[] = await response.json();

      return publishedEvents;
    } else {
      throw new Error('Bulk create failed');
    }
  } catch (err) {
    console.log('Failed to create events in bulk.', err);
    throw err;
  }
};
