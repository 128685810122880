import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';
import { StyledLabel } from '../../../../features/shared/components/styled-form';
import { ButtonTextOption, buttonTextOptions } from '../../../../types';

interface Props {
  buttonText?: ButtonTextOption;
  setButtonText: (newType: ButtonTextOption) => void;
}

export const ButtonTextSelect = ({ buttonText, setButtonText }: Props) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setButtonText(event.target.value as ButtonTextOption);
  };

  return (
    <Container>
      <FormControl style={{ width: '100%' }}>
        <StyledLabel htmlFor='button-text-input'>Button Text</StyledLabel>
        <StyledSelect
          id='button-text-input'
          value={buttonText ?? 'Book Now'}
          onChange={handleChange}
          size='small'
        >
          {buttonTextOptions.map((text) => {
            return (
              <StyledMenuItem key={text} value={text}>
                {text}
              </StyledMenuItem>
            );
          })}
        </StyledSelect>
      </FormControl>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${spacing.lg};
`;

const StyledSelect = styled(Select)`
  min-width: 150px;
  margin-top: ${spacing.sm};
  background-color: white;
  text-transform: capitalize;
`;

const StyledMenuItem = styled(MenuItem)`
  min-width: 150px;
  text-transform: capitalize;
`;
