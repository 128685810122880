import { useAppDispatch, useAppSelector } from '../../../store';
import { selectAdminUsersList } from '../../../store/admin-selectors';
import { setAdminUsersList } from '../../../store/admin-slice';
import { setActiveUser } from '../../../store/app-slice';
import { selectLoggedInUser } from '../../../store/user-selectors';
import { setLoggedInUser } from '../../../store/user-slice';
import { User } from '../../../types';

export const useUpdateUsersState = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectLoggedInUser);
  const users = useAppSelector(selectAdminUsersList);
  const isAdmin = user?.role === 'admin';

  return (
    newUserInfo: User,
    updateLoggedInUser: boolean,
    updateActiveUser: boolean
  ) => {
    if (updateActiveUser) {
      dispatch(setActiveUser(newUserInfo));
    }

    if (updateLoggedInUser) {
      dispatch(setLoggedInUser(newUserInfo));
    }

    if (isAdmin) {
      const filteredUsers =
        users?.filter((user) => user.id !== newUserInfo.id) ?? [];
      const newUsersList = [...filteredUsers, newUserInfo];

      dispatch(setAdminUsersList(newUsersList));
    }
  };
};
