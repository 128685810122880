import { Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors, spacing } from '../../../components/styled';
import { copyEventToBusinessPath } from '../../../routes/routes';
import { useAppDispatch } from '../../../store';
import { setActivePub } from '../../../store/app-slice';
import { Pub } from '../../../types';
import { useSearchPubs } from '../hooks/use-search-pub';
import { ActionButton } from './styled';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const CopyModal = ({ open, setOpen }: Props) => {
  const {
    searchTerm,
    results,
    handleSearch,
    handleListSelection,
    resetSearch,
  } = useSearchPubs();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedPub, setSelectedPub] = useState<Pub | null>(null);

  useEffect(() => {
    resetSearch();
  }, [open]);

  const handleSelect = (result: Pub) => {
    setSelectedPub(result);
    handleListSelection(result.name);
  };

  const handleSubmit = () => {
    if (selectedPub) {
      dispatch(setActivePub(selectedPub));
      setOpen(false);
      // Active Event is set by the row when the modal is opened
      navigate(copyEventToBusinessPath(selectedPub.googlePlaceId));
    }
  };

  return (
    <StyledModal
      open={open}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } },
      }}
      aria-label='Event copy modal'
      aria-description='Select business to copy to'
    >
      <ContentContainer>
        <ModalHeader>
          <h2>Copy Event to Business</h2>
        </ModalHeader>
        <MainContent>
          <p>Copy to:</p>
          <StyledInput
            placeholder='Business name'
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ width: '100%' }}
          />
          {results && (
            <ResultContainer>
              {results.slice(0, 3).map((result) => {
                return (
                  <StyledResult
                    key={result.googlePlaceId}
                    onClick={() => handleSelect(result)}
                  >
                    {result.name}
                  </StyledResult>
                );
              })}
            </ResultContainer>
          )}
        </MainContent>

        <ModalFooter>
          <ButtonsWrapper>
            <StyledActionButton cancel={'true'} onClick={() => setOpen(false)}>
              Cancel
            </StyledActionButton>
            <StyledActionButton
              onClick={handleSubmit}
              disabled={selectedPub === null}
            >
              Copy
            </StyledActionButton>
          </ButtonsWrapper>
        </ModalFooter>
      </ContentContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 400px;
  border-radius: 5px;

  background-color: ${colors.paleGrey};
`;

const MainContent = styled.div`
  padding: ${spacing.lg};
`;

const StyledInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: ${spacing.md};
  margin-bottom: 0;
  border-radius: ${spacing.md};
  border-width: 0.5px;
`;

const ResultContainer = styled.div`
  min-height: 100px;
`;

const StyledResult = styled(Button)`
  && {
    width: 100%;
    color: ${colors.slate};
    padding: ${spacing.md};
    margin-top: 0;
    justify-content: flex-start;
    text-transform: capitalize;
    background-color: white;

    &:hover {
      background-color: ${colors.backgroundBlue};
    }
  }
`;

const ModalHeader = styled.div`
  background-color: ${colors.lightGrey};
  display: flex;
  flex-direction: row;
  padding: ${spacing.lg};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-family: 'Raleway';

  h2 {
    line-height: 1;
    font-weight: 400;
    margin: 0;
  }
`;

const ModalFooter = styled.div`
  background-color: ${colors.lightGrey};
  display: flex;
  flex-direction: row;
  padding: ${spacing.lg};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const StyledActionButton = styled(ActionButton)`
  && {
    margin-top: 0;
    margin-left: ${spacing.md};
  }
`;
