import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserType } from '../../../../../server/database/models/users';
import { loginRequest } from '../../../api/users/login';
import { useFetchData } from '../../../features/shared/hooks/use-fetch-data';
import { useAppDispatch } from '../../../store';
import { setToken } from '../../../store/app-slice';
import { setLoggedInAccount, setLoggedInUser } from '../../../store/user-slice';
import { Account, Pub } from '../../../types';

interface LoginResponse {
  account: Account;
  user: UserType;
  accessToken: string;
  pubs: Pub[];
}

export const useBusinessLogin = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useFetchData();

  const login = async (
    user: { email: string; password: string },
    turnstileToken: string
  ) => {
    setLoading(true);
    try {
      const response = await loginRequest(user, turnstileToken);

      if (response.status !== 200) {
        setError(true);
        setLoading(false);
      } else {
        const { account, user, accessToken }: LoginResponse =
          await response.json();

        dispatch(setToken(accessToken));
        localStorage.setItem('token', accessToken);
        dispatch(setLoggedInUser(user));
        dispatch(setLoggedInAccount(account));

        setError(false);
        setLoading(false);
        navigate('/business/dashboard');
      }
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log('Error logging in');
    }
  };

  return { loading, error, login };
};
