import { HappyHour, HappyHoursByDay, OpeningHours } from '../types';

export interface TimeFormat {
  time: {
    startTime: string;
    endTime: string;
  };
  extra: {
    useOpen: boolean;
    useClose: boolean;
  };
  days: number[];
}

const convertOpeningHoursToString = (
  openingHoursTime?: google.maps.places.PlaceOpeningHoursTime
) => {
  if (openingHoursTime) {
    const { hours, minutes } = openingHoursTime;
    const openHours = hours === 0 ? '24' : hours;
    const openMins = minutes === 0 ? '00' : minutes;

    return `${openHours}:${openMins}`;
  }

  return '';
};

const checkTimeMatch = (
  time: string,
  openingHoursTime: google.maps.places.PlaceOpeningHoursTime
) => {
  return time === convertOpeningHoursToString(openingHoursTime);
};

/**
 * Takes the happy hour times format used by the app and converts it into a more presentable format for setting the times.
 *
 * It makes it easier to display:
 * times - days they apply
 */
export const uniqueHappyHourTimes = (
  timesByDay: HappyHoursByDay,
  openingHours: OpeningHours
) => {
  return Object.entries(timesByDay ?? []).reduce(
    (acc: TimeFormat[], [day, times]) => {
      times.forEach((time) => {
        const extra = { useOpen: false, useClose: false };

        const openingInfo = openingHours.find(
          (hours) => hours.open.day === parseInt(day)
        )?.open;
        const closingInfo = openingHours.find(
          (hours) => hours.open.day === parseInt(day)
        )?.close;

        if (openingInfo && closingInfo) {
          const { startTime, endTime } = time;

          if (checkTimeMatch(startTime, openingInfo)) {
            extra.useOpen = true;
          }
          if (checkTimeMatch(endTime, closingInfo)) {
            extra.useClose = true;
          }
        }

        const entry = acc.find((item) => {
          const startTimeMatch =
            item.time.startTime === time.startTime ||
            (extra.useOpen && item.extra.useOpen);

          const endTimeMatch =
            item.time.endTime === time.endTime ||
            (extra.useClose && item.extra.useClose);

          return startTimeMatch && endTimeMatch;
        });

        if (entry) {
          // If the entry exists, add to the days array'
          entry.days.push(Number(day));
        } else {
          // If not, create a new entry with the time and days array
          acc.push({ time, extra, days: [Number(day)] });
        }
      });

      return acc;
    },
    []
  );
};

/**
 * Converts the happy hours format used for presenting to business owners back to the format
 * expected by the database and the app. It needs to account for values where the opening or
 * closing time should be used in place of the set value in the time object.
 */
export const convertToHappyHoursByDay = (
  timeFormats: TimeFormat[],
  openingHours: OpeningHours
): HappyHoursByDay => {
  const happyHoursByDay: HappyHoursByDay = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };

  timeFormats.forEach(({ time, days, extra }) => {
    // For each day in the days array, push the HappyHour object
    days.forEach((day) => {
      const happyHour: HappyHour = {
        startTime: time.startTime,
        endTime: time.endTime,
      };

      if (extra.useOpen) {
        const openingTime = convertOpeningHoursToString(
          openingHours.find((hours) => hours.open.day === day)?.open
        );
        happyHour.startTime = openingTime;
      }
      if (extra.useClose) {
        const closingTime = convertOpeningHoursToString(
          openingHours.find((hours) => hours.open.day === day)?.close
        );
        happyHour.endTime = closingTime;
      }

      happyHoursByDay[day as keyof HappyHoursByDay].push(happyHour);
    });
  });

  return happyHoursByDay;
};
