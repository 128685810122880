import React, { useContext } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styled from 'styled-components';
import { zIndexValue } from '../../components/styled';
import { AlertContext, QueuedMessage } from './alert-context';
import { Banner } from './banner';

interface AlertProps {
  /** Provides a data-testid */
  'data-testid'?: string;
}

/**
 * Alert Component with built in Tranition
 *
 * @param {AlertProps} param
 *
 * @return JSX.ELEMENT
 */
export const Alert = ({ 'data-testid': dataTestId }: AlertProps) => {
  const { alerts } = useContext(AlertContext);
  const nodeRef = React.useRef(null);

  return (
    // className={className}
    <Container data-testid={dataTestId}>
      <StyledTransitionGroup>
        {Object.values(alerts).map((alert: QueuedMessage, i: number) => (
          <CSSTransition
            nodeRef={nodeRef}
            classNames={'alerts__banner'}
            timeout={200}
            key={i}
          >
            <Banner ref={nodeRef} type={alert.type} onClose={alert.dismiss}>
              {alert.message}
            </Banner>
          </CSSTransition>
        ))}
      </StyledTransitionGroup>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  width: 100%;
  z-index: ${zIndexValue.alert};

  .alerts__banner-enter {
    transform: translateY(-100%);
    z-index: -1;
  }

  .alerts__banner-enter-active {
    transform: translateY(0);
    transition: transform 200ms;
  }

  .alerts__banner-exit {
    transform: translateY(0);
    z-index: -1;
  }

  .alerts__banner-exit-active {
    transform: translateY(-100%);
    transition: transform 200ms;
  }
`;

const StyledTransitionGroup = styled(TransitionGroup)`
  display: block;
  overflow: hidden;
`;
