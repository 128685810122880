// App
export const fullGooglePlaceFieldsList = [
  'geometry',
  'name',
  'place_id',
  'website',
  'opening_hours',
  'vicinity',
  'rating',
  'user_ratings_total',
];
