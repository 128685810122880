import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../../../../components/protected-route';
import { BulkEvents } from '../../../business/events/bulk/components/bulk-events';
import { EventOverview } from '../../../business/events/components/event-overview';
import { NewEventsOptions } from '../../../business/new/events/new-events-options';

export const EventsRoot = () => {
  return (
    <Routes>
      <Route
        path='new'
        element={<ProtectedRoute element={NewEventsOptions} />}
      />
      <Route
        path='new/manual'
        element={<ProtectedRoute element={EventOverview} />}
      />
      <Route
        path='new/bulk'
        element={<ProtectedRoute element={BulkEvents} />}
      />
      <Route
        path=':eventId'
        element={<ProtectedRoute element={EventOverview} />}
      />
    </Routes>
  );
};
