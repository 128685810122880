import { useCallback, useContext } from 'react';
import { BannerType } from '../../../types';
import {
  AlertContext,
  MessageWithKey,
} from '../styled-components/alerts/alert-context';
import { AlertMessages } from '../styled-components/alerts/alert-messages';

/**
 * Standardized timeouts, these can be overriden in the `sendAlert`
 * function
 */
const timeouts: Record<BannerType, number> = {
  error: 4000,
  success: 2000,
  warning: 2000,
};

export const useSendAlert = () => {
  const { sendAlert } = useContext(AlertContext);

  return useCallback(
    (options: MessageWithKey<typeof AlertMessages>) => {
      const messageType = options.type || 'success';
      const timeout = options.timeout || timeouts[messageType];

      return sendAlert({
        message: AlertMessages[options.message],
        type: messageType,
        timeout,
      });
    },
    // disabled so that sendAlert doesn't cause rerenders due to alert context changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
