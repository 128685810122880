import React, { useState } from 'react';

import { AlertContext, Message, Messages } from './alert-context';

interface Props {
  /** All decendent elements of contained in this context */
  children: React.ReactNode;
}

const useQueue = () => {
  const [state, setState] = useState({} as Messages);

  const dequeue = (key: number) => {
    setState((prev) => {
      const { [key]: _, ...next } = prev;
      return next;
    });
  };

  const queue = (val: Message) => {
    const key = Date.now();
    const dismiss = () => dequeue(key);
    const queuedMessage = { ...val, key, dismiss };

    setState((prev) => ({ ...prev, [key]: queuedMessage }));

    if (val.timeout) {
      setTimeout(dismiss, val.timeout);
    }

    return queuedMessage;
  };

  const clear = () => setState({});

  return { state, queue, dequeue, clear };
};

export const AlertProvider = ({ children }: Props) => {
  const { state: alerts, queue: sendAlert, dequeue: dismissAlert, clear: clearAlerts } = useQueue();
  const context = { alerts, sendAlert, dismissAlert, clearAlerts };

  return <AlertContext.Provider value={context}>{children}</AlertContext.Provider>;
};
