import React from 'react';
import styled from 'styled-components';
import { Loader } from '../../../components/loader';
import { spacing } from '../../../components/styled';
import {
  CustomHeading2,
  CustomHeading3,
  CustomHeading4,
} from '../../../components/styled/headings';
import { displayDay } from '../../../helpers/date';
import { useAppSelector } from '../../../store';
import { selectActivePub } from '../../../store/app-selectors';
import { EventsList } from './events-list';

const header = {
  starters: 'Appetizers',
  mains: 'Entrees',
  desserts: 'Desserts',
  firstCourse: 'First Course',
  secondCourse: 'Second Course',
  thirdCourse: 'Third Course',
  fourthCourse: 'Fourth Course',
  fifthCourse: 'Fifth Course',
  sixthCourse: 'Sixth Course',
  seventhCourse: 'Seventh Course',
  eigthCourse: 'Eigth Course',
};

export const Summary = () => {
  const activePub = useAppSelector(selectActivePub);

  if (!activePub) {
    return <Loader />;
  }

  const { happyHours, food, drinks, events, dineOut } = activePub;
  const showEvents = events && events.length > 0;

  return (
    <Container>
      <CustomHeading3>Happy Hour Times</CustomHeading3>
      <Content>
        {Object.entries(happyHours).map(([day, happyHours]) => {
          return (
            <HorizontalWrapper key={day}>
              <Day>{`${displayDay(day)}:`}</Day>
              <TimesWrapper>
                {happyHours.map((times) => (
                  <span key={times.startTime}>
                    {times.startTime} - {times.endTime}
                  </span>
                ))}
                {!happyHours.length && <span>-</span>}
              </TimesWrapper>
            </HorizontalWrapper>
          );
        })}
      </Content>
      {!!drinks.length && (
        <>
          <CustomHeading3>Drinks</CustomHeading3>
          <Content>
            {drinks.map(({ label, price }) => (
              <HorizontalWrapper key={label}>
                <Day>{`${label}:`}</Day>
                <span>{`$${price}`}</span>
              </HorizontalWrapper>
            ))}
          </Content>
        </>
      )}
      {!!food.length && (
        <>
          <CustomHeading3>Food</CustomHeading3>
          <Content>
            {food.map(({ label, price }) => (
              <HorizontalWrapper key={label}>
                <Day>{`${label}:`}</Day>
                <span>{`$${price}`}</span>
              </HorizontalWrapper>
            ))}
          </Content>
        </>
      )}
      {!!dineOut && (
        <>
          <CustomHeading3>Dine Out</CustomHeading3>
          <HorizontalWrapper>
            <span>Description: </span>
            <StyledDineOutInfo>{dineOut.description}</StyledDineOutInfo>
          </HorizontalWrapper>
          <Content>
            <CustomHeading2>Menus</CustomHeading2>
            <MenuWrapper>
              {dineOut.menus.map((menu) => {
                return (
                  <div key={menu.type}>
                    <StyledHeading3>{menu.type}</StyledHeading3>
                    <HorizontalWrapper>
                      <span>Price: </span>
                      <StyledDineOutInfo>{`$${menu.price}`}</StyledDineOutInfo>
                    </HorizontalWrapper>
                    <MenuWrapper>
                      <span style={{ textAlign: 'center' }}>
                        {menu.menuText}
                      </span>
                    </MenuWrapper>
                  </div>
                );
              })}
            </MenuWrapper>
          </Content>
        </>
      )}
      {showEvents && (
        <>
          <CustomHeading3>Events</CustomHeading3>
          <Content>
            <EventsList events={events} />
          </Content>
        </>
      )}
    </Container>
  );
};

const HorizontalWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const Day = styled.span`
  flex-basis: 65%;
`;

const TimesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 500px;
`;

const Content = styled.div`
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
`;

const StyledHeading3 = styled(CustomHeading3)`
  margin-top: ${spacing.md};
  margin-bottom: ${spacing.md};
  text-transform: capitalize;
`;

const StyledHeading4 = styled(CustomHeading4)`
  margin-top: ${spacing.md};
  margin-bottom: ${spacing.md};
`;

const StyledDineOutInfo = styled.span`
  margin-left: ${spacing.md};
`;

const MenuWrapper = styled.div`
  padding-left: ${spacing.lg};
`;
