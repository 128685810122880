import { useState } from 'react';
import { fetchPub, generateMenu } from '../../../../../../api';
import { HappyHourInfo, Pub, PubEvent } from '../../../../../../types';

export const useFetchPub = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchPlaceData = async (
    pub: Pub,
    text?: string,
    link?: string,
    file?: File | null
  ) => {
    setLoading(true);

    const { name, vicinity, openingHours, googlePlaceId } = pub;

    // Check if already existing
    const pubData = await fetchPub(googlePlaceId);
    let happyHourInfo: HappyHourInfo & {
      events?: PubEvent[];
      dineOut?: Pub['dineOut'];
    };
    setError(''); // i.e. no error

    try {
      if (pubData) {
        const { drinks, food, happyHours, events, dineOut } = pubData;
        happyHourInfo = { drinks, food, happyHours, link: '', events, dineOut };
      } else {
        happyHourInfo = await generateMenu({
          name,
          vicinity,
          openingHours,
          link,
          file,
          text,
        });
      }

      return { ...pub, ...happyHourInfo };
    } catch (err) {
      setError('Error generating menu from URL.');
      alert('Error');
      setLoading(false);
      return pub;
    }
  };

  return {
    loading,
    error,
    setError,
    fetchPlaceData,
  };
};
