import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPub } from '../../../api';
import { useAppDispatch, useAppSelector } from '../../../store';
import { fullGooglePlaceFieldsList } from '../../../store/app-constants';
import { selectActivePub } from '../../../store/app-selectors';
import { setActivePub } from '../../../store/app-slice';
import { transformPlaceToPub } from '../helpers/transform-place-to-pub';
import { useGooglePlaces } from './use-google-places';

export const useActivePub = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const activePub = useAppSelector(selectActivePub);
  const { placesLib, getPlaceDetails } = useGooglePlaces();

  useEffect(() => {
    if (!activePub) {
      const fetchActivePub = async () => {
        setLoading(true);

        if (params.googlePlaceId) {
          const activePubData = await fetchPub(params.googlePlaceId);

          if (activePubData) {
            dispatch(setActivePub(activePubData));
          }
        } else if (params.newGooglePlaceId && placesLib) {
          const placeData = await getPlaceDetails(
            params.newGooglePlaceId,
            fullGooglePlaceFieldsList
          );
          const pubData = transformPlaceToPub(placeData);

          dispatch(setActivePub(pubData));
        }

        setLoading(false);
      };

      fetchActivePub();
    }
  }, [activePub, placesLib]);

  return { loading };
};
