import Event from '@mui/icons-material/Event';
import SportsBar from '@mui/icons-material/SportsBar';
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../../components/loader';
import { spacing } from '../../../components/styled';
import {
  HorizontalWrapper,
  StyledTabButton,
} from '../../../features/shared/components/styled';
import { useHandleRefresh } from '../../../features/shared/hooks/use-handle-refresh';
import { useSendAlert } from '../../../features/shared/hooks/use-send-alert';
import { AppSectionWrapper } from '../../../shared/components/section-wrapper';
import { useAppSelector } from '../../../store';
import { selectAdminAccountsList } from '../../../store/admin-selectors';
import { selectActivePub } from '../../../store/app-selectors';
import { selectLoggedInUser } from '../../../store/user-selectors';
import { businessHappyHoursPath, businessNewEventsPath } from '../../routes';
import { OwnerInput } from '../owner-input';
import { StyledPubInfo } from '../styled';

export const NewBusinessOverview = () => {
  const navigate = useNavigate();
  useHandleRefresh();
  const user = useAppSelector(selectLoggedInUser);
  const activePub = useAppSelector(selectActivePub);
  const isAdmin = user?.role === 'admin';
  // TODO: Needs to be handled differently once businesses can register themselves
  const accounts = useAppSelector(selectAdminAccountsList);
  const account = accounts.find((acc) => acc.id === activePub?.accountId);
  const [option, setOption] = useState<number | null>(null);
  const sendAlert = useSendAlert();

  if (!activePub) {
    return <Loader />;
  }

  const handleHappyHourClick = () => {
    if (activePub.openingHours.length > 0) {
      setOption(0);
      navigate(businessHappyHoursPath);
    } else {
      sendAlert({
        message: 'PUB_OPENING_HOURS_ERROR',
        type: 'error',
      });
    }
  };

  const handleEventClick = () => {
    setOption(1);
    navigate(businessNewEventsPath);
  };

  return (
    <div>
      <AppSectionWrapper>
        <Container data-testid='dashboard-container'>
          <h2>{`${activePub.name}`}</h2>
          <StyledPubInfo>{activePub.vicinity}</StyledPubInfo>
          {isAdmin && (
            <>
              <StyledPubInfo>{`ID: ${activePub.googlePlaceId}`}</StyledPubInfo>
              {account && <OwnerInput pub={activePub} account={account} />}
            </>
          )}

          <Content>
            <HorizontalWrapper>
              <StyledTabButton
                $active={option === 0}
                onClick={handleHappyHourClick}
              >
                <SportsBar />
                <span>Add Happy Hours</span>
              </StyledTabButton>
              <StyledTabButton
                $active={option === 1}
                onClick={handleEventClick}
              >
                <Event />
                <span>Add Events</span>
              </StyledTabButton>
              {/* <StyledTabButton
                $active={option === 2}
                onClick={handleDineOutClick}
              >
                <MenuBook />
                <span>Dine Out Menu</span>
              </StyledTabButton> */}
            </HorizontalWrapper>

            <Outlet />
          </Content>
        </Container>
      </AppSectionWrapper>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  margin: ${spacing.xl} 0;
  padding-left: 50px;
`;

const Content = styled.div`
  margin: ${spacing.xl};
`;
