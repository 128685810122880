import { useState } from 'react';
import { useUpdateUsersState } from '../../features/shared/hooks/use-update-users-state';
import { User } from '../../types';
import { updateUser } from '../users/update-user';

export const useUpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const updateUsersState = useUpdateUsersState();

  const updateUserData = async (
    user: User,
    updateLoggedInUser = false,
    updateActiveUser = false
  ) => {
    setLoading(true);
    try {
      const response = await updateUser(user);

      if (response && (response.status === 200 || response.status === 201)) {
        const userData: User = await response?.json();

        updateUsersState(userData, updateLoggedInUser, updateActiveUser);
        setLoading(false);

        return userData;
      } else {
        setError('Received non-200 response while updating user.');
        setLoading(false);
      }
    } catch (err) {
      setError('Failed to update user.');
      setLoading(false);
    }

    setLoading(false);
  };

  return { loading, error, updateUserData };
};
