import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../../components/styled';
import { ActionButton } from '../../../../../features/shared/components/styled';
import { FormWrapper } from '../../../../../features/shared/components/styled-form';
import { readableDayAndDate } from '../../../../../helpers/date';
import { displayTime } from '../../../../../helpers/time';
import { EventDate, PubEvent } from '../../../../../types';

interface Props {
  events: PubEvent[];
  handleNextClick: () => void;
}

const EventDetails = ({
  name,
  eventDate: { date, times },
}: {
  name: string;
  eventDate: EventDate;
}) => {
  return (
    <HorizontalWrapper key={`${date}-${times?.startTime}`}>
      <StyledDate>{`${readableDayAndDate(date)}:`}</StyledDate>
      <StyledName>{`${name} `}</StyledName>
      {times?.startTime && (
        <StyledTime>{` ${displayTime(times.startTime)}`}</StyledTime>
      )}
    </HorizontalWrapper>
  );
};

export const BulkEventsResult = ({ events, handleNextClick }: Props) => {
  return (
    <StyledFormWrapper>
      <p>{`Success! ${events.length} unique events were processed.`}</p>
      <p>Here's a summary of the events we captured:</p>
      <StyledSummary>
        {events.map(({ name, dates }) => (
          <div key={`${name}-${dates[0].date}`}>
            {dates.map((eventDate) => (
              <EventDetails
                key={`${eventDate.date}-${eventDate.times.startTime}`}
                name={name}
                eventDate={eventDate}
              />
            ))}
          </div>
        ))}
      </StyledSummary>
      <p>
        If any events have been missed, you can add them individually
        afterwards.
      </p>
      <p>You can now add more details for each event.</p>
      <ActionButton onClick={handleNextClick}>Get Started</ActionButton>
      {/* <ActionButton onClick={handleNextClick}>Skip & Publish</ActionButton> */}
    </StyledFormWrapper>
  );
};

const StyledFormWrapper = styled(FormWrapper)`
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledDate = styled.span`
  flex-basis: 25%;
`;
const StyledName = styled.span`
  flex-basis: 40%;
`;
const StyledTime = styled.span`
  flex-basis: 35%;
`;

const StyledSummary = styled.div`
  border-radius: ${spacing.md};
  padding: ${spacing.lg};
  background-color: white;
`;
