import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchUser } from '../../../api';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  selectActiveEvent,
  selectActivePub,
  selectActiveUser,
  selectLoadingData,
} from '../../../store/app-selectors';
import { setActiveEvent, setActiveUser } from '../../../store/app-slice';
import { useActivePub } from './use-active-pub';
import { useLoggedInAccount } from './use-logged-in-account';

/**
 * These handlers for missing data need to run further down the react-router tree so that
 * parms can be picked up. If they are placed where useFetchData is used, the params will
 * be undefined.
 */
export const useHandleRefresh = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const activePub = useAppSelector(selectActivePub);
  const activeEvent = useAppSelector(selectActiveEvent);
  const activeUser = useAppSelector(selectActiveUser);
  const loadingData = useAppSelector(selectLoadingData);
  useActivePub();
  useLoggedInAccount();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      if (!activeEvent && params.eventId) {
        const activeEventData = activePub?.events?.find(
          (event) => event.id === params.eventId
        );
        if (activeEventData) {
          dispatch(setActiveEvent(activeEventData));
        }
      }

      if (!activeUser && params.userId) {
        const activeUser = await fetchUser(params.userId);
        dispatch(setActiveUser(activeUser));
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  return { loading: loadingData || loading };
};
