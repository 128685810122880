import React, { useState } from 'react';
import styled from 'styled-components';
import { spacing } from '../../../components/styled';
import { RemoveButton } from '../../../components/styled/buttons';
import { MenuItem } from '../../../types';

interface Props {
  label: string;
  price: string;
  handleChange: (newValue: MenuItem) => void;
  handleRemove: () => void;
}

export const MenuListItem = ({
  label,
  price,
  handleChange,
  handleRemove,
}: Props) => {
  const [tempItem, setTempItem] = useState(label);
  const [tempPrice, setTempPrice] = useState(price);

  const handleLabelUpdate = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const newData = { label: e.target.value, price };
    handleChange(newData);
  };

  const handlePriceUpdate = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const newData = { label, price: e.target.value };
    handleChange(newData);
  };

  return (
    <HorizontalInputs>
      <StyledItemInput
        value={tempItem}
        onChange={(e) => setTempItem(e.target.value)}
        onBlur={handleLabelUpdate}
      />
      -<span style={{ marginLeft: spacing.lg }}>$</span>
      <StyledPriceInput
        value={tempPrice}
        onChange={(e) => setTempPrice(e.target.value)}
        onBlur={handlePriceUpdate}
      />
      <StyledRemoveButton color='error' onClick={handleRemove}>
        X
      </StyledRemoveButton>
    </HorizontalInputs>
  );
};

const HorizontalInputs = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`;

const StyledRemoveButton = styled(RemoveButton)`
  margin: 0 !important;
  padding-top: 0;
`;

const StyledInput = styled.input`
  border-width: 1px;
  padding: ${spacing.md};
  border-radius: ${spacing.md};
`;

const StyledItemInput = styled(StyledInput)`
  width: 60%;
  margin-right: ${spacing.lg};
`;

const StyledPriceInput = styled(StyledInput)`
  width: 10%;
  margin-left: ${spacing.xs};
`;
