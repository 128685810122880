import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';
import { RemoveButton } from '../../../../components/styled/buttons';
import { StyledLabel } from '../../../../features/shared/components/styled-form';
import { TimeInput } from '../../../../features/shared/components/time-input';
import { checkValidTimes } from '../../../../helpers/time';
import { EventTimes } from '../../../../types';
import { HorizontalInputs } from '../../happy-hours/menu-list';

interface Props {
  times?: EventTimes;
  onStartChange: (newTime: string) => void;
  onEndChange: (newTime: string | null) => void;
  listView?: boolean;
}

export const EventTimePickers = ({
  times,
  onStartChange,
  onEndChange,
  listView = false,
}: Props) => {
  const showTimeWarning = useMemo(
    () => (times ? !checkValidTimes(times) : false),
    [times]
  );

  return (
    <div>
      <FormControl style={{ width: '100%' }}>
        {!listView && <StyledLabel>Times</StyledLabel>}
        <HorizontalInputs
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <TimeInput
            value={times?.startTime ?? ''}
            startTime={true}
            onTimeChange={(newStartTime) => {
              if (newStartTime) {
                onStartChange(newStartTime);
              }
            }}
          />
          <Wrapper>
            <StyledDivider>-</StyledDivider>
            <TimeInput
              value={times?.endTime ?? null}
              startTime={false}
              onTimeChange={(newStartTime) => {
                if (newStartTime) {
                  onEndChange(newStartTime);
                }
              }}
              disabled={!times?.startTime}
            />
            {times?.endTime && (
              <Tooltip title='Remove End Time'>
                <StyledRemoveButton
                  color='error'
                  onClick={() => onEndChange(null)}
                >
                  X
                </StyledRemoveButton>
              </Tooltip>
            )}
          </Wrapper>
        </HorizontalInputs>
        {!times?.startTime && (
          <span style={{ color: 'red' }}>You must set a start time.</span>
        )}
        {showTimeWarning && (
          <span style={{ color: 'red' }}>End time is before start time.</span>
        )}
      </FormControl>
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDivider = styled.span`
  margin-left: ${spacing.md};
  margin-right: ${spacing.md};
`;

const StyledRemoveButton = styled(RemoveButton)`
  margin: 0 !important;
  padding-top: 0;
`;
