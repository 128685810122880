import React, { forwardRef, PropsWithChildren, Ref } from 'react';
import styled from 'styled-components';
import { spacing } from '../../../app/components/styled';
import { zIndexValue } from '../../features/shared/components/styled';

interface Props {
  backgroundColor?: string;
  transitionBackground?: boolean;
  mobileColumns?: boolean;
  maxWidth?: string;
  isSticky?: boolean;
  stickyTop?: number;
  sectionStyle?: React.CSSProperties;
}

export const AppSectionWrapper = forwardRef(
  (
    {
      backgroundColor,
      transitionBackground = false,
      mobileColumns = false,
      maxWidth,
      isSticky = false,
      stickyTop = 0,
      children,
      sectionStyle,
    }: PropsWithChildren<Props>,
    ref: Ref<HTMLDivElement | null>
  ) => {
    return (
      <FullWidthContainer
        $backgroundColor={backgroundColor}
        $isSticky={isSticky}
        $stickyTop={stickyTop}
        $transitionBackground={transitionBackground}
      >
        <StyledSection
          ref={ref as Ref<HTMLDivElement>}
          $mobileColumns={mobileColumns}
          $maxWidth={maxWidth}
          style={sectionStyle}
        >
          {children}
        </StyledSection>
      </FullWidthContainer>
    );
  }
);

const FullWidthContainer = styled.section<{
  $backgroundColor?: string;
  $isSticky: boolean;
  $stickyTop: number;
  $transitionBackground: boolean;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  ${({ $transitionBackground }) =>
    $transitionBackground && `transition: background-color 1000ms linear`};
  width: 100%;
  ${({ $isSticky, $stickyTop }) =>
    $isSticky
      ? `
      position: sticky; 
      top: ${$stickyTop}px;
      z-index: ${zIndexValue.stickyHeader};
    `
      : ''}
`;

const StyledSection = styled.div<{
  $mobileColumns: boolean;
  $maxWidth?: string;
}>`
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth}`};
  margin: ${spacing.lg} ${spacing.xl};
  display: flex;
  flex-direction: row;
`;
