import TextareaAutosize from '@mui/material/TextareaAutosize';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { processEventsText } from '../../../../../api/events/process-events-text';
import { Loader } from '../../../../../components/loader';
import { spacing } from '../../../../../components/styled';
import { ActionButton } from '../../../../../features/shared/components/styled';
import {
  FormWrapper,
  StyledForm,
} from '../../../../../features/shared/components/styled-form';
import { useSendAlert } from '../../../../../features/shared/hooks/use-send-alert';
import { useAppSelector } from '../../../../../store';
import { selectActivePub } from '../../../../../store/app-selectors';
import { PubEvent } from '../../../../../types';
import { businessEventsRoute } from '../../../../routes';

interface Props {
  setEvents: (events: PubEvent[]) => void;
}

export const BulkEventsForm = ({ setEvents }: Props) => {
  const navigate = useNavigate();
  const activePub = useAppSelector(selectActivePub);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Processing...');
  const sendAlert = useSendAlert();

  if (!activePub) {
    return <Loader />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(() => setLoadingText('Analysing Text...'), 10 * 1000);
    setTimeout(() => setLoadingText('Processing Events...'), 30 * 1000);
    setTimeout(() => setLoadingText('Generating Calendar...'), 60 * 1000);

    try {
      const newEvents = await processEventsText(activePub.googlePlaceId, text);
      setEvents(newEvents);
    } catch (_error) {
      sendAlert({ message: 'PROCESSING_ERROR', type: 'error' });
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader text={loadingText} />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledFormWrapper>
        <StyledForm onSubmit={handleSubmit}>
          <p>Paste in the information for your events.</p>
          <p>Include as much information as possible.</p>
          <StyledTextareaAutosize
            value={text}
            placeholder={`TUES 4TH
Set Dancing 7PM
Join us for a fun and energetic night of dancing!
$5
Book now at www.booking.ca/dancing

WED 5TH
Paint Night 7PM
Unleash your creativity at our Paint Night!
$10
More info at www.information.ca/paint

FRI 7TH
Music 8:30PM
Enjoy an unforgettable night of live music!
$10
Book now at www.booking.ca/music`}
            onChange={(e) => setText(e.target.value)}
            minRows={4}
          />
          <StyledActionButton
            type='button'
            cancel={'true'}
            onClick={() =>
              navigate(businessEventsRoute(activePub.googlePlaceId))
            }
          >
            Cancel
          </StyledActionButton>
          <StyledActionButton type='submit' disabled={text === ''}>
            Next
          </StyledActionButton>
        </StyledForm>
      </StyledFormWrapper>
    </LocalizationProvider>
  );
};

const StyledFormWrapper = styled(FormWrapper)`
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
`;

const StyledActionButton = styled(ActionButton)`
  && {
    margin-left: ${spacing.md};
    margin-right: ${spacing.md};
  }
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  resize: vertical;
  width: 100%;
  min-height: 40px;
  margin-bottom: 10px;
  padding: ${spacing.sm};
`;
