import React from 'react';

import styled from 'styled-components';
import { colors } from '../../../../components/styled';

export interface IconButtonProps {
  /** One of the icons specified above as a JSX Element */
  children: JSX.Element;
  /** Callback when icon is clicked */
  onClick: () => void;
  /** Callback when icon mouse is over icon */
  onMouseOver?: () => void;
  /** Color of the icon. See Colors for more information */
  color?: string;
  /** Size of the icon */
  size?: string;
  /** Provides a data-testid */
  'data-testid'?: string;
  /** Provides accesibility label */
  'aria-label'?: string;
}

/**
 * Button that takes in Icon component and adds styling to it
 *
 * @param {IconButtonProps} param
 *
 * @returns JSX.ELEMENT
 */
export const IconButton = ({
  children,
  onClick,
  onMouseOver,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  size = '16px',
  color = colors.paleGrey,
}: IconButtonProps) => {
  return (
    <StyledButton
      type='button'
      onClick={onClick}
      onMouseOver={onMouseOver}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      $color={color}
    >
      <StyledIconWrapper
        style={{
          width: size,
          height: size,
          lineHeight: size,
        }}
      >
        {children}
      </StyledIconWrapper>
    </StyledButton>
  );
};

const StyledButton = styled.button<{ $color: string }>`
  && {
    display: inline-block;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    fill: ${({ $color }) => $color};

    &:active,
    &:focus {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
`;

const StyledIconWrapper = styled.div`
  overflow: hidden;

  svg {
    width: 18px;
    height: 18px;
  }
`;
