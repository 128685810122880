import React from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from 'styled-components';
import { spacing } from '../../../components/styled';
import { UserRole } from '../../../types';
import { HorizontalWrapper } from './styled';

interface Props {
  role: UserRole;
  setRole: (newRole: UserRole) => void;
  isAdmin: boolean;
}

export const RoleInput = ({ role, setRole, isAdmin }: Props) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setRole(event.target.value as UserRole);
  };

  return (
    <Container>
      <FormControl>
        <HorizontalWrapper style={{ alignItems: 'baseline' }}>
          <StyledLabel htmlFor='role-input'>Role:</StyledLabel>
          <StyledSelect
            id='role-input'
            value={role}
            onChange={handleChange}
            size='small'
          >
            {isAdmin && <StyledMenuItem value={'admin'}>Admin</StyledMenuItem>}
            <StyledMenuItem value={'account-admin'}>
              Account Admin
            </StyledMenuItem>
            <StyledMenuItem value={'owner'}>Owner</StyledMenuItem>
            {/* <MenuItem value={'Client'} /> */}
          </StyledSelect>
        </HorizontalWrapper>
      </FormControl>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${spacing.lg};
`;

const StyledSelect = styled(Select)`
  min-width: 150px;
  margin-left: ${spacing.md};
`;

const StyledMenuItem = styled(MenuItem)`
  min-width: 150px;
`;

const StyledLabel = styled.label`
  margin-right: ${spacing.xl};
`;
