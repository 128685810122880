import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';
import { HorizontalWrapper } from '../../../../features/shared/components/styled';
import { CuisineType, cuisineTypes } from '../../../../types';

interface Props {
  cuisineType: CuisineType;
  setCuisineType: (newType: CuisineType) => void;
}

export const CusineTypeSelect = ({ cuisineType, setCuisineType }: Props) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setCuisineType(event.target.value as CuisineType);
  };

  return (
    <Container>
      <FormControl>
        <HorizontalWrapper style={{ alignItems: 'baseline' }}>
          <label htmlFor='cusine-type-input'>Cusine Type:</label>
          <StyledSelect
            id='cusine-type-input'
            value={cuisineType}
            onChange={handleChange}
            size='small'
          >
            {cuisineTypes.sort().map((cusine) => {
              return (
                <StyledMenuItem key={cusine} value={cusine}>
                  {cusine}
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
        </HorizontalWrapper>
      </FormControl>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${spacing.lg};
`;

const StyledSelect = styled(Select)`
  min-width: 150px;
  margin-left: ${spacing.md};
  background-color: white;
`;

const StyledMenuItem = styled(MenuItem)`
  min-width: 150px;
`;
