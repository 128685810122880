import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { notifyAll } from '../../../../api/notifications/notify-all';
import { colors, spacing } from '../../../../components/styled';
import {
  FormWrapper,
  StyledForm,
  StyledInput,
  StyledLabel,
} from '../../../../features/shared/components/styled-form';

export const NotificationsTab = () => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [pubId, setPubId] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const sendConfirmed = confirm(
      `Are you sure you wish to send this notification to all users: \n\nTitle: ${title}\nBody: ${body}\nBusiness ID: ${pubId}`
    );

    if (sendConfirmed) {
      const response = await notifyAll({
        title,
        body,
        data: { googlePlaceId: pubId },
      });

      if (response?.status === 200) {
        setShowSuccess(true);
        setTitle('');
        setBody('');
        setPubId('');
      }
    }
  };

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => setShowSuccess(false), 3000);
    }
  }, [showSuccess]);

  return (
    <Container>
      <img
        style={{ width: '80%' }}
        src='https://i.ibb.co/y4dVWL9/IMG-CDAE7-D29-B49-D-1.jpg'
      />
      <FormWrapper>
        <StyledForm onSubmit={handleSubmit}>
          <StyledLabel htmlFor='title-input'>Title*</StyledLabel>
          <StyledInput
            id='title-input'
            placeholder='Notification Title*'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <StyledLabel htmlFor='body-input'>Body*</StyledLabel>
          <StyledInput
            id='body-input'
            placeholder='Notification body*'
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required
          />
          <StyledLabel htmlFor='id-input'>{`Business ID (optional)`}</StyledLabel>
          <StyledMessage>
            This will open the app with a business' overview open.
          </StyledMessage>
          <StyledInput
            id='id-input'
            value={pubId}
            placeholder={`ID from the businesses overview`}
            onChange={(e) => setPubId(e.target.value)}
          />

          <Button type='submit' disabled={title === '' || body === ''}>
            Send
          </Button>
          {showSuccess && <p>Successfully sent notification</p>}
        </StyledForm>
      </FormWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  color: ${colors.blue};
  text-align: center;
  max-width: 600px;
`;

const StyledMessage = styled.p`
  margin-top: ${spacing.sm};
  margin-bottom: ${spacing.md};
  font-size: 14px;
`;
