import { styled } from '@mui/material/styles';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import styledComponent from 'styled-components';
import { colors, spacing } from '../../../../components/styled';
import { StyledLabel } from '../../../../features/shared/components/styled-form';
import { pastDate } from '../../../../helpers/date';
import { EventDate } from '../../../../types';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  '&:focus': {
    backgroundColor: theme.palette.common.white,
  },

  ...(selected && {
    backgroundColor: colors.blue,
    color: theme.palette.common.white,
    '&:focus': {
      backgroundColor: colors.blue,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

interface Props {
  eventDates: EventDate[];
  setEventDates: (dates: EventDate[]) => void;
}

export const MultiDatePicker = ({ eventDates, setEventDates }: Props) => {
  const [noDateWarning, setNoDateWarning] = useState(eventDates.length === 0);
  const datesWithoutDefault = eventDates.filter((d) => d.date !== '');
  const futureDates = eventDates.filter((d) => !pastDate(d));
  const pastDatesWarning = futureDates.length === 0;
  const [showDatesMessage, setShowDatesMessage] = useState(false);
  const showWarning = noDateWarning || pastDatesWarning;
  const warningText =
    noDateWarning || datesWithoutDefault.length === 0
      ? 'At least one date must be selected.'
      : 'All selected dates are in the past.';

  const EventPickerDay = (
    props: PickersDayProps<dayjs.Dayjs> & { eventDates?: EventDate[] }
  ) => {
    const { eventDates = [], day, outsideCurrentMonth, ...other } = props;

    const selected = eventDates.some((eventDate) => {
      return day.isSame(dayjs(eventDate.date), 'day');
    });

    /**
     * This had to be added on the picker as the onChange handler on StaticDatePicker was not being
     * called if the clicked date was currently focused.
     */
    const handleClick = () => {
      const existingIndex = eventDates.findIndex((eventDate) =>
        dayjs(eventDate.date).isSame(day, 'day')
      );

      if (existingIndex > -1) {
        const newValues = eventDates.filter(
          (_, index) => index !== existingIndex
        );
        if (newValues.length === 0) {
          setEventDates(newValues);
          setNoDateWarning(true);
        } else {
          setEventDates(newValues);
          setNoDateWarning(false);
        }
      } else if (day) {
        if (futureDates.length !== datesWithoutDefault.length) {
          setShowDatesMessage(true);

          setTimeout(() => setShowDatesMessage(false), 3000);
        }
        setNoDateWarning(false);

        const newEventDate = {
          date: day.format('YYYY-MM-DD'),
          times: eventDates[0]?.times,
        };
        setEventDates([...eventDates, newEventDate]);
      }
    };

    return (
      <CustomPickersDay
        {...other}
        onClick={handleClick}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        disableMargin
        selected={!!selected}
      />
    );
  };

  return (
    <>
      <StyledLabel>{`Dates (${datesWithoutDefault.length} selected)*`}</StyledLabel>
      <StyledDatePicker
        displayStaticWrapperAs='desktop'
        value={dayjs(eventDates[0]?.date ?? new Date())}
        onChange={() => {}} // Avoids React warning about controlled components
        slots={{ day: EventPickerDay }}
        slotProps={{ day: { eventDates: eventDates ?? [] } as any }}
        disablePast
      />
      {showWarning ? (
        <span style={{ color: 'red' }}>{warningText}</span>
      ) : (
        showDatesMessage && (
          <span style={{ color: 'green' }}>Past dates have been removed.</span>
        )
      )}
    </>
  );
};

const StyledDatePicker = styledComponent(StaticDatePicker)`
  && {
    margin-top: ${spacing.md};
    margin-bottom: ${spacing.md};
  }
`;
