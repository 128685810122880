import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { adminReducer, AdminSliceState } from './admin-slice';
import { appReducer, AppSliceState } from './app-slice';
import { userReducer, UserSliceState } from './user-slice';

export interface RootAppState {
  app: AppSliceState;
  admin: AdminSliceState;
  user: UserSliceState;
}

const rootReducer = combineReducers({
  app: appReducer,
  admin: adminReducer,
  user: userReducer,
});

export const createStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
  });
};

const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
