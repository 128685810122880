import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Loader } from '../../../../components/loader';
import {
  FormWrapper,
  StyledForm,
} from '../../../../features/shared/components/styled-form';
import { pastDate } from '../../../../helpers/date';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  selectActiveEvent,
  selectActivePub,
} from '../../../../store/app-selectors';
import { setActiveEvent } from '../../../../store/app-slice';
import { PubEvent, PubEventValue } from '../../../../types';
import { EventBookingInputs } from './event-booking-inputs';
import { EventDetailsInputs } from './event-details-inputs';
import { EventFormButtons } from './event-form-buttons';
import { EventTimingInputs } from './event-timing-inputs';

interface Props {
  onSubmit: (
    event: PubEvent,
    imgFile: File | undefined | null
  ) => Promise<void>;
  actionButtonText?: string;
  cancelButtonText?: string;
  onCancel: (event: PubEvent, imgFile: File | undefined | null) => void;
  onDelete: (event: PubEvent) => void | Promise<void>;
  index?: number;
  listLength?: number;
}

export const EventForm = ({
  onSubmit,
  actionButtonText,
  cancelButtonText,
  onCancel,
  onDelete,
  index,
  listLength,
}: Props) => {
  const activePub = useAppSelector(selectActivePub);
  const activeEvent = useAppSelector(selectActiveEvent);
  const dispatch = useAppDispatch();
  const [imgFile, setImgFile] = useState<File | undefined | null>(null);
  const [error, setError] = useState('');
  const headingRef = useRef<HTMLHeadingElement>(null);
  const [unpublishedChanges, setUnpublishedChanges] = useState(
    !!listLength ?? false
  );

  if (!activePub || !activeEvent) {
    return <Loader />;
  }

  const { name, type, dates } = activeEvent;
  const futureDates = dates.filter((d) => !pastDate(d));
  const publishDisabled =
    dates.length === 0 ||
    name === '' ||
    type === null ||
    futureDates.length === 0 ||
    futureDates.some((date) => !date.times?.startTime);

  useEffect(() => {
    if (dates.length === 0) {
      setError('Missing dates.');
    } else if (name === '') {
      setError('Missing name.');
    } else if (type === null) {
      setError('Category not set.');
    } else if (dates.filter((d) => d.date !== '').length === 0) {
      setError('No date selected');
    } else if (futureDates.length === 0) {
      setError('Selected dates are in the past.');
    } else if (futureDates.some((date) => !date.times?.startTime)) {
      setError('Missing start time.');
    } else {
      setError('');
    }
  }, [activeEvent]);

  const handleChange = (param: keyof PubEvent, value: PubEventValue) => {
    setUnpublishedChanges(true);
    dispatch(
      setActiveEvent({
        ...activeEvent,
        pubId: activePub.googlePlaceId,
        [param]: value,
      })
    );
  };

  const handleCancel = async () => {
    onCancel(activeEvent, imgFile);
    setImgFile(null);
    // If not bulk changes
    if (!listLength) {
      setUnpublishedChanges(false);
    }
  };

  const handleDelete = async (event: PubEvent) => {
    headingRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    // Timeout needed or the scroll doesn't have time to complete.
    setTimeout(async () => {
      await onDelete(event);
      setImgFile(null);
      if (!listLength) {
        setUnpublishedChanges(false);
      }
    }, 300);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    headingRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    // Timeout needed or the scroll doesn't have time to complete.
    setTimeout(async () => {
      await onSubmit(activeEvent, imgFile);
      setImgFile(null);
      if (!listLength) {
        setUnpublishedChanges(false);
      }
    }, 100);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledFormWrapper>
        {index !== undefined && listLength !== undefined && (
          <span style={{ fontStyle: 'italic' }}>{`${
            index + 1
          } of ${listLength}`}</span>
        )}
        <Form onSubmit={handleSubmit}>
          <EventFormButtons
            event={activeEvent}
            publishDisabled={publishDisabled}
            handleCancel={handleCancel}
            actionButtonText={actionButtonText}
            cancelButtonText={cancelButtonText}
            onDelete={handleDelete}
            error={error}
            unpublishedChanges={unpublishedChanges}
          />
          <EventDetailsInputs
            event={activeEvent}
            handleChange={handleChange}
            imgFile={imgFile}
            setImgFile={setImgFile}
            headingRef={headingRef}
            setUnpublishedChanges={setUnpublishedChanges}
          />
          <HorizontalWrapper>
            <EventTimingInputs
              event={activeEvent}
              pub={activePub}
              futureDates={futureDates}
              setUnpublishedChanges={setUnpublishedChanges}
            />
            <EventBookingInputs
              event={activeEvent}
              handleChange={handleChange}
            />
          </HorizontalWrapper>
          <EventFormButtons
            event={activeEvent}
            publishDisabled={publishDisabled}
            handleCancel={handleCancel}
            actionButtonText={actionButtonText}
            cancelButtonText={cancelButtonText}
            onDelete={handleDelete}
            error={error}
            unpublishedChanges={unpublishedChanges}
          />
        </Form>
      </StyledFormWrapper>
    </LocalizationProvider>
  );
};

const StyledFormWrapper = styled(FormWrapper)`
  margin-top: 0;
  text-align: center;
  padding-top: 0;
`;

const Form = styled(StyledForm)`
  max-width: 100%;
  padding-top: 0;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
