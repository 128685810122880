import { baseUrl } from '../base-url';

interface NotifyAllPayload {
  title: string;
  body: string;
  data: Record<string, string>;
}

export const notifyAll = async ({ title, body, data }: NotifyAllPayload) => {
  try {
    const token = localStorage.getItem('token');

    return await fetch(`${baseUrl}/api/app-users/notify-all`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ title, body, data: JSON.stringify(data) }),
    });
  } catch (error) {
    console.log('Failed to notify users.', error);
  }
};
