import Button from '@mui/material/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUpdatePub } from '../../../api/hooks/use-update-pub';
import { Loader } from '../../../components/loader';
import { Summary } from '../../../features/summary/components/summary';
import { useAppSelector } from '../../../store';
import { selectActivePub } from '../../../store/app-selectors';

export const SummaryPage = () => {
  const navigate = useNavigate();
  const activePub = useAppSelector(selectActivePub);
  const { updatePub } = useUpdatePub();

  if (!activePub) {
    return <Loader />;
  }

  const handleSubmit = async () => {
    await updatePub(activePub);
    navigate('../success');
  };

  return (
    <Container>
      <Summary />
      <HorizontalWrapper>
        <Button onClick={() => navigate('../update-happy-hours')}>Back</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </HorizontalWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const HorizontalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
