import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../../components/loader';
import { spacing } from '../../../components/styled';
import { HeadingTabs } from '../../../features/shared/components/heading-tabs';
import { useHandleRefresh } from '../../../features/shared/hooks/use-handle-refresh';
import { AppSectionWrapper } from '../../../shared/components/section-wrapper';

const tabHeadings = [
  { heading: 'Businesses' },
  { heading: 'Accounts' },
  { heading: 'Users' },
  { heading: 'New Business' },
  { heading: 'New Account' },
  { heading: 'Notifications' },
  { heading: 'Events' },
  // { heading: 'Dine Out' },
  // { heading: 'Dine Out Data Fetch' },
];

export const AdminDashboard = () => {
  const { loading } = useHandleRefresh();

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <AppSectionWrapper>
        <Container>
          <h2>Admin Dashboard</h2>
          <HeadingTabs headings={tabHeadings} />
          <TableContainer>
            <Outlet />
          </TableContainer>
        </Container>
      </AppSectionWrapper>
    </div>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const TableContainer = styled.div`
  margin: ${spacing.lg} 0;
`;
