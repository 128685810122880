export const AlertMessages = {
  PUB_SEARCH_ERROR:
    'Unable to use business as it is missing required information.',
  PUB_OPENING_HOURS_ERROR:
    'Unable to add happy hours as this business is missing opening hours information.',
  PUBLISH_SUCCESS:
    'Successfully published. Reload the app to see your changes.',
  PUBLISH_ERROR:
    'There was an error during publishing. Please check the entered information and try again.',
  DELETE_SUCCESS: 'Successfully deleted.',
  DELETE_ERROR: 'There was an error while deleting. Please try again.',
  PROCESSING_ERROR: 'There was an error processing the data. Please try again.',
} as const;
