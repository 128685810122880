import React, { useState } from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';
import { RemoveButton } from '../../../../components/styled/buttons';
import { DineOutItem } from '../../../../types';

interface Props {
  label: string;
  subLabel: string;
  handleChange: (newValue: DineOutItem) => void;
  handleRemove: () => void;
}

export const DineOutListItem = ({
  label,
  subLabel,
  handleChange,
  handleRemove,
}: Props) => {
  const [tempLabel, setTempLabel] = useState(label);
  const [tempSubLabel, setTempSubLabel] = useState(subLabel);

  const handleLabelUpdate = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const newData = { label: e.target.value, subLabel };
    handleChange(newData);
  };

  const handleSubLabelUpdate = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const newData = { label, subLabel: e.target.value };
    handleChange(newData);
  };

  return (
    <HorizontalInputs>
      <StyledItemInput
        value={tempLabel}
        onChange={(e) => setTempLabel(e.target.value)}
        onBlur={handleLabelUpdate}
        placeholder='Menu item'
      />
      <StyledSubLabelInput
        value={tempSubLabel}
        onChange={(e) => setTempSubLabel(e.target.value)}
        onBlur={handleSubLabelUpdate}
        placeholder='Item description'
      />
      <StyledRemoveButton color='error' onClick={handleRemove}>
        X
      </StyledRemoveButton>
    </HorizontalInputs>
  );
};

const HorizontalInputs = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const StyledRemoveButton = styled(RemoveButton)`
  margin: 0 !important;
  padding-top: 0;
`;

const StyledInput = styled.input`
  border-width: 1px;
  padding: ${spacing.md};
  border-radius: ${spacing.md};
`;

const StyledItemInput = styled(StyledInput)`
  width: 40%;
  margin-right: ${spacing.lg};
`;

const StyledSubLabelInput = styled(StyledInput)`
  width: 60%;
  margin-left: ${spacing.lg};
`;
