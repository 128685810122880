import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../components/styled';
import { HorizontalInputs } from '../../../routes/business/happy-hours/menu-list';

interface Props {
  property: string;
  value: string;
  link?: boolean;
}

export const DetailsRow = ({ property, value, link }: Props) => {
  return (
    <StyledRow>
      <DetailsProp>{property}:</DetailsProp>
      {link ? <a href={value}>{value}</a> : <span>{value}</span>}
    </StyledRow>
  );
};

const StyledRow = styled(HorizontalInputs)`
  margin-top: ${spacing.md};
  margin-bottom: ${spacing.md};
`;

const DetailsProp = styled.span`
  flex-basis: 10%;
  margin-right: ${spacing.lg};
`;
