import { HappyHoursByDay, Pub } from '../../../types';

export const transformPlaceToPub = (
  details: google.maps.places.PlaceResult
): Pub => {
  const {
    name,
    vicinity,
    website,
    opening_hours: openingHours,
    place_id: googlePlaceId,
    rating,
    user_ratings_total,
    geometry,
  } = details;

  if (!name || !vicinity || !googlePlaceId || !rating || !geometry?.location) {
    throw new Error(
      `Missing Google Place data for placeId. Data: ${JSON.stringify(
        details,
        null,
        2
      )}`
    );
  }

  return {
    googlePlaceId,
    name,
    website: website ?? '',
    vicinity,
    openingHours: openingHours?.periods ?? [],
    rating,
    userRatingsTotal: user_ratings_total,
    latitude: geometry.location.lat(),
    longitude: geometry.location.lng(),
    happyHours: {} as HappyHoursByDay,
    drinks: [],
    food: [],
    events: [],
  };
};
