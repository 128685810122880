import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../components/styled';
import { ActionButton } from '../../../features/shared/components/styled';
import { CopyPasteOption } from '../new/happy-hours/copy-paste-option';

interface Props {
  text: string;
  setText: (text: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

export const AddToMenuOption = ({
  text,
  setText,
  onSubmit,
  onCancel,
}: Props) => {
  const handleCancel = () => {
    setText('');
    onCancel();
  };

  return (
    <>
      <CopyPasteOption text={text} setText={setText} addView={true} />
      <ButtonWrapper>
        <StyledActionButton cancel={'true'} onClick={handleCancel}>
          Cancel
        </StyledActionButton>
        <ActionButton onClick={onSubmit}>Update</ActionButton>
      </ButtonWrapper>
    </>
  );
};

const ButtonWrapper = styled.div`
  margin-bottom: ${spacing.lg};
`;

const StyledActionButton = styled(ActionButton)`
  && {
    margin-right: ${spacing.lg};
  }
`;
