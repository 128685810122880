import React from 'react';

import { useNavigate } from 'react-router-dom';
import {
  CustomRowExtraP,
  CustomRowH3,
  CustomRowP,
  CustomRowTextContainer,
  CustomRowTextWrapper,
} from '../../../routes/admin/dashboard/styled';
import { useAppDispatch } from '../../../store';
import { setActivePub, setExistingPub } from '../../../store/app-slice';
import { Account, Pub } from '../../../types';
import { CustomTableRow } from './custom-table-row';

interface Props {
  pub: Pub;
  isAdmin: boolean;
  accounts: Account[];
  onDelete: (pub: Pub) => void;
  readOnly?: boolean;
}

export const PubRow = ({
  pub,
  isAdmin,
  accounts,
  onDelete,
  readOnly = false,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accountDetails = accounts.find((acc) => acc.id === pub.accountId);

  const handleRowClick = () => {
    if (!readOnly) {
      dispatch(setActivePub(pub));
      dispatch(setExistingPub(true));
      navigate(pub.googlePlaceId);
    }
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    {
      e.stopPropagation();
      onDelete(pub);
    }
  };

  const editOption = readOnly
    ? []
    : [{ text: 'Edit', handler: handleRowClick }];
  const deleteOption =
    isAdmin && !readOnly ? [{ text: 'Delete', handler: handleDelete }] : [];
  const dropdownOptions = [...editOption, ...deleteOption];

  return (
    <CustomTableRow
      key={pub.googlePlaceId}
      onClick={handleRowClick}
      readOnly={readOnly}
      dropdownOptions={dropdownOptions}
    >
      <CustomRowTextContainer>
        <CustomRowTextWrapper>
          <CustomRowH3>{pub.name}</CustomRowH3>
          <CustomRowP>{pub.vicinity}</CustomRowP>
        </CustomRowTextWrapper>

        {isAdmin && (
          <CustomRowTextWrapper>
            <CustomRowExtraP>{pub.googlePlaceId}</CustomRowExtraP>
            {!readOnly && (
              <CustomRowExtraP>{accountDetails?.name ?? ''}</CustomRowExtraP>
            )}
          </CustomRowTextWrapper>
        )}
      </CustomRowTextContainer>
    </CustomTableRow>
  );
};
