import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../components/styled';
import { PubEvent } from '../../../types';

interface Props {
  event: PubEvent;
}

export const EventListItem = ({ event }: Props) => {
  return (
    <HorizontalInputs>
      <StyledData>{event.name}</StyledData>
      <StyledData>{event.dates[0].date}</StyledData>
    </HorizontalInputs>
  );
};

const HorizontalInputs = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const StyledData = styled.span`
  margin-right: ${spacing.md};
`;
