import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Turnstile from 'react-turnstile';
import styled from 'styled-components';
import { turnstileSiteKey } from '../../../shared-constants';
import { SectionWrapper } from '../../../website/shared/components/section-wrapper';
import { useAcceptInvite } from '../../api/hooks/use-accept-invite';
import { Loader } from '../../components/loader';
import { colors, spacing } from '../../components/styled';
import { ActionButton } from '../../features/shared/components/styled';
import { useHandleRefresh } from '../../features/shared/hooks/use-handle-refresh';
import { useAppSelector } from '../../store';
import { selectLoggedInUser } from '../../store/user-selectors';
import { dashboardRoute, loginRoute } from '../routes';

export const AccountInvite = () => {
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [passwordsError, setPasswordError] = useState(false);
  const { error, loading: submitting, acceptInvite } = useAcceptInvite();
  const [turnstileToken, setTurnstileToken] = useState('');
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const navigate = useNavigate();
  const { loading } = useHandleRefresh();
  const [searchParams, _setSearchParams] = useSearchParams();
  const accountId = searchParams.get('account_id');
  const accountName = searchParams.get('account_name');

  useEffect(() => {
    // Redirect to the dashboard if logged in.
    if (loggedInUser) {
      navigate(dashboardRoute);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (passwordsError && password === passwordConfirm) {
      setPasswordError(false);
    }
  }, [password, passwordConfirm, passwordsError]);

  if (loading || submitting) {
    return <Loader />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== passwordConfirm) {
      setPasswordError(true);
    } else if (accountId) {
      await acceptInvite(
        accountId,
        { email, password, firstName, lastName },
        turnstileToken
      );
    } else {
      alert('No account information found. Please reach out for support.');
    }
  };

  return (
    <SectionWrapper>
      <Container>
        <p>{`Create a profile to join the ${accountName} account`}</p>
        <FormWrapper>
          <StyledForm onSubmit={handleSubmit}>
            <StyledLabel htmlFor='email'>Email</StyledLabel>
            <StyleInput
              id='email'
              style={{ width: '100%' }}
              placeholder='Email'
              onChange={(e) => setEmail(e.target.value.trim())}
              required
              value={email}
              type='email'
            />
            <StyledLabel htmlFor='firstName'>First Name</StyledLabel>
            <StyleInput
              id='firstName'
              style={{ width: '100%' }}
              placeholder='First Name'
              value={firstName}
              required
              onChange={(e) => setFirstName(e.target.value)}
            />
            <StyledLabel htmlFor='lastName'>Last Name</StyledLabel>
            <StyleInput
              id='lastName'
              style={{ width: '100%' }}
              placeholder='Last Name'
              value={lastName}
              required
              onChange={(e) => setLastName(e.target.value)}
            />
            <StyledLabel htmlFor='password'>Password</StyledLabel>
            <StyleInput
              id='password'
              style={{ width: '100%' }}
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              type='password'
            />
            <StyledLabel htmlFor='confirm-password'>
              Confirm Password
            </StyledLabel>
            <StyleInput
              id='confirm-password'
              style={{ width: '100%' }}
              placeholder='Confirm Password'
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              required
              type='password'
            />
            {passwordsError && (
              <span style={{ color: 'red' }}>
                The entered passwords do not match.
              </span>
            )}
            {!!turnstileToken ? (
              <ActionButton
                type='submit'
                disabled={
                  email === '' ||
                  password === '' ||
                  passwordConfirm === '' ||
                  password !== passwordConfirm ||
                  firstName === '' ||
                  lastName === ''
                }
              >
                Register
              </ActionButton>
            ) : (
              <TurnstileWrapper>
                <Turnstile
                  sitekey={turnstileSiteKey}
                  onVerify={(token) => setTurnstileToken(token)}
                />
              </TurnstileWrapper>
            )}
          </StyledForm>
          {error && (
            <p style={{ color: 'red', marginTop: spacing.md, marginBottom: 0 }}>
              {error}
            </p>
          )}
        </FormWrapper>
        <LinkWrapper>
          <Link to={loginRoute}>Already Have an account? Login.</Link>
        </LinkWrapper>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  text-align: center;
  max-width: 600px;
`;

const StyledLabel = styled.label``;

const StyleInput = styled.input`
  width: 100%;
  margin-bottom: ${spacing.lg};
  margin-top: ${spacing.sm};
  padding: ${spacing.md};
  border: none;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  color: ${colors.slate};
`;

const FormWrapper = styled.div`
  background-color: ${colors.paleGrey};
  padding: ${spacing.lg};
  padding-top: ${spacing.md};
  padding-bottom: ${spacing.lg};
  border-radius: 10px;
  margin-top: ${spacing.xl};
  margin-bottom: ${spacing.lg};
`;

const StyledForm = styled.form`
  width: 100%;
  color: ${colors.slate};
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const LinkWrapper = styled.div`
  margin-bottom: ${spacing.lg};
`;

const TurnstileWrapper = styled.div`
  margin-top: ${spacing.lg};
`;
