import React from 'react';
import styled from 'styled-components';
import {
  StyledInput,
  StyledLabel,
} from '../../../../features/shared/components/styled-form';
import { DineOutMenu, DineOutMenuType } from '../../../../types';

const emptyValue = { label: '', subLabel: '', price: '' };

interface Props {
  menus: DineOutMenu[];
  setMenus: (newMenu: DineOutMenu[]) => void;
}

export const DineOutMenuComponent = ({ menus, setMenus }: Props) => {
  const handleUpdateType = (
    oldType: DineOutMenuType,
    newType: DineOutMenuType
  ) => {
    const matchingMenuIndex = menus.findIndex((m) => m.type === oldType);

    const newMenus = [...menus];
    newMenus[matchingMenuIndex] = {
      ...menus[matchingMenuIndex],
      type: newType,
    };

    setMenus(newMenus);
  };

  const updatePrie = (type: DineOutMenuType, price: string) => {
    const matchingMenuIndex = menus.findIndex((m) => m.type === type);

    const newMenus = [...menus];
    newMenus[matchingMenuIndex] = {
      ...menus[matchingMenuIndex],
      price: parseFloat(price),
    };

    setMenus(newMenus);
  };

  return (
    <>
      {menus.map((menu) => {
        return (
          <div key={menu.type}>
            <StyledLabel htmlFor='type-input'>Menu Type</StyledLabel>
            <StyledInput
              id='type-input'
              placeholder='brunch, lunch or dinner'
              value={menu.type}
              onChange={(e) => handleUpdateType(menu.type, e.target.value)}
              required
            />
            <StyledLabel htmlFor='price-input'>Price</StyledLabel>
            <StyledInput
              id='price-input'
              placeholder='Price - No dollar sign!'
              value={menu.price ?? ''}
              onChange={(e) => updatePrie(menu.type, e.target.value)}
              required
            />
            <span>{menu.menuText}</span>
          </div>
        );
      })}
    </>
  );
};

const MenuWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
