import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUpdateAccount } from '../../../../api/hooks/use-update-account';
import { Loader } from '../../../../components/loader';
import { spacing } from '../../../../components/styled';
import { ActionButton } from '../../../../features/shared/components/styled';
import {
  FormWrapper,
  StyledForm,
  StyledInput,
  StyledLabel,
} from '../../../../features/shared/components/styled-form';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectAdminAccountsList } from '../../../../store/admin-selectors';
import { setAdminAccountsList } from '../../../../store/admin-slice';
import { setActiveAccount } from '../../../../store/app-slice';
import { Account } from '../../../../types';

export const NewAccountForm = () => {
  const accounts = useAppSelector(selectAdminAccountsList);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const { loading, updateAccountData } = useUpdateAccount();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newAccount: Partial<Account> = {
      name,
      ownerId: null,
    };

    const newAccountData = await updateAccountData(newAccount, false, true);

    if (newAccountData) {
      dispatch(setAdminAccountsList([...accounts, newAccountData]));
      dispatch(setActiveAccount(newAccountData));

      navigate(`../accounts/${newAccountData.id}`);
    }
  };

  return (
    <StyledFormWrapper>
      {loading ? (
        <Loader />
      ) : (
        <StyledForm onSubmit={handleSubmit}>
          <StyledLabel htmlFor='name'>Account Name</StyledLabel>
          <StyledInput
            id='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <StyledActionButton type='submit'>Create</StyledActionButton>
        </StyledForm>
      )}
    </StyledFormWrapper>
  );
};

const StyledFormWrapper = styled(FormWrapper)`
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledActionButton = styled(ActionButton)`
  && {
    margin-left: ${spacing.md};
    margin-right: ${spacing.md};
  }
`;
