import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchAccount } from '../../../api/accounts/fetch-account';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectActiveAccount } from '../../../store/app-selectors';
import { setActiveAccount } from '../../../store/app-slice';
import { selectLoggedInAccount } from '../../../store/user-selectors';

export const useLoggedInAccount = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const activeAccount = useAppSelector(selectActiveAccount);
  const loggedInAccount = useAppSelector(selectLoggedInAccount);

  useEffect(() => {
    // loggedInAccount is fetched in useFetchData at the app root.
    if (!activeAccount && loggedInAccount) {
      const fetchActivePub = async () => {
        setLoading(true);

        if (params.accountId === loggedInAccount?.id) {
          dispatch(setActiveAccount(loggedInAccount));
        } else if (params.accountId) {
          const activeAccount = await fetchAccount(params.accountId);
          dispatch(setActiveAccount(activeAccount));
        }

        setLoading(false);
      };

      fetchActivePub();
    }
  }, [activeAccount, loggedInAccount]);

  return { loading };
};
