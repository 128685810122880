import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { Loader } from '../../components/loader';
import { spacing } from '../../components/styled';
import { HeadingTabs } from '../../features/shared/components/heading-tabs';
import { useHandleRefresh } from '../../features/shared/hooks/use-handle-refresh';
import { AppSectionWrapper } from '../../shared/components/section-wrapper';
import { useAppSelector } from '../../store';
import { selectAdminAccountsList } from '../../store/admin-selectors';
import { selectActivePub } from '../../store/app-selectors';
import {
  selectLoggedInAccount,
  selectLoggedInUser,
} from '../../store/user-selectors';
import { OwnerInput } from './owner-input';
import { StyledPubInfo } from './styled';

const pubDashboardHeadings = [
  { heading: 'Overview' },
  { heading: 'Happy Hours', paths: ['success'] },
  { heading: 'Events' },
  // { heading: 'Dine Out' },
];

export const BusinessOverview = () => {
  const user = useAppSelector(selectLoggedInUser);
  const isAdmin = user?.role === 'admin';
  const activePub = useAppSelector(selectActivePub);
  const { loading } = useHandleRefresh();
  const accounts = useAppSelector(selectAdminAccountsList);
  const adminAccountListSearch = accounts.find(
    (acc) => acc.id === activePub?.accountId
  );
  const loggedInAccount = useAppSelector(selectLoggedInAccount);
  const account = isAdmin ? adminAccountListSearch : loggedInAccount;

  if (!activePub || loading) {
    return <Loader />;
  }

  return (
    <div>
      <AppSectionWrapper>
        <Container data-testid='dashboard-container'>
          <Content>
            <h3>{`${activePub.name}`}</h3>
            {isAdmin && (
              <>
                <StyledPubInfo>{`ID: ${activePub.googlePlaceId}`}</StyledPubInfo>
                <OwnerInput pub={activePub} account={account} />
              </>
            )}
            <ButtonWrapper>
              <HeadingTabs headings={pubDashboardHeadings} />
            </ButtonWrapper>
            <Outlet />
          </Content>
        </Container>
      </AppSectionWrapper>
    </div>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div``;

const ButtonWrapper = styled.div`
  margin-bottom: ${spacing.xl};
`;
