import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Account, Pub, PubEvent, User } from 'src/client/app/types';

export interface AdminSliceState {
  pubs: Pub[];
  accountsList: Account[];
  usersList: User[];
  eventsList: PubEvent[];
}

export const initialState: AdminSliceState = {
  pubs: [],
  accountsList: [],
  usersList: [],
  eventsList: [],
};

const adminSlice = createSlice({
  name: 'admin',
  initialState: initialState,
  reducers: {
    setAdminPubs(state, action: PayloadAction<Pub[]>) {
      state.pubs = action.payload;
    },
    setAdminUsersList(state, action: PayloadAction<User[]>) {
      state.usersList = action.payload;
    },
    setAdminEventsList(state, action: PayloadAction<PubEvent[]>) {
      state.eventsList = action.payload;
    },
    setAdminAccountsList(state, action: PayloadAction<Account[]>) {
      state.accountsList = action.payload;
    },
  },
});

export const {
  setAdminPubs,
  setAdminAccountsList,
  setAdminUsersList,
  setAdminEventsList,
} = adminSlice.actions;

export const adminReducer = adminSlice.reducer;
