import React from 'react';
import { PubsTable } from '../../../../features/shared/components/pubs-table';
import { useAppSelector } from '../../../../store';
import { selectAdminPubs } from '../../../../store/admin-selectors';

export const AdminPubs = () => {
  const adminPubs = useAppSelector(selectAdminPubs);

  return <PubsTable pubs={adminPubs} />;
};
