import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button } from '@mui/material';
import React, { PropsWithChildren, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../../components/styled';
import { Row } from '../../../routes/admin/dashboard/styled';
import { useClickOutside } from '../hooks/use-click-outside';
import { zIndexValue } from './styled';

interface Props {
  onClick: () => void;
  readOnly?: boolean;
  dropdownOptions?: {
    text: string;
    handler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }[];
}

export const CustomTableRow = ({
  onClick,
  children,
  dropdownOptions,
  readOnly,
}: PropsWithChildren<Props>) => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setOptionsOpen(false));

  const handleOptionsClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setOptionsOpen(!optionsOpen);
  };

  return (
    <StyledTableRow onClick={onClick} $readOnly={readOnly} ref={ref}>
      {children}
      {dropdownOptions && (
        <CustomRowButtonsWrapper>
          <OptionsButton onClick={handleOptionsClick}>
            <MoreHorizIcon />
          </OptionsButton>
          {dropdownOptions && optionsOpen && (
            <DropDownOptions>
              {dropdownOptions.map(({ text, handler }) => {
                return (
                  <StyledButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handler(e);
                    }}
                  >
                    {text}
                  </StyledButton>
                );
              })}
            </DropDownOptions>
          )}
        </CustomRowButtonsWrapper>
      )}
    </StyledTableRow>
  );
};

const StyledTableRow = styled(Row)<{ $readOnly?: boolean }>`
  && {
    position: relative;
    margin: ${spacing.sm} 0;
    border: 0;
    background-color: ${colors.lightGrey};
    border-radius: 10px;
    padding: ${spacing.md} ${spacing.xl};
    border: 1px solid ${colors.mediumGrey};
    ${({ $readOnly }) => $readOnly && `cursor: default;`}

    &:hover {
      ${({ $readOnly }) =>
        !$readOnly && `background-color: ${colors.backgroundBlue};`}
    }
  }
`;

const CustomRowButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const OptionsButton = styled(Button)`
  && {
    padding-left: 0;
    padding-right: 0;
    min-width: 0;
  }
`;

const DropDownOptions = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  background-color: ${colors.paleGrey};
  padding: 0;
  border: 1px solid ${colors.mediumGrey};
  z-index: ${zIndexValue.customRowOptions};
  display: flex;
  flex-direction: column;
  text-align: start;
  min-width: 120px;
`;

const StyledButton = styled(Button)`
  && {
    justify-content: flex-start;
    color: ${colors.blue};
    padding-left: ${spacing.lg};
    padding-right: ${spacing.lg};
  }
`;
