import { PubEvent } from '../../../types';

export const generateInitialEvent = (googlePlaceId?: string): PubEvent => ({
  pubId: googlePlaceId ?? '',
  name: '',
  description: '',
  price: '0',
  type: null,
  spotlightEvent: false,
  dates: [],
  // Deprecate
  times: { startTime: '18:00' },
  date: '',
});
