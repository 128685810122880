import { useEffect } from 'react';
import { fetchPubs, fetchUsers } from '../../../api';
import { fetchAccount } from '../../../api/accounts/fetch-account';
import { fetchAccounts } from '../../../api/accounts/fetch-accounts';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectAdminUsersList } from '../../../store/admin-selectors';
import {
  setAdminAccountsList,
  setAdminEventsList,
  setAdminPubs,
  setAdminUsersList,
} from '../../../store/admin-slice';
import { selectLoadingData } from '../../../store/app-selectors';
import { setLoadingData } from '../../../store/app-slice';
import {
  selectLoggedInAccount,
  selectLoggedInUser,
} from '../../../store/user-selectors';
import { setLoggedInAccount, setUsersPubs } from '../../../store/user-slice';
import { PubEvent } from '../../../types';
import { useFetchLoggedInUser } from './use-fetch-current-user';

export const useFetchData = () => {
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const loggedInAccount = useAppSelector(selectLoggedInAccount);
  const adminUsersList = useAppSelector(selectAdminUsersList);
  const { fetchLoggedInUser } = useFetchLoggedInUser();
  const dispatch = useAppDispatch();
  const loadingData = useAppSelector(selectLoadingData);

  useEffect(() => {
    if (loggedInAccount) {
      dispatch(setUsersPubs(loggedInAccount.pubs));
    }
  }, [loggedInAccount]);

  useEffect(() => {
    const fetchAdminData = async () => {
      const [accountsList, usersList, pubsList] = await Promise.all([
        fetchAccounts(),
        fetchUsers(),
        fetchPubs(),
      ]);

      dispatch(setAdminAccountsList(accountsList));
      dispatch(setAdminUsersList(usersList));
      dispatch(setAdminPubs(pubsList));

      const events = pubsList.reduce((acc: PubEvent[], pub) => {
        if (pub.events?.length) {
          return [...acc, ...pub.events];
        }

        return acc;
      }, []);
      dispatch(setAdminEventsList(events));

      // const dineOutPubs = pubsList.filter((pub) => pub.dineOut);
      // dispatch(setDineOutPubs(dineOutPubs));
    };

    if (
      !loadingData &&
      loggedInUser?.role === 'admin' &&
      adminUsersList.length === 0
    ) {
      fetchAdminData();
    }
  }, [loadingData, loggedInUser]);

  useEffect(() => {
    const fetchLoggedInAccount = async (accountId: string) => {
      const account = await fetchAccount(accountId);

      dispatch(setLoggedInAccount(account));
    };

    const fetchUserData = async () => {
      dispatch(setLoadingData(true));

      const userData = await fetchLoggedInUser();

      if (userData && userData.accountId) {
        await fetchLoggedInAccount(userData.accountId);
      }

      dispatch(setLoadingData(false));
    };

    if (!loadingData && !loggedInUser) {
      fetchUserData();
    }
  }, [loggedInUser]);
};
