import { User } from '../../types';
import { baseUrl } from '../base-url';

export const updateUser = async (user: User) => {
  try {
    const token = localStorage.getItem('token');
    return await fetch(`${baseUrl}/api/user/${user.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    });
  } catch (err) {
    console.log('Failed to update user data.', err);
  }
};
