import React from 'react';
import { PubsTable } from '../../../features/shared/components/pubs-table';
import { useAppSelector } from '../../../store';
import { selectUserPubs } from '../../../store/user-selectors';

export const DashboardPubs = () => {
  const usersPubs = useAppSelector(selectUserPubs);

  return (
    <>
      <h2>Businesses</h2>
      <PubsTable pubs={usersPubs} />
    </>
  );
};
