import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../../../../components/protected-route';
import { Summary } from '../../../../features/summary/components/summary';
import { BusinessOverview } from '../../../business/business-overview';
import { BusinessEvents } from '../../../business/events/business-events';
import { UpdateHappyHours } from '../../../business/happy-hours/update-happy-hours';
import { PubUpdateSuccess } from '../../../business/success/pub-update-success';
import { SummaryPage } from '../../../business/summary/summary-page';
import { EventsRoot } from './events-root';

export const BusinessRoot = () => {
  return (
    <Routes>
      <Route path='' element={<ProtectedRoute element={BusinessOverview} />}>
        <Route path='' element={<ProtectedRoute element={Summary} />} />
        <Route path='overview' element={<ProtectedRoute element={Summary} />} />
        <Route
          path='happy-hours'
          element={<ProtectedRoute element={UpdateHappyHours} />}
        />
        <Route
          path='summary'
          element={<ProtectedRoute element={SummaryPage} />}
        />
        <Route
          path='success'
          element={<ProtectedRoute element={PubUpdateSuccess} />}
        />
        <Route
          path='events'
          element={<ProtectedRoute element={BusinessEvents} />}
        />
        <Route
          path='events/*'
          element={<ProtectedRoute element={EventsRoot} />}
        />
      </Route>
    </Routes>
  );
};
