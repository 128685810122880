import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUpdatePub } from '../../../../api/hooks/use-update-pub';
import { Loader } from '../../../../components/loader';
import { spacing } from '../../../../components/styled';
import { AddButton } from '../../../../components/styled/buttons';
import { CustomHeading2 } from '../../../../components/styled/headings';
import { ActionButton } from '../../../../features/shared/components/styled';
import {
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledTextArea,
} from '../../../../features/shared/components/styled-form';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  selectActivePub,
  selectDineOutPubs,
} from '../../../../store/app-selectors';
import {
  setActivePub,
  setDineOutPubs,
  setUnpublishedChanges,
} from '../../../../store/app-slice';
import { DineOut, DineOutMenu, DineOutMenuType } from '../../../../types';
import { CusineTypeSelect } from './cusine-type-select';
import { DineOutMenuComponent } from './dine-out-menu';
import { HorizontalInputs } from './dine-out-menu-list';

const emptyMenuValue = (type: DineOutMenuType): DineOutMenu => ({
  type,
  price: 0,
  glutenFreeOption: false,
  vegetarianOption: false,
  takeoutOption: false,
  acceptingReservations: true,
  menuText: '',
});

export const DineOutMenuInputs = () => {
  const dispatch = useAppDispatch();
  const activePub = useAppSelector(selectActivePub);
  const dineOutPubs = useAppSelector(selectDineOutPubs);
  const [description, setDescription] = useState(
    activePub?.dineOut?.description ?? ''
  );
  const [phone, setPhone] = useState(activePub?.dineOut?.phone ?? '');
  const [email, setEmail] = useState(activePub?.dineOut?.email ?? '');
  const [bookingUrl, setBookingUrl] = useState(
    activePub?.dineOut?.bookingUrl ?? ''
  );
  const [imageUrl, setImageUrl] = useState(activePub?.dineOut?.imageUrl ?? '');
  const [cuisine, setCuisine] = useState(activePub?.dineOut?.cuisine ?? '');
  const { updatePub } = useUpdatePub();
  const navigate = useNavigate();
  const [menus, setMenus] = useState(activePub?.dineOut?.menus ?? []);
  const [showNewMenuInput, setShowNewMenuInput] = useState(false);
  const [newMenuType, setNewMenuType] = useState('dinner');

  if (!activePub) {
    return <Loader />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const dineOut: DineOut = {
      bookingUrl,
      email,
      phone,
      cuisine,
      imageUrl,
      description,
      menus,
      dineOutUrl: '',
    };

    dispatch(setActivePub({ ...activePub, dineOut }));
    await updatePub({ ...activePub, dineOut });

    const filteredPubs = dineOutPubs.filter(
      (pub) => pub.googlePlaceId !== activePub.googlePlaceId
    );
    dispatch(setDineOutPubs([...filteredPubs, activePub]));

    dispatch(setUnpublishedChanges(false));
    navigate('../success');
  };

  const handleSetMenus = (newMenu: DineOutMenu[]) => {
    setMenus(newMenu);
  };

  const addNewEmptyMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setMenus([...menus, emptyMenuValue(newMenuType)]);
    setShowNewMenuInput(false);
  };

  console.log('dine out', activePub.dineOut);

  return (
    <Container>
      <StyledForm onSubmit={handleSubmit} style={{ maxWidth: 1000 }}>
        <StyledLabel htmlFor='description-input'>Description</StyledLabel>
        <StyledTextArea
          id='description-input'
          placeholder='Description, e.g. Choose one option from each course'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <StyledLabel htmlFor='phone-input'>Phone Number</StyledLabel>
        <StyledInput
          id='phone-input'
          placeholder='Phone Number'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <StyledLabel htmlFor='email-input'>Email</StyledLabel>
        <StyledInput
          id='email-input'
          placeholder='Email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <StyledLabel htmlFor='booking-url-input'>Booking URL</StyledLabel>
        <StyledInput
          id='booking-url-input'
          placeholder='http://booking.com'
          value={bookingUrl}
          onChange={(e) => setBookingUrl(e.target.value)}
          required
        />
        <StyledLabel htmlFor='image-url-input'>Image URL</StyledLabel>
        <StyledInput
          id='image-url-input'
          placeholder='http://image.com'
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          required
        />
        <CusineTypeSelect cuisineType={cuisine} setCuisineType={setCuisine} />
        <CustomHeading2>Menus</CustomHeading2>
        <div style={{ width: '100%' }}>
          <AddButton
            onClick={() => setShowNewMenuInput(true)}
            style={{ marginBottom: spacing.xl }}
          >
            Add a Menu +
          </AddButton>
        </div>
        {showNewMenuInput && (
          <HorizontalInputs
            style={{
              marginBottom: spacing.xl,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <StyledInput
              id='type-input'
              placeholder='Enter menu type: brunch, lunch or dinner'
              value={newMenuType}
              onChange={(e) => setNewMenuType(e.target.value)}
              required
            />
            <ActionButton
              onClick={addNewEmptyMenu}
              style={{ marginTop: 0, marginLeft: spacing.lg }}
            >
              Start
            </ActionButton>
          </HorizontalInputs>
        )}
        <DineOutMenuComponent menus={menus} setMenus={handleSetMenus} />
        <ActionButton type='submit'>Submit</ActionButton>
      </StyledForm>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing.md};
`;

const MenuWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
