import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../../../components/styled';
import { readableDate } from '../../../../helpers/date';
import { EventDate } from '../../../../types';
import { EventTimePickers } from './event-time-pickers';

interface Props {
  eventDates: EventDate[];
  handleDatesChange: (newDates: EventDate[]) => void;
}

export const IndividualEventTimes = ({
  eventDates,
  handleDatesChange,
}: Props) => {
  const [showInputs, setShowInputs] = useState(eventDates.length > 1);

  useEffect(() => {
    setShowInputs(eventDates.length > 1);
  }, [eventDates]);

  const handleNewStartTime = (newStartTime: string, index: number) => {
    const newEventDates = eventDates.map((eventDate, i) => {
      const { date, times } = eventDate;

      return i === index
        ? { date, times: { ...times, startTime: newStartTime } }
        : eventDate;
    });

    handleDatesChange(newEventDates);
  };

  const handleNewEndTime = (newEndTime: string | null, index: number) => {
    const newEventDates = eventDates.map((eventDate, i) => {
      const { date, times } = eventDate;

      if (i === index) {
        if (newEndTime) {
          return { date, times: { ...times, endTime: newEndTime } };
        } else {
          return { date, times: { startTime: times.startTime } };
        }
      } else {
        return eventDate;
      }
    });

    handleDatesChange(newEventDates);
  };

  return (
    <>
      {eventDates.length > 1 && (
        <StyledDropDownHeader
          onClick={() => setShowInputs(!showInputs)}
          style={{ marginTop: spacing.sm }}
        >
          <span>Set Individual Times</span>
          <ArrowWrapper>
            {showInputs ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </ArrowWrapper>
        </StyledDropDownHeader>
      )}
      {showInputs && (
        <>
          {eventDates.map(({ date, times }, index) => {
            return (
              <StyledTimeListItem key={`${date}-${times.startTime}`}>
                <span>{readableDate(date)}</span>
                <EventTimePickers
                  times={times}
                  onStartChange={(newTime) =>
                    handleNewStartTime(newTime, index)
                  }
                  onEndChange={(newTime) => handleNewEndTime(newTime, index)}
                  listView={true}
                />
              </StyledTimeListItem>
            );
          })}
        </>
      )}
    </>
  );
};

const StyledDropDownHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  color: ${colors.paleGrey};
  padding-top: ${spacing.sm};
  padding-bottom: ${spacing.sm};
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  right: ${spacing.sm};
`;

const StyledTimeListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
