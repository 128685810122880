import TextareaAutosize from '@mui/material/TextareaAutosize';
import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';

interface Props {
  text: string;
  setText: (text: string) => void;
  addView?: boolean;
}

export const CopyPasteOption = ({ text, setText, addView = false }: Props) => {
  const instructions = `Copy and paste ${
    addView ? 'the new items to add.' : 'your happy hour menu and times.'
  }`;
  const placeholder = `${
    addView
      ? ''
      : `Happy Hour Mon-Fri 3-6pm. 

HAPPY HOUR`
  }
HAPPY HOUR
DRINKS
Guinness (20 oz) $7.50
Magners (20 oz) $7.50
Stella Artois (20 oz) $7.50
Red/White Wine 9oz $10
Red/White Wine 6oz $7
Baby Guinness 1oz $5
House Highballs 1oz $6.25
Bucket of the Day $27.50
  `;

  return (
    <>
      <p>{instructions}</p>
      <StyledTextareaAutosize
        value={text}
        placeholder={placeholder}
        onChange={(e) => setText(e.target.value)}
        minRows={4}
      />
    </>
  );
};

const StyledTextareaAutosize = styled(TextareaAutosize)`
  resize: vertical;
  width: 100%;
  min-height: 40px;
  margin-bottom: 10px;
  padding: ${spacing.sm};
`;
