import React, { useState } from 'react';
import { pastDate } from '../../../helpers/date';
import { PubEvent } from '../../../types';
import { CopyModal } from './copy-modal';
import { EventsTable } from './events-table';

interface Props {
  events?: PubEvent[];
  onDelete: (event: PubEvent) => void;
  showDuplicate?: boolean;
}

export const EventsTables = ({
  events,
  onDelete,
  showDuplicate = false,
}: Props) => {
  const [copyModalOpen, setCopyModalOpen] = useState(false);

  // Any date is today or later
  const activeEvents = events?.filter((ev) =>
    ev.dates.some((date) => !pastDate(date))
  );
  // All dates are in the past
  const pastEvents = events?.filter((ev) =>
    ev.dates.every((date) => pastDate(date))
  );

  return (
    <>
      <h3>Active Events</h3>
      <EventsTable
        events={activeEvents}
        onDelete={onDelete}
        showDuplicate={showDuplicate}
        setCopyModalOpen={setCopyModalOpen}
      />
      <h3>Past Events</h3>
      <p>Past events will be deleted after 30 days.</p>
      <EventsTable
        events={pastEvents}
        onDelete={onDelete}
        futureEvents={false}
        setCopyModalOpen={setCopyModalOpen}
      />
      <CopyModal open={copyModalOpen} setOpen={setCopyModalOpen} />
    </>
  );
};
