import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import styled from 'styled-components';
import { spacing } from '../../../../components/styled';
import { StyledLabel } from '../../../../features/shared/components/styled-form';

interface Props {
  pricePrefix?: string;
  setPricePrefix: (newType: string) => void;
  disabled: boolean;
}

const options = ['', 'From', 'Early Bird', 'Online'];

export const PricePrefixSelect = ({
  pricePrefix,
  setPricePrefix,
  disabled,
}: Props) => {
  const handleChange = (prefix: SelectChangeEvent<unknown>) => {
    setPricePrefix(prefix.target.value as string);
  };

  return (
    <>
      <StyledLabel htmlFor='price-prefix-input'>Prefix</StyledLabel>
      <StyledSelect
        id='price-prefix-input'
        value={pricePrefix ?? ''}
        onChange={handleChange}
        size='small'
        disabled={disabled}
        placeholder='None'
      >
        {options.map((option) => {
          return (
            <StyledMenuItem key={option} value={option}>
              {option}
            </StyledMenuItem>
          );
        })}
      </StyledSelect>
    </>
  );
};

const StyledSelect = styled(Select)`
  min-width: 150px;
  margin-top: ${spacing.sm};
  background-color: white;
  text-transform: capitalize;
`;

const StyledMenuItem = styled(MenuItem)`
  min-width: 150px;
  text-transform: capitalize;
`;
